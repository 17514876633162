import React from "react";
import MobileChart from "../MobileChart/MobileChart";
import MobileWeeklyChart from "../MobileWeeklyChart/MobileWeeklyChart";
import MobileMonthlyChart from "../MobileMonthlyChart/MobileMonthlyChart";
import StatisticsView from "../StatisticsView/StatisticsView";
import styles from "./MobileEnergyBlock.module.scss";

type PropsT = {
  energy: any;
  period: string;
};

const MobileEnergyBlock = (props: PropsT) => {
  const { energy, period } = props;

  function renderChart() {
    switch (period) {
      case "today":
      case "yesterday":
        return energy?.date ? <MobileChart energy={energy} /> : <div />;
      case "week":
        return energy?.label === "This week" ? (
          <MobileWeeklyChart energy={energy} />
        ) : (
          <div />
        );
      case "month":
        return energy?.label === "This month" ? (
          <MobileMonthlyChart energy={energy} />
        ) : (
          <div />
        );
      default:
        return <div />;
    }
  }

  return (
    <div className={styles.chartWrapper}>
      <StatisticsView
        savings={energy.totalSavings}
        generation={energy.totalGeneration}
        onPeakConsumption={energy.totalOnPeak || energy.totalOnPeakConsumption}
        offPeakConsumption={energy.totalOffPeak || energy.totalOffPeakConsumption}
      />
      {renderChart()}
    </div>
  );
};

export default MobileEnergyBlock;
