import { put, takeEvery, call, take, delay } from "redux-saga/effects";
import {
  CreateUserT,
  InviteUserT,
  SendBaseLineT,
  InviteFriendRequestT,
  ResendInviteT,
  inviteFriendSuccess,
  inviteFriendFailure,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  INVITE_USER,
  INVITE_USER_SUCCESS,
  INVITE_USER_FAILURE,
  SEND_BASE_LINE,
  SEND_BASE_LINE_SUCCESS,
  SEND_BASE_LINE_FAILURE,
  INVITE_FRIEND_REQUEST,
  RESEND_INVITE,
  RESEND_INVITE_SUCCESS,
  RESEND_INVITE_FAILURE,
} from "../modules/inviteUser";
import { SHOW_ALERT, HIDE_ALERT } from "../modules/successAlert";
import { GET_USERS_LIST } from "../modules/users";
import {
  inviteUser,
  sendBaseline,
  inviteFriend,
  resendInvite,
} from "../../api/users";

function* createUserSaga(action: CreateUserT) {
  try {
    yield put({ type: INVITE_USER, userData: action.userData });
    const { homeId } = yield take(INVITE_USER_SUCCESS);

    yield put({ type: SEND_BASE_LINE, homeId, data: action.data });
    yield take(SEND_BASE_LINE_SUCCESS);

    yield put({ type: CREATE_USER_SUCCESS, payload: { invited: true } });
    yield put({ type: GET_USERS_LIST });
  } catch (error) {
    yield put({ type: CREATE_USER_FAILURE, error });
  }
}

function* inviteUserSaga(action: InviteUserT) {
  try {
    const {
      data: { homeId },
    } = yield call(inviteUser, action.userData);

    yield put({ type: INVITE_USER_SUCCESS, homeId });
  } catch (error) {
    yield put({ type: INVITE_USER_FAILURE, payload: { inviteError: error } });
  }
}

function* sendBaseLineSaga(action: SendBaseLineT) {
  try {
    const { homeId, data } = action;
    yield call(sendBaseline, { homeId, data });

    yield put({ type: SEND_BASE_LINE_SUCCESS });
  } catch (error) {
    yield put({ type: SEND_BASE_LINE_FAILURE, payload: { sendError: error } });
    yield put({ type: GET_USERS_LIST });
  }
}

function* inviteFriendSaga(action: InviteFriendRequestT) {
  try {
    yield call(inviteFriend, action.data);
    yield put({ type: SHOW_ALERT, message: "email sent!" });
    yield delay(1000);
    yield put({ type: HIDE_ALERT });
    yield put(inviteFriendSuccess());
  } catch (error) {
    yield put(inviteFriendFailure(error));
  }
}

function* resendInviteSaga(action: ResendInviteT) {
  try {
    yield call(resendInvite, action.homeId);

    yield put({ type: RESEND_INVITE_SUCCESS });
  } catch (error) {
    yield put({ type: RESEND_INVITE_FAILURE, error });
  }
}

export default function* usersSaga() {
  yield takeEvery(CREATE_USER, createUserSaga);
  yield takeEvery(INVITE_USER, inviteUserSaga);
  yield takeEvery(SEND_BASE_LINE, sendBaseLineSaga);
  yield takeEvery(INVITE_FRIEND_REQUEST, inviteFriendSaga);
  yield takeEvery(RESEND_INVITE, resendInviteSaga);
}
