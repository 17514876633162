import React from "react";
import {
  ComposedChart,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts";

import CustomTooltip from "../CustomChartTooltip/CustomChartTooltip";
import { EnergyT } from "../../api/dashboard";
import {
  calculatePickHours,
  transformedData,
  calculatePick,
  calculatePickValue,
  calculateOnPeakDashboard,
  calculateOffPeakDashboard,
  calculateConsumptionDashboard,
} from "../../helpers/grid";
import { formattedHours } from "../../constants";

import colors from "../../assets/style/variables/colors.scss";
import styles from "./DesktopChart.module.scss";
import { useSelector } from "react-redux";
import { AppState } from "../../store/modules";
import { StateT as AuthState } from "../../store/modules/authorization";
import { StateT as userState } from "../../store/modules/users";

type PropsT = {
  energy: EnergyT;
  forAdmin?: boolean;
  period?: string;
};

const DesktopChart = (props: PropsT) => {
  const {
    energy: { peaks, hours },
    forAdmin = false,
  } = props;
  const peaksHours = calculatePickHours(peaks);
  const peakValue = calculatePickValue(hours);

  const { isFlat } = useSelector<AppState, userState>((state) => state.users);
  const { currentRate } = useSelector<AppState, AuthState>(
    (state) => state.authorization
  );

  const chartData = transformedData.reduce(
    (acc: Array<any>, curr: any, index: number) => {
      return [
        ...acc,
        {
          ...hours[index],
          hour: hours[index]?.hour || index,
          peak: peaksHours.includes(index) ? peakValue : 0,
          onPeak: peaksHours.includes(index),
        },
      ];
    },
    []
  );

  const renderChart = () => (
    <ComposedChart
      margin={
        forAdmin ? { left: 15, top: 20, right: 32 } : { top: 20, right: 20 }
      }
      data={chartData}
      stackOffset="sign"
      className={forAdmin ? styles.adminChart : ""}
    >
      <CartesianGrid strokeDasharray="3 3" horizontal={false} />
      <YAxis
        tick={{
          stroke: colors["grey"],
          strokeWidth: 0.5,
          fontWeight: 300,
        }}
        tickLine={false}
        axisLine={false}
        type="number"
        tickMargin={30}
      />
      <XAxis
        dataKey="hour"
        axisLine={false}
        tick={{
          stroke: colors["grey"],
          strokeWidth: 0.5,
          fontWeight: 300,
        }}
        domain={[0, 23]}
        ticks={[0, 4, 8, 12, 16, 20]}
        tickFormatter={(timeStr) => formattedHours[timeStr]}
        type="number"
        tickLine={false}
      />
      <Area
        type="step"
        dataKey="peak"
        fill="#f86552"
        fillOpacity={0.06}
        stroke="#f86552"
        strokeOpacity={0.06}
      />
      <Area
        type="step"
        dataKey={calculatePick}
        fill="#f86552"
        fillOpacity={0.06}
        stroke="#f86552"
        strokeOpacity={0.06}
      />
      {!currentRate?.flat && !isFlat && (
        <Bar
          dataKey={calculateOnPeakDashboard}
          barSize={34}
          fill={colors["dark-ocean"]}
          stackId="stack"
          radius={2}
        />
      )}
      {!currentRate?.flat && !isFlat && (
        <Bar
          dataKey={calculateOffPeakDashboard}
          barSize={34}
          fill={colors["cyan"]}
          stackId="stack"
          radius={2}
        />
      )}
      {(currentRate?.flat || isFlat) && (
        <Bar
          dataKey={calculateConsumptionDashboard}
          barSize={34}
          fill={colors["cyan"]}
          stackId="stack"
          radius={2}
        />
      )}
      <Bar
        dataKey="generation"
        barSize={34}
        fill={colors["orange"]}
        stackId="stack"
        radius={2}
      />
      <ReferenceLine y={0} stroke={colors["swans-down"]} strokeWidth={1} />
      <Tooltip
        content={<CustomTooltip />}
        cursor={{ stroke: colors["black"], strokeWidth: 2 }}
        viewBox={{ x: 0, y: 0, width: 400, height: 400 }}
      />
    </ComposedChart>
  );

  return (
    <ResponsiveContainer
      height="100%"
      width="100%"
      aspect={forAdmin ? 2.0 : 4.0}
      className={styles.responsiveContainer}
    >
      {renderChart()}
    </ResponsiveContainer>
  );
};

export default DesktopChart;
