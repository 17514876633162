import React from "react";
import styles from "./MenuDrawerButton.module.scss";
import cx from "classnames";

interface IMenuDrawerButtonParams {
  onClick: () => void;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  children: string;
  type?: "warning";
}

const MenuDrawerButton = (params: IMenuDrawerButtonParams) => {
  const { onClick, Icon, children, type } = params;

  return (
    <button className={styles.button} type="button" onClick={onClick}>
      <div className={cx(styles.content, type ? styles[type] : "")}>
        <Icon className={styles.icon} />
        {children}
      </div>
    </button>
  );
};

export default MenuDrawerButton;
