import React, { useMemo, useCallback, useEffect, useState } from "react";
import { isMobileOnly } from "react-device-detect";
import { CellProps as CellPropsT } from "react-table";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import TextWithDate from "../TextWithDate/TextWithDate";
import Table from "../PlainTable/Table";
import BillPDFGenerator from "../BillPDFGenerator/BillPDFGenerator";
import PayNowButton from "../PayNowButton/PayNowButton";
import NumericValueFormat from "../NumericValueFormat/NumericValueFormat";
import { AppState } from "../../store/modules";
import {
  payBill as payBillAction,
  StateT as BillsState,
  setBillId,
} from "../../store/modules/bills";
import { StateT as AuthState } from "../../store/modules/authorization";
import { formatMonthDayYear } from "../../helpers/formatDate";

import { ReactComponent as DownloadIcon } from "../../assets/icons/Download.svg";
import { ReactComponent as PaidIcon } from "./../../assets/icons/Paid.svg";
import { ReactComponent as InProgressIcon } from "./../../assets/icons/InProgress.svg";
import styles from "./BillingAndPaymentHistory.module.scss";
import ErrorBillModal from "../ErrorBillModal/ErrorBillModal";
import { ReactComponent as FailIcon } from "../../assets/icons/Exceed.svg";

const iconStyle = {
  cursor: "pointer",
};

type BillsPaymentRowT = {
  id: string;
  activity: string;
  endDate: Date;
  totalAmount: number;
  paid: boolean;
  paymentPending: boolean;
  paymentFailed: boolean;
};

type StatusT = {
  admin: boolean;
  original: any;
  isMobile?: boolean;
  payHandler: (id: string) => void;
  openModal: (id: string) => void;
};

function renderStatus(props: StatusT) {
  const { admin, original, isMobile, payHandler, openModal } = props;

  const clickHandler = () => payHandler(original.id);
  const openHandler = () => openModal(original.id);

  if (original.paid) {
    return <PaidIcon />;
  }
  if (original.paymentPending) {
    return <InProgressIcon />;
  }
  if (original.paymentFailed) {
    return <FailIcon onClick={openHandler} style={iconStyle} />;
  }
  if (admin) {
    return "-";
  }
  return (
    <PayNowButton
      className={isMobile && styles.mobilePayButton}
      onClick={clickHandler}
    />
  );
}

function getBillingAndPaymentColumns(
  isMobile: boolean,
  admin: boolean,
  payHandler: (id: string) => void,
  openModal: (id: string) => void
) {
  if (isMobile) {
    return [
      {
        Header: "Date",
        accessor: "endDate",
        Cell: ({ row }: CellPropsT<BillsPaymentRowT>) => (
          <TextWithDate
            title="Sprightful bill"
            date={formatMonthDayYear(row.original.endDate)}
          />
        ),
      },
      {
        Header: "Amount",
        accessor: "totalAmount",
        Cell: ({ row }: CellPropsT<BillsPaymentRowT>) => (
          <NumericValueFormat value={row.original.totalAmount} isMoney />
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row: { original } }: CellPropsT<BillsPaymentRowT>) => (
          <div className={styles.mobileColumnsIcons}>
            {renderStatus({ admin, original, isMobile, payHandler, openModal })}
            <BillPDFGenerator data={original}>
              <DownloadIcon />
            </BillPDFGenerator>
          </div>
        ),
      },
    ];
  }

  return [
    {
      Header: "Date",
      accessor: "endDate",
      Cell: ({ row }: CellPropsT<BillsPaymentRowT>) =>
        formatMonthDayYear(row.original.endDate),
    },
    {
      Header: "Invoice",
      accessor: "activity",
      Cell: ({ row }: CellPropsT<BillsPaymentRowT>) => {
        return (
          <>
            Sprightful bill&nbsp;
            <BillPDFGenerator data={row.original}>
              <DownloadIcon />
            </BillPDFGenerator>
          </>
        );
      },
    },
    {
      Header: "Amount",
      accessor: "totalAmount",
      Cell: ({ row }: CellPropsT<BillsPaymentRowT>) => (
        <NumericValueFormat value={row.original.totalAmount} isMoney />
      ),
    },
    {
      Header: "Status",
      accessor: "paid",
      Cell: ({ row: { original } }: CellPropsT<BillsPaymentRowT>) =>
        renderStatus({ admin, original, isMobile, payHandler, openModal }),
    },
  ];
}

export default function BillingAndPaymentHistory() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { location } = useSelector<AppState, any>(({ router }) => router);
  const { billId, payError } = useSelector<AppState, BillsState>(
    (state) => state.bills
  );

  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const openModal = useCallback(
    (id: string | null) => {
      if (id) {
        dispatch(setBillId(id));
      }
      setModalOpen(true);
    },
    [dispatch]
  );

  const closeModal = () => {
    dispatch(setBillId(null));
    setModalOpen(false);
  };

  useEffect(() => {
    if (payError) {
      openModal(billId);
    }
  }, [payError, openModal, billId]);

  useEffect(() => {
    const billId = location?.state?.billId;
    if (billId) {
      dispatch(payBillAction(billId));
    }
  }, [location, dispatch]);

  const { closed = [] } = useSelector<AppState, BillsState>(
    (state) => state.bills
  );

  const { admin, payments } = useSelector<AppState, AuthState>(
    (state) => state.authorization
  );

  const configured = payments?.configured || false;

  const payBill = useCallback(
    (id: string) => {
      dispatch(payBillAction(id));
    },
    [dispatch]
  );

  const payHandler = useCallback(
    (id: string) =>
      configured
        ? payBill(id)
        : history.push("/payment-details", { toBills: true, billId: id }),
    [configured, history, payBill]
  );

  const columns = useMemo(
    () =>
      getBillingAndPaymentColumns(isMobileOnly, admin, payHandler, openModal),
    [admin, payHandler, openModal]
  );

  return (
    <>
      <TextWithDate title="Payment History" date="" />
      <Table columns={columns} data={closed} />
      <ErrorBillModal
        isOpen={isModalOpen}
        closeModal={closeModal}
        billId={billId}
        payHandler={payHandler}
      />
    </>
  );
}
