import React from "react";
import NumericValueFormat from "../NumericValueFormat/NumericValueFormat";
import styles from "../Nomination/Nomination.module.scss";

type PropsT = {
  name: string;
  number: string | number | React.ReactNode;
  color?: string;
  isMoney?: boolean;
  suffix?: string; 
};

const Nomination = (props: PropsT) => {
  const { name, number, color, suffix, isMoney = false } = props;
  return (
    <div className={styles.wrapper}>
      <div className={styles.name}>{name}</div>
      <div className={styles.number} style={{ color }}>
        {isMoney ?(
            <NumericValueFormat value={number} isMoney />
        ):(
            <NumericValueFormat value={number} decimalScale={1} suffix={suffix} />
        )}
      </div>
    </div>
  );
};

export default Nomination;
