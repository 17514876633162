import {
  takeEvery,
  put,
  call,
  select,
  spawn,
  delay,
  take,
} from "redux-saga/effects";
import { push, replace } from "connected-react-router";
import {
  ConfirmT,
  LoginT,
  ForgotT,
  RecoveryT,
  UploadPhotoT,
  ChangePasswordT,
  CONFIRM,
  CONFIRM_SUCCESS,
  CONFIRM_FAILURE,
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  FORGOT,
  FORGOT_SUCCESS,
  FORGOT_FAILURE,
  RECOVERY,
  RECOVERY_SUCCESS,
  RECOVERY_FAILURE,
  CHECK,
  CHECK_SUCCESS,
  CHECK_FAILURE,
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  UPLOAD_PHOTO,
  UPLOAD_PHOTO_FAILURE,
  UPLOAD_PHOTO_SUCCESS,
  REMOVE_PHOTO,
  REMOVE_PHOTO_SUCCESS,
  REMOVE_PHOTO_FAILURE,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  resetUploadStatus,
} from "../modules/authorization";
import {
  confirmRegistration,
  loginUser,
  forgetPassword,
  recoveryPassword,
  checkUser,
  uploadPhoto,
  removePhoto,
  changePassword,
} from "../../api/authorization";
import axios from "../../api/axiosConfig";
import {
  saveTokens,
  setToken,
  removeUser,
  getTokens,
} from "../../helpers/authorization";
import { slashesRegexp } from "../../constants";

function* confirmSaga(action: ConfirmT) {
  try {
    const {
      router: {
        location: { search },
      },
    } = yield select();
    const registrationId = search.replace("?", "");

    yield call(confirmRegistration, {
      ...action.data,
      registrationId,
    });

    yield put({
      type: CONFIRM_SUCCESS,
      payload: {
        message: "Your account was successfully created. Please Log In.",
      },
    });

    yield delay(1000);
    yield put(push("/auth/login"));
  } catch (error) {
    yield put({ type: CONFIRM_FAILURE, error });
  }
}

function* loginSaga(action: LoginT) {
  try {
    const { data } = yield call(loginUser, action.data);
    saveTokens(data);
    yield put({ type: LOGIN_SUCCESS });
    yield put({ type: CHECK });

    yield take(CHECK_SUCCESS);
    const admin: boolean = yield select((state) => state.authorization.admin);
    const path = admin ? "/users-list" : "/";
    yield put(push(path));
  } catch (error) {
    yield put({ type: LOGIN_FAILURE, error });
  }
}

function* forgotPasswordSaga(action: ForgotT) {
  try {
    yield call(forgetPassword, action.data);
    yield put({
      type: FORGOT_SUCCESS,
      payload: {
        message: "Your password has been successfully changed. Please Log In.",
      },
    });

    yield delay(1000);
    yield put(push("/auth/login"));
  } catch (error) {
    yield put({ type: FORGOT_FAILURE, error });
  }
}

function* recoveryPasswordSaga(action: RecoveryT) {
  try {
    const {
      router: {
        location: { search },
      },
    } = yield select();
    const recoverId = search.replace("?", "");

    yield call(recoveryPassword, {
      password: action.data.password,
      recoverId,
    });

    yield put({
      type: RECOVERY_SUCCESS,
      payload: {
        message:
          "Password reset link has been sent to your email, please follow instructions",
      },
    });

    yield delay(1000);
    yield put(push("/auth/login"));
  } catch (error) {
    yield put({ type: RECOVERY_FAILURE, error });
  }
}

function* checkUserSaga() {
  try {
    const token = getTokens().token || "";
    setToken(token);
    const { data } = yield call(checkUser);
    yield put({
      type: CHECK_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({ type: CHECK_FAILURE, error });
  }
}

function* logoutSaga() {
  try {
    removeUser();
    yield put({ type: LOGOUT_SUCCESS, userId: null });
    yield put(push("/auth/login"));
  } catch (error) {
    console.error(error);
    yield put({ type: LOGOUT_FAILURE, error: error.message });
  }
}

function* uploadPhotoSaga(action: UploadPhotoT) {
  try {
    yield call(uploadPhoto, action.file);
    yield put({ type: UPLOAD_PHOTO_SUCCESS });
    yield put({ type: CHECK });
    yield delay(100);
    yield put(resetUploadStatus());
  } catch (error) {
    console.error(error);
    yield put({ type: UPLOAD_PHOTO_FAILURE, error: error });
  }
}

function* removePhotoSaga() {
  try {
    yield call(removePhoto);
    yield put({ type: REMOVE_PHOTO_SUCCESS });
    yield put({ type: CHECK });
  } catch (error) {
    console.error(error);
    yield put({ type: REMOVE_PHOTO_FAILURE, error });
  }
}

function* changePasswordSaga(action: ChangePasswordT) {
  try {
    yield call(changePassword, action.data);
    yield put({ type: CHANGE_PASSWORD_SUCCESS });
  } catch (error) {
    console.log(error);
    yield put({ type: CHANGE_PASSWORD_FAILURE, error });
  }
}

function* checkToken() {
  axios.defaults.headers.common["X-Requested-With"] = window.location.origin;

  const pathname: string = yield select((store) => store.router?.location?.pathname);

  if (slashesRegexp.test(pathname.substring(0, 2))) {
    yield put(replace("/404"));
    return;
  }

  if (pathname.split("/").includes("auth")) {
    return;
  }

  const token = getTokens().token;

  if (token) {
    yield put({ type: CHECK });
  } else {
    yield put(replace("/auth/login"));
  }
}

export default function* authorizationSaga() {
  yield spawn(checkToken);
  yield takeEvery(CONFIRM, confirmSaga);
  yield takeEvery(LOGIN, loginSaga);
  yield takeEvery(FORGOT, forgotPasswordSaga);
  yield takeEvery(RECOVERY, recoveryPasswordSaga);
  yield takeEvery(CHECK, checkUserSaga);
  yield takeEvery(LOGOUT, logoutSaga);
  yield takeEvery(UPLOAD_PHOTO, uploadPhotoSaga);
  yield takeEvery(REMOVE_PHOTO, removePhotoSaga);
  yield takeEvery(CHANGE_PASSWORD, changePasswordSaga);
}
