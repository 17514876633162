import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";
import get from "lodash/get";

import SortIcon from "../SortIcon/SortIcon";
import TableUsersListRow from "./TableUsersListRow";
import DeleteUserModal from "../DeleteUserModal/DeleteUserModal";
import Modal from "../Modal/Modal";
import { AppState } from "../../store/modules";
import {
  StateT,
  getUsersList,
  switchDeleteModal,
} from "../../store/modules/users";
import { UserT } from "../../api/users";
import styles from "./TableUsersList.module.scss";

const TableUsersList = () => {
  const dispatch = useDispatch();
  const { users, loaded, error, isDeleteModalOpen, deleteId } = useSelector<
    AppState,
    StateT
  >((state) => state.users);

  const closeDeleteModal = () => dispatch(switchDeleteModal(false));

  const [list, setList] = useState<UserT[]>([]);
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState("id");

  useEffect(() => {
    if (!users?.length && !loaded && !error) {
      dispatch(getUsersList());
    }
  }, [users, dispatch, loaded, error]);

  useEffect(() => {
    if (users) {
      setList(users);
    }
  }, [users]);

  const changeOrder = (property: string) => {
    if (users) {
      const isAsc = orderBy === property && order === "asc";
      const copy = [...users];
      const sorted = copy.sort((prev: any, curr: any) => {
        if (get(prev, property) < get(curr, property)) {
          return isAsc ? -1 : 1;
        }
        if (get(prev, property) > get(curr, property)) {
          return isAsc ? 1 : -1;
        }
        return 0;
      });

      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
      setList(sorted);
    }
  };

  if (!users?.length) return null;

  return (
    <>
      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>
                <button
                  type="button"
                  className={styles.sort}
                  onClick={() => changeOrder("name")}
                >
                  <span className={cx(styles.sortTitle, styles.tableTitle)}>
                    Account ({users.length})
                  </span>
                  &nbsp;
                  <SortIcon order={order} isSorted={orderBy === "name"} />
                </button>
              </th>
              <th>
                <button
                  type="button"
                  className={styles.sort}
                  onClick={() => changeOrder("accountId")}
                >
                  <span className={cx(styles.sortTitle, styles.tableTitle)}>
                    Account id
                  </span>
                  &nbsp;
                  <SortIcon order={order} isSorted={orderBy === "accountId"} />
                </button>
              </th>
              <th>
                <button
                  type="button"
                  className={styles.sort}
                  onClick={() => changeOrder("address")}
                >
                  <span className={cx(styles.sortTitle, styles.tableTitle)}>
                    Address
                  </span>
                  &nbsp;
                  <SortIcon order={order} isSorted={orderBy === "address"} />
                </button>
              </th>
              <th>
                <button
                  type="button"
                  className={styles.sort}
                  onClick={() => changeOrder("installationPerformance.month")}
                >
                  <span className={cx(styles.sortTitle, styles.tableTitle)}>
                    Kwh/kW peak
                    <br />
                    (month&nbsp;|&nbsp;yesterday)
                  </span>
                  &nbsp;
                  <SortIcon
                    order={order}
                    isSorted={orderBy === "installationPerformance.month"}
                  />
                </button>
              </th>
              <th>
                <button
                  type="button"
                  className={styles.sort}
                  onClick={() => changeOrder("onPeakRatio.month")}
                >
                  <span className={cx(styles.sortTitle, styles.tableTitle)}>
                    On peak ratio
                    <br />
                    (month&nbsp;|&nbsp;yesterday)
                  </span>
                  &nbsp;
                  <SortIcon
                    order={order}
                    isSorted={orderBy === "onPeakRatio.month"}
                  />
                </button>
              </th>
              <th>
                <button
                  type="button"
                  className={styles.sort}
                  onClick={() => changeOrder("balance")}
                >
                  <span className={cx(styles.sortTitle, styles.tableTitle)}>
                    Balance
                  </span>
                  &nbsp;
                  <SortIcon order={order} isSorted={orderBy === "balance"} />
                </button>
              </th>
              <th>
                <button
                  type="button"
                  className={styles.sort}
                  onClick={() => changeOrder("upcomingBill")}
                >
                  <span className={cx(styles.sortTitle, styles.tableTitle)}>
                    Projected Bill
                  </span>
                  &nbsp;
                  <SortIcon
                    order={order}
                    isSorted={orderBy === "upcomingBill"}
                  />
                </button>
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {list.map((user: UserT) => (
              <TableUsersListRow {...user} key={user.id} />
            ))}
          </tbody>
        </table>
      </div>
      <Modal
        isOpen={isDeleteModalOpen}
        closeModal={closeDeleteModal}
        style={{ width: "468px" }}
      >
        {deleteId && <DeleteUserModal closeModal={closeDeleteModal} />}
      </Modal>
    </>
  );
};

export default TableUsersList;
