/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import cx from "classnames";

import ModalHeader from "../Modal/ModalHeader";
import { AppState } from "../../store/modules";
import { apiURL } from "../../api/axiosConfig";
import {
  StateT as UsersState,
  getBaseline,
  switchSavingShareModal,
  switchAdditionalServicesModal,
  switchReferralLinkModal,
  switchRateModal,
} from "../../store/modules/users";

import { ReactComponent as BeeIcon } from "../../assets/icons/Bee.svg";
import { ReactComponent as ResidenceIcon } from "../../assets/icons/Residence.svg";
import { ReactComponent as PowerIcon } from "../../assets/icons/Power.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/Edit.svg";
import stylesProfile from "../TableUsersList/TableUsersListRowProfile.module.scss";
import styles from "./UserDetailsModal.module.scss";

const url = apiURL();

const UserDetailsModal = () => {
  const dispatch = useDispatch();
  const { selectedUser } = useSelector<AppState, UsersState>(
    (state) => state.users
  );

  function openSavingShareModal() {
    dispatch(switchSavingShareModal(true, selectedUser?.id));
  }

  function additionalServicesModal() {
    dispatch(switchAdditionalServicesModal(true, selectedUser?.id));
  }

  function referralLinkModal() {
    dispatch(switchReferralLinkModal(true, selectedUser?.id));
  }

  function rateModal() {
    dispatch(switchRateModal(true, selectedUser?.id));
  }

  useEffect(() => {
    if (!selectedUser?.baseline && selectedUser?.id) {
      dispatch(getBaseline(selectedUser.id));
    }
  }, [dispatch, selectedUser]);

  if (!selectedUser) return null;

  const {
    address,
    ecobeeId,
    email,
    name,
    sensorIds,
    supplierCode,
    uploaded,
    panelSize,
    solarPanelSize,
    photoLink,
    accountId = "-",
    currentSavingsShare = 0,
  } = selectedUser;

    const renderNeurio = () => {
    if (!uploaded) return "...";
    if (sensorIds?.length) {
      return sensorIds.map((item: string) => (
        <div key={item} className={cx(styles.neurioItem, "group-icon")}>
          <ResidenceIcon className={styles.neurioIcon} />
          {item}
        </div>
      ));
    }
    return "-";
  };

  const renderPowerProvider = () => {
    if (!uploaded) return "...";
    return (
      <div className="group-icon">
        {supplierCode === "FPL" && <PowerIcon className={styles.powerIcon} />}
        {supplierCode || "-"}
      </div>
    );
  };

    const usedRate =
    selectedUser?.supplier?.rates[selectedUser.supplier.rates.length - 1] ||
    null;

  return (
    <>
      <ModalHeader>User details</ModalHeader>
      <div className={styles.formWrapper}>
        <div className={styles.fieldWrapper}>
          <div className={stylesProfile.content}>
            <div
              className={cx(stylesProfile.img, styles.avatar)}
              style={{ backgroundImage: `url(${url}${photoLink})` }}
            />
            <div>
              <div className={stylesProfile.name}>
                <p>{name}</p>
              </div>
              <a href={`mailto:${email}`} className={stylesProfile.email}>
                {email}
              </a>
            </div>
          </div>
        </div>
        <div className={styles.fieldWrapper}>
          <div className={styles.name}>Account Id</div>
          <div className={styles.value}>{accountId}</div>
        </div>
        <div className={styles.fieldWrapper}>
          <div className={styles.name}>Address</div>
          <div className={styles.value}>{address}</div>
        </div>
        <div className={styles.fieldWrapper}>
          <div className={styles.name}>Ecobee</div>
          <div className={styles.value}>
            {(ecobeeId && (
              <div className="group-icon">
                <BeeIcon className={styles.ecobeeIcon} />
                {ecobeeId}
              </div>
            )) ||
              "-"}
          </div>
        </div>
        <div className={styles.fieldWrapper}>
          <div className={styles.name}>Neurio</div>
          <div className={styles.value}>{renderNeurio()}</div>
        </div>
        <div className={styles.fieldWrapper}>
          <div className={styles.name}>Power Provider</div>
          <div className={styles.value}>{renderPowerProvider()}</div>
        </div>
        <div className={styles.fieldWrapper}>
          <div className={styles.name}>Rate</div>
          <div className={cx(styles.value, styles.savingShare)}>
            {usedRate?.name}
            <button onClick={rateModal} className={styles.editButton}>
              <EditIcon className={styles.editIcon} />
            </button>
          </div>
        </div>
        <div className={styles.fieldWrapper}>
          <div className={styles.name}>Solar Panel Size, kW</div>
          <div className={styles.value}>
            {panelSize || solarPanelSize || "-"}
          </div>
        </div>
        <div className={styles.fieldWrapper}>
          <div className={styles.name}>Saving Share</div>
          <div className={cx(styles.value, styles.savingShare)}>
            {currentSavingsShare * 100} %
            <button
              onClick={openSavingShareModal}
              className={styles.editButton}
            >
              <EditIcon className={styles.editIcon} />
            </button>
          </div>
        </div>
        <div className={styles.fieldWrapper}>
          <div className={styles.name}>Additional Services</div>
          <div className={cx(styles.value, styles.savingShare)}>
            <button
              onClick={additionalServicesModal}
              className={styles.editButton}
            >
              <EditIcon className={styles.editIcon} />
            </button>
          </div>
        </div>
        <div className={styles.fieldWrapper}>
          <div className={styles.name}>Referral Link</div>
          <div className={cx(styles.value, styles.savingShare)}>
            <button onClick={referralLinkModal} className={styles.editButton}>
              <EditIcon className={styles.editIcon} />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDetailsModal;
