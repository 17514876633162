import React, { useEffect } from "react";
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts";

import CustomTooltip from "../CustomChartTooltip/CustomChartTooltip";
import { WeeklyEnergyT } from "../../api/dashboard";
import {
  transformedWeekData,
  calculateOnPeakConsumption,
  calculateOffPeakConsumption,
  calculateTotalConsumption,
} from "../../helpers/grid";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/modules";
import { StateT as AuthState } from "../../store/modules/authorization";

import colors from "../../assets/style/variables/colors.scss";
import styles from "./DesktopWeeklyChart.module.scss";
import { useParams } from "react-router-dom";
import { getUserInfo, StateT as userState } from "../../store/modules/users";

type PropsT = {
  energy: WeeklyEnergyT;
  forAdmin?: boolean;
};

const DesktopWeeklyChart = (props: PropsT) => {
  const {
    energy: { elements },
    forAdmin = false,
  } = props;
  const {userId} = useParams<any>();
  const dispatch = useDispatch();

  const {isFlat} = useSelector<AppState, userState>(
    (state) => state.users
  );
  const { currentRate } = useSelector<AppState, AuthState>(
    (state) => state.authorization
  );

  useEffect(() => {
    if ((!currentRate?.flat && !isFlat && userId)) {
      dispatch(getUserInfo(userId));
    }
  }, [currentRate, dispatch, isFlat, userId])

  const chartData = transformedWeekData.reduce(
    (acc: Array<any>, curr: any, index: number) => {
      return [
        ...acc,
        {
          ...elements[index],
          generation:
            (elements[index]?.onPeakGeneration ||
            0) + (elements[index]?.offPeakGeneration ||
            0),
        },
      ];
    },
    []
  );
  const renderChart = () => (
    <ComposedChart
      margin={
        forAdmin ? { left: 15, top: 20, right: 32 } : { top: 20, right: 20 }
      }
      data={chartData}
      stackOffset="sign"
    >
      <CartesianGrid strokeDasharray="3 3" horizontal={false} />
      <YAxis
        tick={{
          stroke: colors["grey"],
          strokeWidth: 0.5,
          fontWeight: 300,
        }}
        tickLine={false}
        axisLine={false}
        type="number"
        tickMargin={30}
      />
      <XAxis
        dataKey="label"
        axisLine={false}
        tick={{
          stroke: colors["grey"],
          strokeWidth: 0.5,
          fontWeight: 300,
        }}
        padding={{ left: 20, right: 0 }}
        tickLine={false}
      />
      <Bar
        dataKey="generation"
        fill={colors["orange"]}
        stackId="stack"
        radius={2}
      />
      {(!currentRate?.flat && !isFlat) && (
        <Bar
          dataKey={calculateOffPeakConsumption}
          fill={colors["cyan"]}
          stackId="stack"
          radius={2}
        />
      )}
      {(!currentRate?.flat && !isFlat) && (
        <Bar
          dataKey={calculateOnPeakConsumption}
          fill={colors["dark-ocean"]}
          stackId="stack"
          radius={2}
        />
      )}
      {(currentRate?.flat || isFlat) && (
        <Bar
          dataKey={calculateTotalConsumption}
          fill={colors["cyan"]}
          stackId="stack"
          radius={2}
        />
      )}
      <ReferenceLine y={0} stroke={colors["swans-down"]} strokeWidth={1} />
      <Tooltip
        content={<CustomTooltip />}
        cursor={{ stroke: colors["black"], strokeWidth: 2 }}
        viewBox={{ x: 0, y: 0, width: 400, height: 400 }}
      />
    </ComposedChart>
  );

  return (
    <ResponsiveContainer
      height="100%"
      width="100%"
      aspect={forAdmin ? 2.0 : 4.0 / 1.0}
      className={styles.responsiveContainer}
    >
      {renderChart()}
    </ResponsiveContainer>
  );
};

export default DesktopWeeklyChart;
