import React from "react";
import { isMobileOnly } from "react-device-detect";

import Modal from "../Modal/Modal";
import { ReactComponent as Icon } from "./../../assets/icons/Paid.svg";
import styles from "./SuccessAlert.module.scss";
import { hideAlert } from "../../store/modules/successAlert";
import { useDispatch } from "react-redux";

type PropsT = {
  message?: string;
  isOpen: boolean;
};

export default function SuccessAlert(props: PropsT) {
  const dispatch = useDispatch();
  const { message = "Success!", isOpen } = props;

  function closeModal() {
    dispatch(hideAlert());
  }

  const modalStyle = {
    width: isMobileOnly ? "calc(100% - 40px)" : "468px",
    height: "62px",
    padding: "6px 16px",
  };

  return (
    <Modal
      isOpen={isOpen}
      style={modalStyle}
      contentClassName={styles.contentWrapper}
      showCloseButton={false}
      closeModal={closeModal}
    >
      <Icon />
      {message}
    </Modal>
  );
}
