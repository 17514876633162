import React from "react";
// import cx from "classnames";

// import Button from "../Button/Button";
import DesktopNomination from "../DesktopNomination/DesktopNomination";
// import NumericValueFormat from "../NumericValueFormat/NumericValueFormat";

import { ReactComponent as Chart } from "../../assets/icons/Chart.svg";
// import styles from "../../containers/Home/Home.module.scss";

type PropsT = {
  billPeriod?: string;
  lifetimeSavings: number;
  lifetimeSavingsShare: number;
  admin: boolean;
  navigateToBillsPage: () => void;
};

export default function DesktopLifetimeSavingsBlock(props: PropsT) {
  const {
    billPeriod,
    lifetimeSavingsShare,
  } = props;

  return (
    <DesktopNomination
      Icon={Chart}
      title="Savings"
      subtitle="Your lifetime savings"
      dates={billPeriod}
      bill={lifetimeSavingsShare}
    >
    </DesktopNomination>
  );
}
