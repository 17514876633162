import React, { useEffect } from "react";
import {
  ComposedChart,
  Bar,
  XAxis,
  ReferenceLine,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/modules";
import { StateT as AuthState } from "../../store/modules/authorization";
import { useMediaQuery } from "react-responsive";

import CustomTooltip from "../CustomChartTooltip/CustomChartTooltip";
import {
  calculateOnPeakConsumption,
  calculateOffPeakConsumption,
  calculateTransformedMonthData,
  calculateTotalConsumption,
} from "../../helpers/grid";

import colors from "../../assets/style/variables/colors.scss";
import { useParams } from "react-router-dom";
import { getUserInfo, StateT as userState } from "../../store/modules/users";

type PropsT = {
  energy: any;
};

const MobileMonthlyChart = (props: PropsT) => {
  const {
    energy: { elements },
  } = props;

  const { currentRate } = useSelector<AppState, AuthState>(
    (state) => state.authorization
  );
  const {userId} = useParams<any>();
  const dispatch = useDispatch();

  const {isFlat} = useSelector<AppState, userState>(
    (state) => state.users
  );

  useEffect(() => {
    if ((!currentRate?.flat && !isFlat && userId)) {
      dispatch(getUserInfo(userId));
    }
  }, [currentRate, dispatch, isFlat, userId])

  const isLandscape = useMediaQuery({ query: "(orientation: landscape)" });
  const chartData = calculateTransformedMonthData(elements);

  return (
    <div>
      <ResponsiveContainer
        height="100%"
        width="100%"
        aspect={isLandscape ? 3.0 : 4.0 / 3.0}
      >
        <ComposedChart
          margin={{ left: 10, top: 22 }}
          data={chartData}
          stackOffset="sign"
        >
          <CartesianGrid strokeDasharray="3 3" horizontal={false} />
          <XAxis
            dataKey="label"
            axisLine={false}
            tick={{
              stroke: colors["grey"],
              strokeWidth: 0.5,
              fontWeight: 300,
            }}
            tickFormatter={(num) => `Week ${num}`}
            padding={{ left: 0, right: 4 }}
            tickLine={false}
          />
          <Bar
            dataKey="generation"
            fill={colors["orange"]}
            stackId="stack"
            radius={2}
          />
          {(!currentRate?.flat && !isFlat) && (
            <Bar
              dataKey={calculateOffPeakConsumption}
              fill={colors["cyan"]}
              stackId="stack"
              radius={2}
            />
          )}
          {(!currentRate?.flat && !isFlat) && (
            <Bar
              dataKey={calculateOnPeakConsumption}
              fill={colors["dark-ocean"]}
              stackId="stack"
              radius={2}
            />
          )}
          {(currentRate?.flat || isFlat) && (
            <Bar
              dataKey={calculateTotalConsumption}
              fill={colors["cyan"]}
              stackId="stack"
              radius={2}
            />
          )}
          <ReferenceLine
            y={0}
            stroke={colors["grey"]}
            strokeOpacity={0.2}
            strokeWidth={1}
          />
          <Tooltip
            content={<CustomTooltip />}
            position={{ x: 0, y: -146 }}
            cursor={{ stroke: colors["black"], strokeWidth: 2 }}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MobileMonthlyChart;
