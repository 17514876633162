import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import Button from "../Button/Button";

type PropsT = {
  className?: string;
  onClick?: () => void;
};

export default function PayNowButton({ className, onClick }: PropsT) {
  const history = useHistory();
  const navigateToPaymentsPage = useCallback(() => {
    history.push("/payment-details", { toBills: true });
  }, [history]);

  return (
    <Button
      variant="outlined"
      onClick={onClick || navigateToPaymentsPage}
      className={className}
    >
      Set up Autopay
    </Button>
  );
}
