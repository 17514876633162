import React, { useMemo, useEffect } from "react";
import { Form, Field, withFormik } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import cx from "classnames";

import ModalHeader from "../Modal/ModalHeader";
import ModalFooter from "../Modal/ModalFooter";
import Button from "../Button/Button";
import InputField from "../InputField/InputField";
import { Loader } from "../Loading/Loading";
import { AppState } from "../../store/modules";
import { StateT as AuthorizationState } from "../../store/modules/authorization";
import { ChangingPasswordT } from "../../api/authorization";

import styles from "../InviteFriendForm/InviteFriendForm.module.scss";
import ValidateFormMessage from "../ValidateFormMessage/ValidateFormMessage";

type PropsT = {
  onSubmit: (data: ChangingPasswordT) => void;
  close: () => void;
  email: string;
};

type RenderFieldPropsT = {
  name: string;
  label: string;
  placeholder: string;
  disabled?: boolean;
  type?: string;
};

function renderField(row: RenderFieldPropsT) {
  return (
    <Field
      component={InputField}
      variant="outline"
      name={row.name}
      key={row.name}
      label={row.label}
      placeholder={row.placeholder}
      className={styles.fieldItem}
      autoComplete="off"
      disabled={row.disabled || false}
      type={row.type}
    />
  );
}

function ChangePassword(props: PropsT) {
  const rows = useMemo(
    () => [
      {
        name: "email",
        label: "Email",
        placeholder: "Enter your email",
        disabled: true,
      },
      {
        name: "oldPassword",
        label: "Old Password",
        placeholder: "Enter old password",
        type: "password",
      },
      {
        name: "newPassword",
        label: "New Password",
        placeholder: "Enter new password",
        type: "password",
      },
    ],
    []
  );

  const { loading, loaded, error } = useSelector<AppState, AuthorizationState>(
    (state: AppState) => state.authorization
  );

  useEffect(() => {
    if (loaded) {
      props.close();
    }
  }, [loaded, props]);

  if (loading) {
    return (
      <div className={styles.loaderWrapper}>
        <Loader size={180} className={styles.loader} />
      </div>
    );
  }

  return (
    <>
      <ModalHeader className={cx(styles.header, styles.changePasswordHeader)}>
        Change password
      </ModalHeader>

      <Form id="invite-friend" className={styles.contentWrapper}>
        {rows.map(renderField)}
      </Form>

      <ModalFooter>
        <ValidateFormMessage error={error} className={styles.error} />
        <Button
          variant="outlined"
          type="submit"
          form="invite-friend"
          className={styles.sendButton}
        >
          Change password
        </Button>

        <Button onClick={props.close}>Cancel</Button>
      </ModalFooter>
    </>
  );
}

export default withFormik<PropsT, ChangingPasswordT>({
  displayName: `withFormik${ChangePassword.name}`,
  mapPropsToValues: (props: PropsT) => ({
    email: props.email,
    oldPassword: "",
    newPassword: "",
  }),
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email("That format doesn't look right")
      .required("Required field"),
    oldPassword: Yup.string().required("Required field"),
    newPassword: Yup.string().required("Required field"),
  }),
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },
})(ChangePassword);
