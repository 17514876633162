import { put, takeEvery, all, call } from "redux-saga/effects";
import {
  GetUpcomingBillsByHomeIdT,
  PayBillRequestT,
  GetBillsInformationByIdT,
  GET_UPCOMING_BILLS,
  GET_BILLS_INFORMATION_REQUEST,
  GET_UPCOMING_BILLS_BY_HOME_ID,
  GET_UPCOMING_BILLS_BY_HOME_ID_SUCCESS,
  GET_UPCOMING_BILLS_BY_HOME_ID_FAILURE,
  PAY_BILL_REQUEST,
  GET_BILLS_INFORMATION_BY_ID,
  payBillSuccess,
  payBillFailure,
  getUpcomingBillsSuccess,
  getUpcomingBillsFailure,
  getBillsInformationSuccess,
  getBillsInformationFailure,
} from "../modules/bills";
import {
  getUpcomingBills,
  getClosedBills,
  getUpcomingBillsByHomeId,
  getComparativeBill,
  payBill,
  getClosedBillsById,
  getComparativeBillById,
} from "../../api/bills";

function* getUpcomingBillsSaga() {
  try {
    const { data: upcoming } = yield call(getUpcomingBills);
    yield put(getUpcomingBillsSuccess(upcoming));
  } catch (error) {
    yield put(getUpcomingBillsFailure(error));
  }
}

function* getBillsInformationSaga() {
  try {
    const [
      { data: upcoming },
      { data: closed },
      { data: comparison },
    ] = yield all([
      call(getUpcomingBills),
      call(getClosedBills),
      call(getComparativeBill),
    ]);

    yield put(getBillsInformationSuccess({ upcoming, closed, comparison }));
  } catch (error) {
    yield put(getBillsInformationFailure(error));
  }
}

function* getUpcomingBillsByHomeIdSaga(action: GetUpcomingBillsByHomeIdT) {
  try {
    const { data: userUpcoming } = yield call(
      getUpcomingBillsByHomeId,
      action.userId
    );
    yield put({ type: GET_UPCOMING_BILLS_BY_HOME_ID_SUCCESS, userUpcoming });
  } catch (error) {
    yield put({ type: GET_UPCOMING_BILLS_BY_HOME_ID_FAILURE, error });
  }
}

function* payBillSaga({ id }: PayBillRequestT) {
  try {
    yield call(payBill, id);
    yield put(payBillSuccess());
  } catch (error) {
    yield put(payBillFailure(error));
  }
}

function* getBillsInformationByIdSaga(action: GetBillsInformationByIdT) {
  try {
    const [
      { data: upcoming },
      { data: closed },
      { data: comparison },
    ] = yield all([
      call(getUpcomingBillsByHomeId, action.userId),
      call(getClosedBillsById, action.userId),
      call(getComparativeBillById, action.userId),
    ]);

    yield put(getBillsInformationSuccess({ upcoming, closed, comparison }));
  } catch (error) {
    yield put(getBillsInformationFailure(error));
  }
}

export default function* billsSaga() {
  yield takeEvery(GET_UPCOMING_BILLS, getUpcomingBillsSaga);
  yield takeEvery(GET_BILLS_INFORMATION_REQUEST, getBillsInformationSaga);
  yield takeEvery(GET_BILLS_INFORMATION_BY_ID, getBillsInformationByIdSaga);
  yield takeEvery(GET_UPCOMING_BILLS_BY_HOME_ID, getUpcomingBillsByHomeIdSaga);
  yield takeEvery(PAY_BILL_REQUEST, payBillSaga);
}
