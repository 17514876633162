export function formatMoney(value: number = 0) {
  return `$${Math.round(value).toLocaleString("en")}`;
}

export function formatEnergy(value: number = 0) {
  return `${Math.round(value).toLocaleString("en")}kWh`;
}

export function formatPercent(value: number = 0) {
  return `${Math.round(value).toLocaleString("en")}%`;
}
