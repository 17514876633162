import colors from "../../assets/style/variables/colors.scss";

type BaseStyleT = {
  stroke: string;
  strokeWidth: number;
  strokeLinecap: "round";
  transition: string;
};

export const baseStyle: BaseStyleT = {
  stroke: colors["botticelli"],
  strokeWidth: 8,
  strokeLinecap: "round",
  transition: "stroke-dashoffset 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)",
};

export const LINE_LENGTH = {
  outer: 470.76,
  middle: 385.52,
  inner: 293.76,
};

export const RED = colors["error"];
export const GREY = colors["grey"];
export const BLACK = colors["black"];
export const WHITE = colors["white"];
export const BLUE = colors["blue"];
export const ORANGE = colors["orange"];
export const CYAN = colors["cyan"];
export const DARKOCEAN = colors["dark-ocean"];

export const CHART_STYLES = {
  outer: { stroke: ORANGE, strokeDasharray: LINE_LENGTH.outer },
  middle: {
    stroke: CYAN,
    strokeDasharray: LINE_LENGTH.middle,
  },
  inner: { stroke: DARKOCEAN, strokeDasharray: LINE_LENGTH.inner },
};

export type CoordinatesT = {
  circle: {
    cy: number;
  };
  mark: {
    y: number;
  };
  text: { y: number };
};

export const coordinates = {
  generation: {
    circle: {
      cy: 35,
    },
    mark: {
      y: 40,
    },
    text: { y: 10 },
  },
  offPeak: {
    circle: {
      cy: 100,
    },
    mark: {
      y: 105,
    },
    text: { y: 75 },
  },
  onPeak: {
    circle: {
      cy: 165,
    },
    mark: {
      y: 170,
    },
    text: { y: 140 },
  },
};
