import React, { useEffect } from "react";
import cx from "classnames";
import { useSelector, useDispatch } from "react-redux";

import Button from "../Button/Button";
import ModalHeader from "../Modal/ModalHeader";
import ModalContent from "../Modal/ModalContent";
import ModalFooter from "../Modal/ModalFooter";
import CSVLoader from "../CSVLoader/CSVLoader";
import { AppState } from "../../store/modules";
import {
  setCanInvite,
  StateT as InviteUsersState,
} from "../../store/modules/inviteUser";

import { ReactComponent as Chevron } from "../../assets/icons/Chevron-left.svg";
import styles from "./BaselineForm.module.scss";

type PropsT = {
  closeModal: () => void;
  goBack: () => void;
  isHidden: boolean;
  onSubmit: () => void;
  inviting: boolean;
  invited: boolean;
  onComponentUnmount?: () => void;
  setBaselineData?: any;
};

function BaselineForm(props: PropsT) {
  const {
    closeModal,
    goBack,
    isHidden,
    inviting,
    invited,
    onComponentUnmount,
    setBaselineData,
    onSubmit,
  } = props;
  const dispatch = useDispatch();
  const { canInvite } = useSelector<AppState, InviteUsersState>(
    (state: AppState) => state.inviteUser
  );

  function close() {
    closeModal();
    dispatch(setCanInvite(false));
  }

  useEffect(() => {
    return () => {
      if (onComponentUnmount) {
        onComponentUnmount();
      }
    };
  }, [onComponentUnmount]);

  useEffect(() => {
    if (invited) {
      closeModal();
    }
  }, [invited, closeModal]);

  return (
    <div className={cx({ [styles.hidden]: isHidden })}>
      <ModalHeader>
        <span className={styles.title}>User baseline usage data</span>
      </ModalHeader>

      <ModalContent>
        <CSVLoader setBaselineData={setBaselineData} />
      </ModalContent>

      <ModalFooter>
        <Button
          variant="outlined"
          type="submit"
          disabled={inviting || !canInvite}
          onClick={onSubmit}
        >
          {inviting ? "loading..." : "Send Invite"}
          {invited && "User Invited"}
        </Button>

        <Button onClick={close}>Cancel</Button>

        <Button className={styles.goBack} onClick={goBack}>
          <Chevron />
          Back
        </Button>
      </ModalFooter>
    </div>
  );
}

export default BaselineForm;
