/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";

import ModalHeader from "../Modal/ModalHeader";
import ModalContent from "../Modal/ModalContent";
import ModalFooter from "../Modal/ModalFooter";
import Button from "../Button/Button";
import { StateT as UsersState } from "../../store/modules/users";
import { AppState } from "../../store/modules";
import {
  changeAdditionalService,
  getAdditionalServices,
} from "../../api/users";
import { dateFormat, formatMonthDayYear } from "../../helpers/formatDate";
import "./AdditionalServicesModal.scss";
import { Field, Form, Formik, FormikConfig } from "formik";
import InputField from "../InputField/InputField";

type PropsT = {
  closeModal: () => void;
  setSuccessEditAdditionalCharges: (value: boolean) => void;
};

export default function AdditionalServicesModal(props: PropsT) {
  const { closeModal, setSuccessEditAdditionalCharges } = props;
  const { selectedUser } = useSelector<AppState, UsersState>(
    (state) => state.users
  );
  const [additionalCharges, setAdditionalCharges] = useState<any>();
  const [date, setDate] = useState(new Date());
  const [data, setData] = useState([]);

  const onChange = (val: number) => setAdditionalCharges(val);

  useEffect(() => {
    if (selectedUser?.id) {
      (async () => {
        const result = await getAdditionalServices(selectedUser.id);
        if (result.status === 200) {
          setData(result.data);
        }
      })();
    }
  }, [selectedUser?.id]);

  useEffect(() => {
    if (data?.length) {
      // @ts-ignore
      setAdditionalCharges(data[data.length - 1]?.cost);
    }
  }, [data]);

  const handleChangeDate = (date: Date) => setDate(date);

  const validate = (values: any) => {
    const errors: any = {};

    if (!values.additionalCharges && values.additionalCharges !== 0) {
      errors.additionalCharges = "Required field";
    } else if (isNaN(values.additionalCharges)) {
      errors.additionalCharges = "Invalid value";
    }
    return errors;
  };

  const onSubmit = async (values: any) => {
    const result = await changeAdditionalService(
      selectedUser?.id,
      values.additionalCharges,
      date
    );
    if (result.status === 200) {
      setSuccessEditAdditionalCharges(true);
      closeModal();
    }
  };

  const form: FormikConfig<any> = {
    initialValues: {
      additionalCharges,
    },
    enableReinitialize: true,
    onSubmit,
    validate,
  };

  return (
    <Formik {...form}>
      {(): React.ReactNode => {
        return (
          <Form noValidate className={"additionalServicesModal"}>
            <div>
              <ModalHeader>Additional Services</ModalHeader>
              <ModalContent>
                <div className={"date"}>
                  <p>Valid from:&nbsp;&nbsp;&nbsp;</p>
                  <DatePicker
                    selected={date}
                    onChange={handleChangeDate}
                    dateFormat={dateFormat}
                    showMonthDropdown
                    showYearDropdown
                  />
                </div>
                <div className={"field"}>
                  <label>Additional charges:</label>
                  <div className="fieldWrapper">
                    <p className={"dollar"}>$</p>
                    <Field
                      component={InputField}
                      variant="outline"
                      name={`additionalCharges`}
                      type="number"
                      onChange={onChange}
                      value={additionalCharges}
                    />
                  </div>
                </div>
                <div className={"historyLabel"}>Change history:</div>
                <div className={"tableWrapper"}>
                  <b className={"box"}>Additional Service</b>
                  <b className={"box"}>Valid From</b>
                  <b className={"box"}>Valid To</b>

                  {data?.length
                    ? data.map((item: any) => (
                        <div className={"row"} key={item.validFrom.toString()}>
                          <div className={"box"}>{item.cost}</div>
                          <div className={"box"}>
                            {formatMonthDayYear(item.validFrom)}
                          </div>
                          <div className={"box"}>
                            {formatMonthDayYear(item.validTo)}
                          </div>
                        </div>
                      ))
                    : "-"}
                </div>
              </ModalContent>
              <ModalFooter>
                <Button variant="outlined" type="submit">
                  Save
                </Button>
                <Button onClick={closeModal}>Cancel</Button>
              </ModalFooter>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
