export default [
  {
    title: "Is going solar right for me?",
    description: `
  ###### Is going solar right for me?
  A general rule of thumb for Florida is that if your roof doesn’t get much shade during the day and have an electric bill averages $150+ per month, solar energy will make financial sense for you – no matter if you pay upfront or finance the whole project.
    `,
  },
  {
    title: "What kind of support do you offer?",
    description: `
  ###### What kind of support do you offer?
  25-yr system warranty  The beautiful thing about solar photovoltaic technology is that there are no moving parts. However, for extra peace of mind your system is under warranty for 25 years.
  24/7 monitoring  Transparency is key. That is why all our systems are equipped with real time tracking software. You can track your energy consumption vs. production and dive deeper to see how well each solar panel is producing.
  20-year workmanship guarantee We are always there for you. That means if you have any questions, you notice something, or your energy needs change, we are always just a phone call away. For repairs or service issues, we will be at your home within 48 hours.
    `,
  },
  {
    title: "How do you determine how many panels I should have?",
    description: `
  ###### How do you determine how many panels I should have?
  There is no “one size fits all”. When determining the size of your solar system, there are many factors at play. We perform a full analysis on your energy consumption and roof – from there, we design a solar system that matches your needs perfectly.
    `,
  },
  {
    title: "Is there any required maintenance?",
    description: `
  ###### Is there any required maintenance?
  Very little to no maintenance is required. Solar panels have no moving parts – they are extremely durable and should last around 25-30 years with no maintenance besides casual checkups and cleanings. With the said, our installations include a 20 year workmanship warranty so we are always there for you.
    `,
  },
  {
    title: "How long does installation take?",
    description: `
  ###### How long does installation take?
  Depends on the size of your home. On average installation takes between 2 and 4 days.
    `,
  },
  {
    title: "Can I monitor solar output?",
    description: `
  ###### Can I monitor solar output?
  All of our systems include 24/7 online monitoring so you can keep track of your real time energy production and consumption. Our monitoring systems have built-in alerts that will let both you and us know if there is something that need our attention.
    `,
  },
  {
    title: "Can I go completely off the grid?",
    description: `
  ###### Can I go completely off the grid?
  You definitely can! We don’t really recommend it since it would mean you’d need to invest in a big battery system for your home in order to serve all your energy needs day and night. It’s better to invest in solar, stay connected to the electric grid, and take advantage of regulations like net metering.
    `,
  },
  {
    title: "What is Net Metering?",
    description: `
  ###### What is Net Metering?
  Once you go solar, FP&L will come out to change your utility meter to a bi-directional meter (net meter). With a net meter in place, you’ll get a dollar for dollar credit for all the solar energy you send back into the grid. For instance, if during the day you produce 50kWh of energy yet consume only 25kWh in that time, the extra 25kWh your home generated will be available to use during nighttime for free.
    `,
  },
  {
    title: "What happens during power outages?",
    description: `
  ###### What happens during power outages?
  As a safety mechanism, solar panels shut down during power outages. Otherwise utility field technicians would have no way to be sure when wires are live or dead when they’re making repairs.
  There is a way around it – with a battery backup system and an automatic transfer switch, your home can continue to operate even during a power outage.
    `,
  },
  {
    title: "Does adding a battery make sense?",
    description: `
  ###### Does adding a battery make sense?
  It depends on your needs. Right now, batteries are quite cost-prohibitive. For energy backup service during power outages, we recommend using a generator.
  We continue to expect battery prices to drop significantly during the next 3 years. The awesome thing about going solar is that you can add batteries any time afterwards and the additional costs of doing so are minimal.
    `,
  },
  {
    title: "What are my savings in South Florida?",
    description: `
  ###### What are my savings in South Florida?
  This is the time to go solar in South Florida. On top of the savings on your electric bills, you will also generate savings through tax credit, net metering and tax deductions.
  Investment Tax Credit Whether you pay cash or finance your solar system, you are eligible for the 30% federal Investment Tax Credit (ITC) until the end of 2019. After 2019, the tax credit will be stepping down to 26% in 2020, 22% in 2021, and eventually zero by 2022.
  Tax Savings If you finance your system using a PACE program, the interest you pay on your loan is tax deductible.
  Net Metering If you produce more energy than you consume during a particular month, FP&L will credit your account for the next billing cycle. Any extra credits you have at the end of the year, FP&L will credit to you at wholesale energy rates.
    `,
  },
  {
    title: "Do you offering financing?",
    description: `
  ###### Do you offering financing?
  Yes! Most people finance their solar systems to make it easier on their wallet. With our financing, it’s very typical to pay no money up front and save money month to month compared to what households were used to paying in monthly electricity bills.
    `,
  },
  {
    title: "Why Sprightful?",
    description: `
  ###### Why Sprightful?
  You need to do your research when choosing your solar installer. None of our work is sub-contracted. We streamlined the process so we could have the lowest possible prices, highest quality, and best-in-class warranties. We are endlessly passionate about renewable energy and making it financially attractive for you.
  
  Once you partner with us, you join the Sprightful family for life. Anything we can do to enhance your solar experience, we are there. Our work doesn’t stop after installation. We monitor your system to make sure that your electric bills are correct each billing cycle, and our savings goals are being hit.
  100% Customer Focused  Transparency and communication is key. Whether it is during the installation or after, reaching out to us is as simple as a quick text or call. We believe our relationship with you is the best asset we could ever build – and we’ll go above and beyond to make sure it flourishes.
  Fully Customizable Designs We want to maximize your return on investment. All our proposals are designed to match your energy consumption and lifestyle.
  Cutting-Edge Technology We use the latest in solar technology to give you the most efficient system. We install smart technology with every system so you can keep an eye on every aspect of you solar system, as well as keep an eye on which appliances are using the most energy.
  Aesthetics and Performance Driven Solar is the new black. We make sure all our systems meet our aesthetic standards. This means sleek, high performing systems that look beautiful on your roof while remaining financially smart.
    `,
  },
];
