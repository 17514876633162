import { PaymentDetailsT } from "../../api/paymentDetails";

export const SAVE_PAYMENT_DETAILS = "SAVE_PAYMENT_DETAILS";
export const SAVE_PAYMENT_DETAILS_SUCCESS = "SAVE_PAYMENT_DETAILS_SUCCESS";
export const SAVE_PAYMENT_DETAILS_FAILURE = "SAVE_PAYMENT_DETAILS_FAILURE";
export const LINK_BANK_ACCOUNT_REQUEST = "LINK_BANK_ACCOUNT_REQUEST";
export const LINK_BANK_ACCOUNT_SUCCESS = "LINK_BANK_ACCOUNT_SUCCESS";
export const LINK_BANK_ACCOUNT_FAILURE = "LINK_BANK_ACCOUNT_FAILURE";
export const SEND_BANK_TOKEN_REQUEST = "SEND_BANK_TOKEN_REQUEST";
export const SEND_BANK_TOKEN_SUCCESS = "SEND_BANK_TOKEN_SUCCESS";
export const SEND_BANK_TOKEN_FAILURE = "SEND_BANK_TOKEN_FAILURE";
export const RESET_STATE = "RESET_STATE";

export type SavePaymentDetailsT = {
  type: typeof SAVE_PAYMENT_DETAILS;
  values: PaymentDetailsT;
};

export type SavePaymentDetailsSuccessT = {
  type: typeof SAVE_PAYMENT_DETAILS_SUCCESS;
  payload: any;
};

export type SavePaymentDetailsFailureT = {
  type: typeof SAVE_PAYMENT_DETAILS_FAILURE;
  error: string;
};

type LinkBankAccountRequestT = {
  type: typeof LINK_BANK_ACCOUNT_REQUEST;
}

export type LinkBankAccountSuccessT = {
  type: typeof LINK_BANK_ACCOUNT_SUCCESS;
  data: {
    token: string;
    account: any
  }
}

type LinkBankAccountErrorT = {
  type: typeof LINK_BANK_ACCOUNT_SUCCESS;
  error: string;
}

type SendBankTokenRequestT = {
  type: typeof SEND_BANK_TOKEN_REQUEST;
}

type SendBankTokenSuccessT = {
  type: typeof SEND_BANK_TOKEN_SUCCESS;
}

type SendBankTokenFailureT = {
  type: typeof SEND_BANK_TOKEN_FAILURE;
  error: string;
}

type ResetStateT = {
  type: typeof RESET_STATE;
}

export type ActionTypeT =
  | SavePaymentDetailsT
  | SavePaymentDetailsSuccessT
  | SavePaymentDetailsFailureT
  | LinkBankAccountRequestT
  | LinkBankAccountSuccessT
  | LinkBankAccountErrorT
  | SendBankTokenRequestT
  | SendBankTokenSuccessT
  | SendBankTokenFailureT
  | ResetStateT;

export type StateT = {
  loading: boolean;
  error: string | null;
  linked: boolean;
};

const initialState: StateT = {
  loading: false,
  linked: false,
  error: null,
};

export default function savePaymentDetailsReducer(
  state: StateT = initialState,
  action: ActionTypeT
): StateT {
  switch (action.type) {
    case SAVE_PAYMENT_DETAILS:
    case SEND_BANK_TOKEN_REQUEST:
      return { ...state, loading: true };
    case SAVE_PAYMENT_DETAILS_SUCCESS:
    case SEND_BANK_TOKEN_SUCCESS:
      return { ...state, loading: false, linked: true };
    case SAVE_PAYMENT_DETAILS_FAILURE:
    case SEND_BANK_TOKEN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case RESET_STATE: {
      return { ...initialState };
    }

    default:
      return state;
  }
}

export function savePaymentDetails(values: PaymentDetailsT) {
  return {
    type: SAVE_PAYMENT_DETAILS,
    values,
  };
}

export function linkBankAccount(): LinkBankAccountRequestT {
  return {
    type: LINK_BANK_ACCOUNT_REQUEST,
  }
}

export function linkBankAccountSuccess(token: string, account: any): LinkBankAccountSuccessT {
  return {
    type: LINK_BANK_ACCOUNT_SUCCESS,
    data: { token, account },
  }
}

export function sendBankToken(): SendBankTokenRequestT {
  return {
    type: SEND_BANK_TOKEN_REQUEST,
  };
}

export function sendBankTokenSuccess(): SendBankTokenSuccessT {
  return {
    type: SEND_BANK_TOKEN_SUCCESS,
  };
}

export function sendBankTokenFailure(error: string): SendBankTokenFailureT {
  return {
    type: SEND_BANK_TOKEN_FAILURE,
    error
  };
}

export function resetState() {
  return {
    type: RESET_STATE,
  }
}
