import React from "react";
import cx from "classnames";
import { ReactComponent as Calendar } from "../../assets/icons/Calendar.svg";
import styles from "./TextWithDate.module.scss";

type TextWithDateT = {
  title: string;
  date: string;
  className?: string;
  // titleClassName?: string;
};

export default function TextWithDate({
  title,
  date,
  className,
  // titleClassName,
}: TextWithDateT) {
  return (
    <div className={className}>
      <div className={styles.title}>{title}</div>
      <div className={cx(styles.dates, { [styles.hide]: !date })}>
        <Calendar />
        {date}
      </div>
    </div>
  );
}
