import React from "react";
import { Route, Link, RouteComponentProps } from "react-router-dom";
import { useSelector } from "react-redux";

import Title from "../../components/Title/Title";
import AuthorizationForm from "./AuthorizationForm";
import { AuthT } from "../../api/authorization";
import { AppState } from "../../store/modules";
import { StateT as AuthState, confirmUser, loginUser, forgotPassword, recoveryPassword } from "../../store/modules/authorization";

import Logo from "../../assets/images/logo.png";
import styles from "./AuthorizationPage.module.scss";
import cx from "classnames";

interface PropsT extends RouteComponentProps {
  title: string;
}

type ParamsT = {
  title?: string;
  subtitle?: string;
  navLink?: string;
  linkName?: string;
  buttonLabel?: string;
  handler?: any;
  initialValues: AuthT;
};

function getParams(path: string): ParamsT {
  switch (path) {
    case "/auth/confirm":
      return {
        title: "You’ve got the invite from Sprightful",
        subtitle: "Create your password first",
        buttonLabel: "Create an account",
        handler: confirmUser,
        initialValues: { password: "" },
      };
    case "/auth/login":
      return {
        title: "Welcome!",
        navLink: "/auth/forgot-password",
        linkName: "Forgot Password?",
        buttonLabel: "Log In",
        handler: loginUser,
        initialValues: { email: "", password: "" },
      };
    case "/auth/forgot-password":
      return {
        title: "Password recovery",
        navLink: "/auth/login",
        linkName: "Back to Log in",
        buttonLabel: "Get Reset Link",
        handler: forgotPassword,
        initialValues: { email: "" },
      };
    case "/auth/reset":
      return {
        title: "Create new password",
        navLink: "/auth/login",
        linkName: "Back to Log in",
        buttonLabel: "Update Password",
        handler: recoveryPassword,
        initialValues: { password: "", repeatPassword: "" },
      };
    default:
      return {
        title: "",
        buttonLabel: "",
        handler: () => {},
        initialValues: {},
      };
  }
}

const AuthorizationPage = (props: PropsT) => {
  const { pathname } = props.location;
  const { error } = useSelector<AppState, AuthState>((state) => state.authorization);

  const params = getParams(pathname);
  const render = () => <AuthorizationForm buttonLabel={params.buttonLabel} handler={params.handler} initialValues={params.initialValues} />;

  return (
    <div className={styles.container}>
      <div className={cx(styles.wrap, "wrap")}>
        <div className={cx(styles.content, !params.navLink ? styles.contentNavSpace : "")}>
          <div className={styles.logo}>
            <img src={Logo} alt="Sprightful logo" className={styles.logoImg} />
          </div>

          <header className={styles.header}>
            <Title>
              <h1 className={styles.title}>{params.title}</h1>
            </Title>

            {error && <div className={styles.error}>{error}</div>}

            {params.subtitle && <p className={styles.subtitle}>{params.subtitle}</p>}
          </header>

          <Route exact path="/auth/confirm" render={render} />
          <Route exact path="/auth/login" render={render} />
          <Route exact path="/auth/forgot-password" render={render} />
          <Route exact path="/auth/reset" render={render} />

          {params.navLink && (
            <footer>
              <Link to={params.navLink} className={styles.navLink}>
                {params.linkName}
              </Link>
            </footer>
          )}
        </div>
      </div>
    </div>
  );
};

export default AuthorizationPage;
