import styles from "./getArrowCodeAndClassName.module.scss";

const ARROW_UP = "\u2191";
const ARROW_DOWN = "\u2193";

export default function getArrowCodeAndClassName(number: number) {
  if (number === 0) {
    return { arrow: "", className: "", isNegative: false };
  }

  const isNegative = number < 0;
  const arrow = isNegative ? ARROW_DOWN : ARROW_UP;
  const className = isNegative ? styles.negative : styles.positive;

  return { arrow, className, isNegative };
}
