import axios from "./axiosConfig";

export type PaymentDetailsT = {
  name: string;
  card: string;
  expirationDate: string;
  cvv: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  sameAsBilling: boolean;
};

export interface BankAccountT {
  account_id: string;
  name: string;
  mask: string;
  type: string;
  subtype: string;
}

interface PaymentRequestT {
  token: string,
  account_id: string,
  account_name: string,
  account_number: string,
}

export function savePaymentDetails(data: PaymentDetailsT) {
  return axios.post("/payment-details", data);
}

export function sendBankToken(data: PaymentRequestT) {
  return axios.post("/me/payments/create", data);
}
