import React from "react";
import styles from "./RectangleNomination.module.scss";
import Nomination from "../Nomination/Nomination";

type PropsT = {
  name: string;
  number: number;
  isMoney?: boolean;
  suffix?: string;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

const RectangleNomination = (props: PropsT) => {
  const { name, number, Icon, isMoney = false, suffix } = props;

  return (
    <div className={styles.element}>
      <div className={styles.header}>
        <Icon className={styles.icon} />
      </div>
      <Nomination name={name} number={number} suffix={suffix} isMoney={isMoney} />
    </div>
  );
};

export default RectangleNomination;
