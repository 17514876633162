import React, { ReactNode } from "react";
import styles from "./ReactTooltip.module.scss";

function ReactTooltip({
  children,
  text,
  visible,
}: {
  children: ReactNode;
  text: string;
  visible: boolean;
}) {
  return (
    <div className={styles.tooltip}>
      {children}
      <span
        style={
          visible
            ? { visibility: "visible", opacity: 1 }
            : { visibility: "hidden", opacity: 0 }
        }
        className={styles.tooltipText}
      >
        {text}
      </span>
    </div>
  );
}

export default ReactTooltip;
