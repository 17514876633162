import React from "react";
import cn from "classnames";

import { computedColor } from "./PowerFlow";
import { ReactComponent as House } from "../../assets/icons/Home.svg";
import { ReactComponent as Solar } from "../../assets/icons/Sun.svg";
import { ReactComponent as Grid } from "../../assets/icons/Grid.svg";
import colors from "../../assets/style/variables/colors.scss";
import styles from "./PowerFlow.module.scss";

const computedBackgroundColor: Record<string, string> = {
  house: "#F3F7FB",
  solar: "#FFF6F2",
  grid: "#F2F3FF",
};

const computedIcon: Record<
  string,
  React.FunctionComponent<React.SVGProps<SVGSVGElement>>
> = {
  house: House,
  solar: Solar,
  grid: Grid,
};

type PropsT = {
  type: string;
  name: string;
  activeChart: string;
  selectChart: (name: string) => void;
};

const Icon = (props: PropsT) => {
  const { type, name, activeChart, selectChart } = props;
  const Icon = computedIcon[name];
  const color = colors[computedColor[name]];
  const backgroundColor = computedBackgroundColor[name];
  return (
    <button
      className={cn(styles.icons, activeChart === name && styles.activeIcon)}
      onClick={() => selectChart(type)}
      style={{ borderColor: color, backgroundColor }}
    >
      <Icon />
    </button>
  );
};

export default Icon;
