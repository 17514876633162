import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Formik,
  FormikProps,
  Form,
  FieldArray,
  FormikConfig,
  Field,
} from "formik";
import cx from "classnames";

import Button from "../Button/Button";
import InputField from "../InputField/InputField";
import ValidateMessage from "../ValidateMessage/ValidateMessage";
import SelectField from "../SelectField/SelectField";
import ModalHeader from "../Modal/ModalHeader";
import ModalContent from "../Modal/ModalContent";
import ModalFooter from "../Modal/ModalFooter";
import ValidateFormMessage from "../ValidateFormMessage/ValidateFormMessage";
import { AppState } from "../../store/modules";
import { InvitedUserT } from "../../api/users";
import {
  StateT as InfoState,
  getPowerProviders,
  getRates,
} from "../../store/modules/additionalInfo";
import { emailRegex, accountIdRegexp } from "../../constants";

import { ReactComponent as EnvelopeIcon } from "./../../assets/icons/Envelope.svg";
import { ReactComponent as PinIcon } from "./../../assets/icons/Pin.svg";
import { ReactComponent as BeeIcon } from "./../../assets/icons/Bee.svg";
import { ReactComponent as ResidenceIcon } from "./../../assets/icons/Residence.svg";
import colors from "./../../assets/style/variables/colors.scss";
import styles from "./UserEditModal.module.scss";

type PropsT = {
  closeModal: () => void;
  handleSubmit: (user: InvitedUserT) => void;
  isHidden: boolean;
  inviteError: null | string;
};

type UserFormT = {
  name: string;
  email: string;
  accountId: string,
  address: string;
  ecobeeId: string;
  neurioIds: string[];
  powerProvider: {
    value?: string;
  };
  rate: { value?: string };
  powerSupplierAccountId: string;
  panelSize: number | string;
};

const validate = (values: UserFormT) => {
  const errors = {} as Record<string, string>;

  if (!values.name) {
    errors.name = "Required field";
  }

  if (!values.email) {
    errors.email = "Required field";
  } else if (!emailRegex.test(values.email)) {
    errors.email = "That format doesn't look right.";
  }

  if (!values.accountId) {
    errors.accountId = "Required field";
  } else if (!accountIdRegexp.test(values.accountId)) {
    errors.accountId = "Invalid account ID format.";
  }

  if (!values.panelSize) {
    errors.panelSize = "Required field";
  }

  if (!values.address) {
    errors.address = "Required field";
  }

  if (values.neurioIds.length < 1 || values.neurioIds[0] === "") {
    errors.neurioIds = "Required field";
  }

  if (!values.powerProvider.value) {
    errors.powerProvider = "Required field";
  }

  if (!values.rate.value) {
    errors.rate = "Required field";
  }

  if (!values.powerSupplierAccountId) {
    errors.powerSupplierAccountId = "Required field";
  }

  return errors;
};

const ecoBeeIconStyle: React.CSSProperties = {
  fill: colors["green-light"],
  width: 14,
  height: 13,
};

const envelopeIconStyle: React.CSSProperties = {
  width: 14,
  height: 10,
};

const iconStyle: React.CSSProperties = { width: 14, height: 14 };

const pinIconStyle: React.CSSProperties = { width: 10, height: 13 };

const UserEditModal = (props: PropsT) => {
  const dispatch = useDispatch();
  const { closeModal, handleSubmit, isHidden, inviteError } = props;

  const { powerProviders, rates } = useSelector<AppState, InfoState>(
    (state) => state.additionalInfo
  );

  useEffect(() => {
    if (!powerProviders) {
      dispatch(getPowerProviders());
    }
  }, [powerProviders, dispatch]);

  const onSubmit = (values: UserFormT) => {
    const {
      name,
      email,
      accountId,
      address,
      ecobeeId,
      neurioIds,
      powerProvider,
      rate,
      powerSupplierAccountId,
      panelSize
    } = values;

    const data: InvitedUserT = {
      name,
      email,
      accountId,
      address,
      ecobeeId,
      neurioIds,
      isAdmin: false,
      powerSupplierId: powerProvider.value,
      supplierRateId: rate.value,
      powerSupplierAccountId,
      panelSize
    };

    handleSubmit(data);
  };

  const form: FormikConfig<UserFormT> = {
    initialValues: {
      name: "",
      email: "",
      accountId: "",
      panelSize: "",
      address: "",
      ecobeeId: "",
      neurioIds: [""],
      powerProvider: {},
      rate: {},
      powerSupplierAccountId: "",
    },
    onSubmit,
    validate,
  };

  const powerProvidersOptions = powerProviders
    ? powerProviders.map((provider) => ({
        label: provider.name,
        value: provider.id,
      }))
    : [];

  const ratesOptions = rates
    ? rates.map((rate: any) => ({
        label: rate.name,
        value: rate.id,
      }))
    : [];

  const changeProviderCallback = (id: string) => dispatch(getRates(id));

  return (
    <Formik {...form}>
      {(formik: FormikProps<UserFormT>): React.ReactNode => {
        const { values } = formik;

        return (
          <Form
            noValidate
            className={cx(styles.form, isHidden && styles.hidden)}
          >
            <ModalHeader>Send an invite</ModalHeader>

            <ModalContent>
              <Field
                component={InputField}
                variant="outline"
                name="name"
                label="User"
                placeholder="Enter user name"
                className={styles.formItem}
              />

              <Field
                component={InputField}
                variant="outline"
                name="email"
                type="email"
                label="Email"
                placeholder="User’s email"
                Icon={EnvelopeIcon}
                iconStyle={envelopeIconStyle}
                className={styles.formItem}
              />

              <Field
                component={InputField}
                variant="outline"
                name="accountId"
                type="string"
                label="Account ID"
                placeholder="Account ID"
                className={styles.formItem}
              />

              <Field
                component={InputField}
                variant="outline"
                name="panelSize"
                label="Your solar panel size"
                placeholder="Solar Panel Size, kW"
                className={styles.formItem}
              />

              <Field
                component={InputField}
                variant="outline"
                name="address"
                label="Address"
                placeholder="Client address"
                Icon={PinIcon}
                iconStyle={pinIconStyle}
                className={styles.formItem}
              />

              <Field
                component={InputField}
                variant="outline"
                name="ecobeeId"
                label="EcoBee"
                placeholder="Account ID"
                Icon={BeeIcon}
                iconStyle={ecoBeeIconStyle}
                className={styles.formItem}
              />

              <FieldArray
                name="neurioIds"
                render={(arrayFieldNeurio) => {
                  const removeField = () => {
                    arrayFieldNeurio.remove(values.neurioIds.length - 1);
                  };

                  const handleBlur = (index: number) => {
                    if (index > 0 && !values.neurioIds[index].length) {
                      arrayFieldNeurio.remove(index);
                    }
                  };

                  const addField = () => {
                    arrayFieldNeurio.push("");
                  };

                  return (
                    <>
                      {values.neurioIds.map((item, index) => (
                        <Field
                          component={InputField}
                          variant="outline"
                          key={index}
                          name={`neurioIds.${index}`}
                          label={index > 0 ? null : "Neurio"}
                          placeholder="Sensor ID"
                          Icon={ResidenceIcon}
                          className={cx(
                            index < values.neurioIds.length - 1 &&
                              styles.formItem
                          )}
                          hasValidationMessage={false}
                          iconStyle={iconStyle}
                          onBlur={() => (index > 0 ? handleBlur(index) : null)}
                        />
                      ))}

                      <ValidateMessage name="neurioIds" />

                      <div className={styles.inputArrayFooter}>
                        {values.neurioIds.length > 1 && (
                          <button
                            className={cx(
                              "link",
                              "error",
                              styles.inputArrayFooterLink
                            )}
                            type="button"
                            onClick={removeField}
                          >
                            Remove
                          </button>
                        )}

                        <button
                          className={cx(
                            "link",
                            "default",
                            styles.inputArrayFooterLink
                          )}
                          type="button"
                          onClick={addField}
                        >
                          +Add
                        </button>
                      </div>
                    </>
                  );
                }}
              />

              <hr className={styles.divider} />

              <Field
                component={SelectField}
                variant="outline"
                name="powerProvider"
                label="Power Provider"
                placeholder="Select your provider"
                options={powerProvidersOptions}
                className={styles.formItem}
                handleChangeCallback={changeProviderCallback}
              />

              <Field
                component={SelectField}
                variant="outline"
                name="rate"
                label="Rate"
                placeholder="Select your power rate"
                options={ratesOptions}
              />

              <Field
                component={InputField}
                variant="outline"
                name="powerSupplierAccountId"
                label="Power supplier account id"
                placeholder="Enter power supplier account id"
                className={styles.powerSupplierFormItem}
              />
            </ModalContent>

            <ModalFooter>
              <ValidateFormMessage
                error={inviteError}
                className={styles.error}
              />
              <Button
                variant="outlined"
                type="submit"
                className={styles.buttonPrimary}
              >
                Usage Data
              </Button>
              <Button onClick={closeModal} className={styles.buttonSecondary}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UserEditModal;
