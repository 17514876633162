import React from "react";
import styles from "./LinearLoader.module.scss";

type PropsT = {
  completed: number;
};

export default function LinearLoader(props: PropsT) {
  return (
    <div className={styles.container}>
      <div className={styles.filler} style={{ width: `${props.completed}%` }} />
    </div>
  );
}
