import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Field, Formik, FormikProps } from "formik";

import Button from "../../components/Button/Button";
import InputField from "../../components/InputField/InputField";
import { emailRegex } from "../../constants";
import { AppState } from "../../store/modules";
import { StateT as AuthState } from "../../store/modules/authorization";
import { AuthT } from "../../api/authorization";

import styles from "./AuthorizationPage.module.scss";

type PropsT = {
  buttonLabel?: string;
  handler?: (arg0: AuthT) => void;
  initialValues?: any;
};

const validate = (values: AuthT): AuthT => {
  const errors = {} as AuthT;

  if ("email" in values) {
    if (!values.email) {
      errors.email = "Required field";
    } else if (!emailRegex.test(values.email)) {
      errors.email = "That format doesn't look right.";
    }
  }

  if ("password" in values) {
    if (!values.password) {
      errors.password = "Required field";
    }
  }

  if ("repeatPassword" in values) {
    if (!values.repeatPassword) {
      errors.repeatPassword = "Required field";
    }

    if (values.password !== values.repeatPassword) {
      errors.repeatPassword = "Passwords don't match";
    }
  }

  return errors;
};

const AuthorizationForm = (props: PropsT) => {
  const { buttonLabel, handler, initialValues } = props;
  const dispatch = useDispatch<any>();
  const { loading } = useSelector<AppState, AuthState>((state) => state.authorization);

  async function submitForm(values: AuthT, { resetForm }: any) {
    handler && (await dispatch(handler(values)));
    resetForm();
  }

  return (
    <Formik initialValues={initialValues} onSubmit={submitForm} validate={validate} validateOnChange={false}>
      {(formik: FormikProps<AuthT>): React.ReactNode => {
        const { handleSubmit } = formik;

        return (
          <form noValidate onSubmit={handleSubmit} className={styles.formWrapper}>
            <div className={styles.inputs}>
              {"email" in initialValues && <Field component={InputField} type="email" name="email" placeholder="Email" />}

              {"password" in initialValues && <Field component={InputField} type="password" name="password" placeholder="Password" />}

              {"repeatPassword" in initialValues && <Field component={InputField} type="password" name="repeatPassword" placeholder="Repeat Password" />}
            </div>

            <Button variant="outlined" type="submit" className={styles.submitButton} disabled={loading}>
              {loading ? "loading..." : buttonLabel}
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};

export default AuthorizationForm;
