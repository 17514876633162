import axios from "./axiosConfig";
import { setTokenForRefresh } from "../helpers/authorization";

export type AuthT = {
  email?: string;
  password?: string;
  repeatPassword?: string;
};

export type UserConfirmT = {
  registrationId: string;
  password: string;
};

export type UserLoginT = {
  email: string;
  password: string;
};

export type RecoverPasswordT = {
  recoverId: string;
  password: string;
};

export type ForgetPasswordT = {
  email: string;
};

export type TokensT = {
  token: string;
  refreshToken: string;
};

export type ChangingPasswordT = {
  email: string;
  oldPassword: string;
  newPassword: string;
};

export function confirmRegistration(data: UserConfirmT) {
  return axios.post("/auth/confirm", data);
}

export function loginUser(data: UserLoginT) {
  const { email: login, password } = data;
  return axios.post("/auth/login", { login, password });
}

export function forgetPassword(data: ForgetPasswordT) {
  return axios.post("/auth/forget", data);
}

export function checkUser() {
  return axios.get("/me");
}

export function renewToken() {
  setTokenForRefresh();
  return axios.post("/auth/refresh");
}

export function recoveryPassword(data: RecoverPasswordT) {
  return axios.post("/auth/recover", data);
}

export function uploadPhoto(file: File) {
  return axios.post("/me/photo", file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function removePhoto() {
  return axios.delete("/me/photo");
}

export function changePassword(data: ChangingPasswordT) {
  return axios.post("/auth/password", data);
}
