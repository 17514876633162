import React from "react";
import cx from "classnames";
import styles from "./EditFPLRates.module.scss";
import ModalHeader from "../../components/Modal/ModalHeader";
import ModalContent from "../../components/Modal/ModalContent";
import { formatMonthDayYear } from "../../helpers/formatDate";
import { useDispatch, useSelector } from "react-redux";
import { showRatesHistory } from "../../store/modules/additionalInfo";
import { AppState } from "../../store/modules";
import { StateT as InfoState } from "../../store/modules/additionalInfo";

type PropsT = {
  setShowListOfUpdates: (value: boolean) => void;
};

function UpdatesHistory(props: PropsT) {
  const { setShowListOfUpdates } = props;
  const dispatch = useDispatch();
  const { updatedRates } = useSelector<AppState, InfoState>(
    (state) => state.additionalInfo
  ); 

  function backToForm() {
    dispatch(showRatesHistory(false));
    setShowListOfUpdates(false);
  }

  return (
    <>
      <ModalHeader>Rates Update History</ModalHeader>
      <ModalContent>
        {updatedRates?.length ? (
          <table
            className={styles.table}
            // @ts-ignore
            border="1"
            cellSpacing="0"
            cellPadding="0"
          >
            <thead>
              <tr>
                <th rowSpan={2}>Valid From</th>
                <th rowSpan={2}>Valid To</th>
                <th rowSpan={2}>Boundary</th>
                <th colSpan={2}>Basic</th>
                <th colSpan={2}>Over Boundary</th>
                <th colSpan={4}>Variable</th>
                <th>Fixed</th>
                {updatedRates[0].peak && <th colSpan={4}>Peaks</th>}
              </tr>
              <tr>
                <th>Fuel Charge</th>
                <th>None Fuel Charge</th>
                <th>Fuel Charge</th>
                <th>None Fuel Charge</th>
                <th>Conservation</th>
                <th>Storm</th>
                <th>Environmental</th>
                <th>Capacity</th>
                <th>Customer</th>
                {updatedRates[0].peak && (
                  <>
                    <th colSpan={2}>On peak</th>
                    <th colSpan={2}>Off peak</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {updatedRates?.map((value: any) => {
                return (
                  <tr key={value.validFrom}>
                    <td>{formatMonthDayYear(value.validFrom)}</td>
                    <td>{formatMonthDayYear(value.validTo)}</td>
                    <td>{value.boundary}</td>
                    <td>{value.basic.fuelCharge}</td>
                    <td>{value.basic.nonFuelCharge}</td>
                    <td>{value.overBoundary.fuelCharge}</td>
                    <td>{value.overBoundary.nonFuelCharge}</td>
                    <td>{value.variable.conservation}</td>
                    <td>{value.variable.storm}</td>
                    <td>{value.variable.environmental}</td>
                    <td>{value.variable.capacity}</td>
                    <td>{value.fixed.customer}</td>
                    {value.peak && (
                      <>
                        <td>{value.peak.onPeak.fuelCharge}</td>
                        <td>{value.peak.onPeak.nonFuelCharge}</td>
                        <td>{value.peak.offPeak.fuelCharge}</td>
                        <td>{value.peak.offPeak.nonFuelCharge}</td>
                      </>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : updatedRates?.length === 0 ? (
          <p style={{ fontSize: "24px" }}>No information to show</p>
        ) : (
          <p style={{ fontSize: "22px", textAlign: 'center' }}>Loading...</p>
        )}
      </ModalContent>
      <div style={{ textAlign: "right", margin: "32px" }}>
        <button
          className={cx("link", "default")}
          type="button"
          onClick={backToForm}
        >
          Go Back
        </button>
      </div>
    </>
  );
}

export default UpdatesHistory;
