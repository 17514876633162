import React, { ReactNode } from "react";
import styles from "./Title.module.scss";

type PropsT = {
  children: ReactNode;
};

const Title = (props: PropsT) => {
  const { children } = props;
  return <div className={styles.wrapper}>{children}</div>;
};

export default Title;
