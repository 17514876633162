// @ts-nocheck
import React, { Component } from "react";
import Canvg, { presets } from "canvg";
import {
  Page,
  Text,
  Document,
  BlobProvider,
  Image,
  View,
  Font,
} from "@react-pdf/renderer";
import { connect } from "react-redux";
import CircleChart from "../../components/CircleChart/CircleChartContainer";
import { formatMonthDayYear } from "../../helpers/formatDate";
import {
  formatMoney,
  formatEnergy,
  formatPercent,
} from "../../helpers/formatValues";

import logo from "../../assets/images/logo2.png";
import Calendar from "../../assets/images/calendar.png";
import Tree from "../../assets/images/tree.png";
import Sun from "../../assets/images/sun.png";
import styles from "./styles";
import avenirDemi from "../../assets/fonts/avenirnextcyr-demi.ttf";
import avenirBold from "../../assets/fonts/AvenirNextCyr-Bold.ttf";
import avenirLight from "../../assets/fonts/AvenirNextCyr-Light.ttf";
import "./offscreen-polyfill";

Font.register({
  family: "Avenir Light",
  src: avenirLight,
});
Font.register({
  family: "Avenir Demi",
  src: avenirDemi,
});
Font.register({
  family: "Avenir Bold",
  src: avenirBold,
});

type StateT = {
  generate: boolean;
  render: boolean;
};

type PropsT = {
  data?: any;
  children: React.ReactElement;
  currentRate: any;
  isFlat: null | boolean;
};

class BillPDFGenerator extends Component<PropsT, StateT> {
  state = {
    generate: false,
    render: false,
  };

  link: HTMLAnchorElement | null = null;

  svgs: { [$svgName: string]: any | null } = {
    circleChart: null,
  };

  chartDimensions = {
    circleChart: { width: 3180, height: 1990 },
  };

  shouldComponentUpdate(nextProps: {}, { generate, render }: StateT) {
    // Update only when generate or render becomes true
    return (
      !!(!this.state.generate && generate) || !!(!this.state.render && render)
    );
  }

  pdfGenerated = ({ url }: any) => {
    if (url) {
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.download = "Sprightful_bill.pdf";
      link.click();
      this.link = link;
    }

    return null;
  };

  generatePngFromSvg = async (
    svg: string,
    dimensions: { width: number; height: number }
  ) => {
    const canvas = new OffscreenCanvas(dimensions.width, dimensions.height);
    const ctx = canvas.getContext("2d");

    // @ts-ignore
    const v = await Canvg.from(ctx, svg, presets.offscreen());

    await v.render();
    const blob = await canvas.convertToBlob({ quality: 1, type: "image/png" });
    return URL.createObjectURL(blob);
  };

  prepare = async () => {
    if (this.link) {
      this.link.click();
      return;
    }

    if (this.state.generate) {
      return;
    }

    this.setState({ generate: true });
  };

  addToCache = (name: "circleChart") => async (ref: any) => {
    if (this.svgs[name]) {
      return;
    }

    if (!ref?.outerHTML) {
      return;
    }

    this.svgs[name] = await this.generatePngFromSvg(
      ref.outerHTML,
      this.chartDimensions[name]
    );
  };

  awaitAllChartsLoading = () => {
    return new Promise<void>((resolve) => {
      const interval = setInterval(() => {
        if (Object.keys(this.svgs).some((name) => !this.svgs[name])) {
          return;
        }

        clearInterval(interval);
        resolve();
      }, 100);
    });
  };

  renderCharts = () => {
    if (!this.state.generate || this.state.render) {
      return null;
    }

    this.awaitAllChartsLoading().then(() => {
      this.setState({ render: true });
    });

    const {
      data: {
        onPeakConsumption,
        offPeakConsumption,
        totalGeneration,
        predictedGeneration,
        predictedOffPeak,
        predictedOnPeak,
        savingsAmount,
        sprightfulSavings,
        actualCharge,
      },
    } = this.props;

    return (
      <div style={{ width: 0, height: 0, opacity: 0, pointerEvents: "none" }}>
        <CircleChart
          onPeakConsumption={onPeakConsumption}
          offPeakConsumption={offPeakConsumption}
          totalGeneration={totalGeneration}
          predictedGeneration={predictedGeneration}
          predictedOffPeak={predictedOffPeak}
          predictedOnPeak={predictedOnPeak}
          svgProps={{ height: this.chartDimensions.circleChart.height }}
          svgRef={this.addToCache("circleChart")}
          savingsAmount={savingsAmount}
          sprightfulSavings={sprightfulSavings}
          actualCharge={actualCharge}
          animation={false}
          withPieChart
        />
      </div>
    );
  };

  getDates(dates: Array<string>) {
    const newDates = dates.map((el) => {
      const date = new Date(Date.parse(el));
      return `${formatMonthDayYear(date)}`;
    });

    return (
      <Text style={styles.textTo}>
        {newDates[0]} to {newDates[1]}
      </Text>
    );
  }

  renderPDF() {
    if (!this.state.render) {
      return null;
    }

    const {
      startDate,
      endDate,
      actualCharge,
      totalSavings,
      sprightfulSavings,
      savingsAmount,
      totalAmount,
      totalGeneration,
      offPeakGeneration,
      offPeakConsumption,
      onPeakGeneration,
      onPeakConsumption,
      totalConsumption,
      accountName,
      accountId,
      accountAddress,
      savingsShare,
      preventedUtilityBill = 0,
      additionalCharge,
    } = this.props.data;

    const offset = Math.round((totalSavings / 0.1142) * 0.000707 * 2240);
    const trees = Math.round((offset / 2240) * 16.5);

    const MyDoc = (
      <Document>
        <Page size="A4" style={styles.body} wrap={false}>
          <View style={styles.headerContainer}>
            <View style={styles.logoBlock}>
              <View style={styles.logoContainer}>
                <Image style={styles.logo} src={logo} />
                <Text style={styles.sprightful}>Sprightful</Text>
              </View>

              <View style={styles.logoTextContainer}>
                <Text style={styles.logoText}>(352) 888-6042</Text>

                <Text style={styles.logoText}>support@sprightful.com</Text>
              </View>
            </View>

            <View style={styles.noteContainer}>
              <View style={styles.row}>
                <Image style={styles.calendar} src={Calendar} />

                {this.getDates([startDate, endDate])}
              </View>

              <View style={styles.row}>
                <Text style={[styles.textTo, styles.name]}>{accountName}</Text>

                <Text style={styles.textTo}>&nbsp;({accountId})</Text>
              </View>

              <Text style={styles.textTo}>{accountAddress}</Text>
            </View>
          </View>

          <View style={styles.section}>
            <View style={[styles.row, styles.titleRow]}>
              <Text style={[styles.markerTitle, styles.monthSavings]}>
                THIS MONTH YOU SAVED <Text>{formatMoney(savingsAmount)} ️</Text>
              </Text>
              <Image style={styles.sunLogo} src={Sun} />
              <Text style={[styles.markerTitle, styles.monthSavings]}>
                &nbsp;AND OFFSET <Text>{offset}</Text> lbsCO&#8322; EQUIVALENT
                TO PLANTING <Text>{trees}</Text>&nbsp;
              </Text>
              <Image style={styles.sunLogo} src={Tree} />
            </View>
            <Text style={styles.sectionTitle}>
              How is your SSP bill calculated?
            </Text>
            <View style={[styles.rowBetween, styles.oddRow]}>
              <Text style={styles.sectionText}>
                Before going solar, the FPL bill would have looked like this:
              </Text>

              <Text style={styles.sectionText}>
                {formatMoney(preventedUtilityBill)}
              </Text>
            </View>
            <View style={styles.rowBetween}>
              <Text style={styles.sectionText}>
                But it actually looks like this:
              </Text>

              <Text style={styles.sectionText}>
                {formatMoney(actualCharge)}
              </Text>
            </View>
            <View style={[styles.rowBetween, styles.oddRow]}>
              <Text style={styles.sectionText}>
                Meaning the solar system generated this amount of total savings:
              </Text>

              <Text style={styles.sectionText}>
                {formatMoney(totalSavings)}
              </Text>
            </View>
            <View style={styles.rowBetween}>
              <Text style={styles.sectionText}>Now, let’s share them!</Text>
            </View>
            <View style={[styles.rowBetween, styles.oddRow]}>
              <Text style={[styles.sectionText, styles.indentedText]}>
                Your share
              </Text>

              <Text style={styles.sectionText}>
                {formatPercent(100 * savingsShare)} ={" "}
                {formatMoney(savingsAmount)}
              </Text>
            </View>
            <View style={styles.rowBetween}>
              <Text style={[styles.sectionText, styles.indentedText]}>
                Sprightful’s share
              </Text>

              <Text style={styles.sectionText}>
                {formatPercent(100 * (1 - savingsShare))} ={" "}
                {formatMoney(sprightfulSavings)}
              </Text>
            </View>
            <Text style={styles.sectionTitle}>
              Wrapping up your charges for this month
            </Text>
            <View style={[styles.rowBetween, styles.oddRow]}>
              <Text style={styles.sectionText}>FPL bill </Text>

              <Text style={styles.sectionText}>
                {formatMoney(actualCharge)}
              </Text>
            </View>
            <View style={styles.rowBetween}>
              <Text style={styles.sectionText}>
                Additional services you are subscribed to with FPL
              </Text>

              <Text style={styles.sectionText}>
                {formatMoney(additionalCharge)}
              </Text>
            </View>
            <View style={[styles.rowBetween, styles.oddRow]}>
              <Text style={styles.sectionText}>Sprightful’s share </Text>

              <Text style={styles.sectionText}>
                {formatMoney(sprightfulSavings)}
              </Text>
            </View>
            <View style={styles.rowBetween}>
              <Text style={[styles.sectionText, styles.summaryText]}>
                Total SSP bill
              </Text>

              <Text style={[styles.sectionText, styles.summaryText]}>
                {formatMoney(totalAmount)}
              </Text>
            </View>
            <View>
              <Text style={[styles.sectionText, styles.subHeaderText]}>
                *charges on your FPL account will be paid by Sprightful.
              </Text>
            </View>
          </View>

          <Text style={styles.sectionTitle}>Energy Details</Text>

          <View style={styles.rowBetween}>
            <Text style={styles.sectionText}>
              Total home's energy consumption
            </Text>

            <Text style={styles.sectionText}>
              {formatEnergy(totalConsumption)}
            </Text>
          </View>

          {!this.props.currentRate?.flat && !this.props.isFlat && (
            <View style={styles.divider} />
          )}

          {!this.props.currentRate?.flat && !this.props.isFlat && (
            <>
              <View style={styles.rowBetween}>
                <Text style={styles.indentedText}>On Peak</Text>

                <Text style={styles.sectionText}>
                  {formatEnergy(onPeakConsumption)}
                </Text>
              </View>

              <View style={styles.rowBetween}>
                <Text style={styles.indentedText}>Off Peak</Text>

                <Text style={styles.sectionText}>
                  {formatEnergy(offPeakConsumption)}
                </Text>
              </View>
            </>
          )}

          <View style={styles.rowBetween}>
            <Text style={styles.sectionText}>
              Of which covered by solar energy
            </Text>

            <Text style={styles.sectionText}>
              {formatEnergy(totalGeneration)}
            </Text>
          </View>

          {!this.props.currentRate?.flat && !this.props.isFlat && (
            <View style={styles.divider} />
          )}

          {!this.props.currentRate?.flat && !this.props.isFlat && (
            <>
              <View style={styles.rowBetween}>
                <Text style={styles.indentedText}>On Peak</Text>

                <Text style={styles.sectionText}>
                  {formatEnergy(onPeakGeneration)}
                </Text>
              </View>

              <View style={styles.rowBetween}>
                <Text style={styles.indentedText}>Off Peak</Text>

                <Text style={styles.sectionText}>
                  {formatEnergy(offPeakGeneration)}
                </Text>
              </View>
            </>
          )}

          <View style={{...styles.chartsContainer, ...!this.props.currentRate?.flat && !this.props.isFlat && styles.marginTop}}>
            <View />
            <Image style={styles.canvas} src={this.svgs.circleChart} />
          </View>
        </Page>
      </Document>
    );

    return <BlobProvider document={MyDoc}>{this.pdfGenerated}</BlobProvider>;
  }

  render() {
    return (
      <>
        <button onClick={this.prepare}>{this.props.children}</button>

        {this.renderCharts()}

        {this.renderPDF()}
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    currentRate: state.authorization.currentRate,
    isFlat: state.users.isFlat,
  };
};

export default connect(mapStateToProps)(BillPDFGenerator);
