import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import UserEditModal from "../UserEditModal/UserEditModal";
import BaselineForm from "../BaselineForm/BaselineForm";
import { DEFAULT_MODAL_WIDTH } from "../../containers/UsersListPage/UsersListPage";
import {
  createUser,
  StateT as InviteUsersState,
} from "../../store/modules/inviteUser";
import { AppState } from "../../store/modules";
import { InvitedUserT, BaselineT } from "../../api/users";
import styles from "./InviteUser.module.scss";

type PropsT = {
  closeModal: () => void;
  setInviteUserModalWidth: (width: number | string) => void;
};

const InviteUser = (props: PropsT) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [userData, setUserData] = useState<InvitedUserT>();
  const [baselineData, setBaselineData] = useState<BaselineT>();
  const { inviteError, sendError, inviting, invited } = useSelector<
    AppState,
    InviteUsersState
  >((state) => state.inviteUser);

  useEffect(() => {
    if (inviteError) {
      setStep(1);
    }
    if (sendError) {
      setStep(2);
    }
  }, [inviteError, sendError]);

  function saveUserForm(user: InvitedUserT) {
    setStep(2);
    setUserData(user);
  }

  function goBack() {
    props.setInviteUserModalWidth(DEFAULT_MODAL_WIDTH);
    setStep(1);
  }

  function submit() {
    if (userData) {
      dispatch(createUser({ userData, data: baselineData }));
    }
  }

  return (
    <>
      {inviting}
      <div className={styles.stepper}>{`Step ${step}/2`}</div>
      <UserEditModal
        closeModal={props.closeModal}
        handleSubmit={saveUserForm}
        isHidden={step === 2}
        inviteError={inviteError}
      />
      <BaselineForm
        closeModal={props.closeModal}
        goBack={goBack}
        isHidden={step === 1}
        onSubmit={submit}
        inviting={inviting}
        invited={invited}
        setBaselineData={setBaselineData}
      />
    </>
  );
};

export default InviteUser;
