import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";

import DesktopChart from "../DesktopChart/DesktopChart";
import { AppState } from "../../store/modules";
import {
  StateT as UsersState,
  getUserEnergyData,
} from "../../store/modules/users";
import { StateT as DashboardState } from "../../store/modules/dashboard";
import StatisticsView from "../StatisticsView/StatisticsView";
import Loading from "../Loading/Loading";
import DesktopWeeklyChart from "../DesktopWeeklyChart/DesktopWeeklyChart";
import DesktopMonthlyChart from "../DesktopMonthlyChart/DesktopMonthlyChart";
import { selectFieldStyle } from "../DashboardTitle/SelectFieldStype";
import ModalFooter from "../Modal/ModalFooter";
import Button from "../Button/Button";
import { timeOptions } from "../../constants";

import styles from "./UserEnergyModal.module.scss";

type PropsT = {
  closeModal: () => void;
};

export default function UserEnergyModal({ closeModal }: PropsT) {
  const dispatch = useDispatch();
  const { energy } = useSelector<AppState, UsersState>((state) => state.users);
  const { period } = useSelector<AppState, DashboardState>(
    (state) => state.dashboard
  );
  const selectedValue = timeOptions.find((item) => item.value === period);

  const onChange = (option: any) => {
    dispatch(getUserEnergyData(option.value));
  };

  function renderChart() {
    switch (period) {
      case "today":
      case "yesterday":
        return energy?.date ? (
          <DesktopChart energy={energy} forAdmin />
        ) : (
          <div />
        );
      case "week":
        return energy?.label === "This week" ? (
          <DesktopWeeklyChart energy={energy} forAdmin />
        ) : (
          <div />
        );
      case "month":
        return energy?.label === "This month" ? (
          <DesktopMonthlyChart energy={energy} forAdmin />
        ) : (
          <div />
        );
      default:
        return <div />;
    }
  }

  return (
    <>
      <div className={styles.modalHeader}>
        <div className={styles.smallTitle}>Arlene Lane</div>
        <div className={styles.title}>
          Energy Usage for
          <Select
            name="name"
            options={timeOptions}
            onChange={onChange}
            value={selectedValue}
            styles={selectFieldStyle}
            isSearchable={false}
          />
        </div>
      </div>
      {energy ? (
        <>
          <StatisticsView
            savings={energy.totalSavings}
            generation={energy.totalGeneration}
            onPeakConsumption={
              energy.totalOnPeak || energy.totalOnPeakConsumption
            }
            offPeakConsumption={
              energy.totalOffPeak || energy.totalOffPeakConsumption
            }
            className={styles.statisticsWrapper}
          />
          {renderChart()}
        </>
      ) : (
        <Loading />
      )}

      <ModalFooter>
        <Button
          variant="outlined"
          className={styles.closeButton}
          onClick={closeModal}
        >
          Close
        </Button>
      </ModalFooter>
    </>
  );
}
