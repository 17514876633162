import React, { ReactNode, useMemo } from "react";
import Modal from "react-modal";
import cx from "classnames";
import ScrollLock from "react-scrolllock";
import { ReactComponent as CloseIcon } from "../../assets/icons/Close.svg";
import styles from "./Modal.module.scss";

type PropsT = {
  isOpen: boolean;
  closeModal: () => void;
  children: ReactNode;
  style?: Record<string, string>;
  contentClassName?: string;
  showCloseButton?: boolean;
};

const modalStyles = (style: Record<string, string>) => ({
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: 0,
    transform: "translate(-50%, -50%)",
    ...style,
  },
  overlay: {
    backgroundColor: "rgba(15, 31, 72, 0.5)",
    zIndex: 9999,
  },
});

export const CustomModal = (props: PropsT) => {
  const { isOpen, closeModal, children, style } = props;
  const modalStyle = useMemo(() => style || ({}), [style]);
  const customStyles = modalStyles(modalStyle);

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Modal" ariaHideApp={false}>
      <ScrollLock>
        <div className={cx(styles.content, props.contentClassName)}>
          <div className={styles.wrapper}>
            {props.showCloseButton && (
              <button onClick={closeModal} className={cx(styles.closeButton, "clickExpand")}>
                <CloseIcon className={styles.closeIcon} />
              </button>
            )}

            {children}
          </div>
        </div>
      </ScrollLock>
    </Modal>
  );
};

CustomModal.defaultProps = {
  showCloseButton: true,
  style: {},
};

export default CustomModal;
