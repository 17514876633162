import React, { SyntheticEvent } from "react";
import { Link, Element } from "react-scroll";
import ReactMarkdown from "react-markdown";
import { isMobileOnly } from "react-device-detect";
import cx from "classnames";

import WhiteBlock from "../../components/WhiteBlock/WhiteBlock";
import Button from "../../components/Button/Button";
import questions from "../../assets/markdowns/faq";
import styles from "./FAQ.module.scss";

function stopPropagation(event: SyntheticEvent) {
  event.stopPropagation();
}

const FAQ = () => {
  return (
    <div className={styles.pageWrapper}>
      <WhiteBlock className={styles.questions}>
        <div className={styles.questionsBlock}>
          {questions.map((question, index) => (
            <Link
              key={`${index}-${question.title}`}
              className={styles.questionsLink}
              activeClass={styles.activeQuestion}
              to={`${index}-${question.title}`}
              spy={true}
              smooth={true}
              duration={500}
              offset={isMobileOnly ? 0 : -110}
            >
              <span className={styles.questionNumber}>{index + 1}.</span>
              <span>{question.title}</span>
            </Link>
          ))}
        </div>

        <div className={styles.questionsBottomBlock}>
          <h6>Still have any question?</h6>
          <a href="mailto:test@sprightful.com">
            <Button variant="outlined" onClick={stopPropagation}>
              Contact Us
            </Button>
          </a>
        </div>
      </WhiteBlock>

      <WhiteBlock className={styles.answers}>
        {questions.map((question, index) => (
          <Element
            key={`answer-${index}-${question.title}`}
            name={`${index}-${question.title}`}
            className={styles.element}
          >
            <ReactMarkdown children={question.description} />
          </Element>
        ))}

        <div className={cx(styles.element, styles.answersBottomBlock)}>
          <h6>I'm interested in solar, what's next?</h6>
          <p>
            Give us a call or fill out this 1 minute questionnaire. We will
            contact you within 24 hours for a quick consultation.
          </p>
        </div>
      </WhiteBlock>
    </div>
  );
};

export default FAQ;
