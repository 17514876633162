import axios from "./axiosConfig";

export type UpcomingDataT = {
  currentSavings: number;
  projectedSavings: number;
  currentSavingsShare: number;
  projectedSavingsShare: number;
  currentCharge: number;
  projectedCharge: number;
  currentPeriod: string;
  projectedPeriod: string;
  projectedMonthlyComparison: number;
  projectedMonthlyComparisonPercentage: number;
};

export type UpcomingBillsT = {
  actualCharge: number;
  baselineCharge: number;
  baselineConsumption: number;
  baselineExceeded: boolean;
  endDate: Date;
  id: string;
  offPeakConsumption: number;
  onPeakConsumption: number;
  onPeakRatio: number;
  paid: boolean;
  predictedGeneration: number;
  predictedOffPeak: number;
  predictedOnPeak: number;
  savingsAmount: number;
  savingsShare: number;
  startDate: Date;
  totalAmount: number;
  totalConsumption: number;
  totalGeneration: number;
  totalSavings: number;
  upcoming: UpcomingDataT;
};

export type ClosedBillT = {
  actualCharge: number;
  baselineCharge: number;
  baselineConsumption: number;
  baselineExceeded: boolean;
  endDate: Date;
  offPeakConsumption: number;
  onPeakConsumption: number;
  onPeakRatio: number;
  paid: false;
  predictedGeneration: number;
  predictedOffPeak: number;
  predictedOnPeak: number;
  savingsAmount: number;
  savingsShare: number;
  startDate: Date;
  totalAmount: number;
  totalConsumption: number;
  totalGeneration: number;
  totalSavings: number;
};

export type CompareT = {
  change: number;
  currentValue: number;
  previousValue: number;
};

export type ComparativeBillT = {
  previousYear: string;
  currentYear: string;
  onPeak: CompareT;
  offPeak: CompareT;
  savings: CompareT;
  generation: CompareT;
};

export function getUpcomingBills(): Promise<{ data: UpcomingBillsT }> {
  return axios.get("/me/bills/upcoming");
}

export function getClosedBills(): Promise<{ data: Array<ClosedBillT> }> {
  return axios.get("/me/bills");
}

export function getComparativeBill(): Promise<{ data: ComparativeBillT }> {
  return axios.get("/me/bills/compare");
}

export function payBill(billId: string) {
  return axios.post(`/me/bills/${billId}/pay`);
}

// Endpoints for admin
export function getClosedBillsById(
  homeId: string
): Promise<{ data: Array<ClosedBillT> }> {
  return axios.get(`/admin/homes/${homeId}/bills`);
}

export function getUpcomingBillsByHomeId(
  homeId: string
): Promise<{ data: UpcomingBillsT }> {
  return axios.get(`/admin/homes/${homeId}/bills/upcoming`);
}

export function getComparativeBillById(
  homeId: string
): Promise<{ data: ComparativeBillT }> {
  return axios.get(`/admin/homes/${homeId}/bills/compare`);
}
