import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";

import store from "./store";

import "./index.scss";

import App from "./containers/App/App";

const rootElement = document.getElementById("root") as HTMLElement;
const root = createRoot(rootElement);

root.render(
    <Provider store={store}>
        <App />
    </Provider>,
);

serviceWorker.register();
