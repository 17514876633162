import React, { useState, useEffect, useRef } from "react";
import AvatarEditor from 'react-avatar-editor'
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { isMobileOnly } from "react-device-detect";
import heic2any from "heic2any";

import ModalHeader from "../Modal/ModalHeader";
import Button from "../Button/Button";
import ModalFooter from "../Modal/ModalFooter";
import Loading from "../Loading/Loading";
import {
  StateT as AuthState,
  uploadPhoto,
  removePhoto,
} from "../../store/modules/authorization";
import { AppState } from "../../store/modules";
import ValidateFormMessage from "../ValidateFormMessage/ValidateFormMessage";
import { apiURL } from "../../api/axiosConfig";
import { ReactComponent as RefreshIcon } from "../../assets/icons/Refresh.svg";
import { ReactComponent as RemoveIcon } from "../../assets/icons/Trashcan.svg";
import { ReactComponent as Chevron } from "../../assets/icons/Chevron-left.svg";
import styles from "./UploadPhoto.module.scss";

const url = apiURL();

type PropsT = {
  closeModal: () => void;
  photoLink: string | null;
};

async function convertIfRequired(file: File) {
  if (file.name.endsWith('.heic')) {
    try {
      return heic2any({ blob: file, toType: 'image/png', });
    } catch (err) {
      console.log(err);
      // Failed to convert
    }
  }

  return file;
}

const UploadPhoto = (props: PropsT) => {
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState<any>();
  const avatarEditor = useRef(null);
  const [rotate, setRotation] = useState<number>(0);

  const { closeModal, photoLink } = props;
  const [preview, setPreview] = useState<string | null>(null);

  const { error, addedPhoto, deletedPhoto, loading } = useSelector<
    AppState,
    AuthState
  >((state) => state.authorization);

  useEffect(() => {
    let objectUrl = "";
    if (selectedFile) {
      objectUrl = URL.createObjectURL(selectedFile);
      setPreview(objectUrl);
    }

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  useEffect(() => {
    if (addedPhoto) {
      closeModal();
    }
  }, [addedPhoto, closeModal]);

  useEffect(() => {
    if (deletedPhoto) {
      setPreview(null);
    }
  }, [deletedPhoto]);

  async function onDrop(acceptedFiles: Array<File>) {
    const file = await convertIfRequired(acceptedFiles[0]);
    setSelectedFile(file);
  }

  function savePhoto() {
    if (selectedFile && avatarEditor.current) {
      const formData = new FormData();
      // @ts-ignore
      avatarEditor.current.getImageScaledToCanvas().toBlob((blob) => {
        formData.append("avatar", blob);
        dispatch(uploadPhoto(formData));
      });
    }
  }

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const { onClick: selectFile, ...dragNDropProps } = getRootProps();

  function rotateRight() {
    setRotation((rotate + 90) % 360)
  }

  function deletePhoto() {
    if (photoLink) {
      dispatch(removePhoto());
    }
    if (selectedFile) {
      setPreview(null);
    }
  }

  const userPhoto = photoLink
    ? {
      background: `radial-gradient(transparent 70.6%, rgba(57, 57, 57, 0.5) 0), url(${url}${photoLink}) center/cover no-repeat`,
    }
    : {};

  if (loading) return <Loading />;

  return (
    <div>
      <ModalHeader className={styles.modalHeaderContainer}>
        {isMobileOnly && (
          <Chevron className={styles.chevronIcon} />
        )}
        <span className={styles.title}>Upload New Photo</span>
      </ModalHeader>

      <div {...dragNDropProps} className={styles.dndBlock}>
        {(selectedFile && preview) ? (
          <div className={styles.editorContainer}>
            <AvatarEditor
              ref={avatarEditor}
              width={280}
              height={280}
              image={preview}
              scale={1.1}
              borderRadius={140}
              rotate={rotate}
              style={{ width: '280px', height: '280px' }}
            />

            <button className={styles.rotateButton} onClick={rotateRight}>
              <RefreshIcon  />
            </button>
          </div>
        ) : (
          <div
            className={styles.photoFiller}
            onClick={selectFile}
            style={userPhoto}
          />
        )}

        <input {...getInputProps()} multiple={false} />

        <div className={styles.dndLabel}>
          <Button className={styles.button} onClick={selectFile}>
            Click Here
          </Button>
          &nbsp;to Upload New Photo,
          <br />
          or just Drag and Drop it.
        </div>
      </div>

      <div className={styles.footer}>
        <ValidateFormMessage
          error={error}
          className={styles.error}
        />
        <ModalFooter>
          <Button
            variant="outlined"
            className={styles.buttonPrimary}
            onClick={savePhoto}
          >
            Save
          </Button>
          <Button onClick={closeModal} className={styles.buttonSecondary}>
            Cancel
          </Button>

          <Button className={styles.removeButton} onClick={deletePhoto}>
            <RemoveIcon /> Remove
          </Button>
        </ModalFooter>
      </div>
    </div>
  );
};

export default UploadPhoto;
