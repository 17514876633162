export const SHOW_ALERT = "SHOW_ALERT";
export const HIDE_ALERT = "HIDE_ALERT";

export type ShowAlertT = {
  type: typeof SHOW_ALERT;
  message: string;
};

type HideAlertT = {
  type: typeof HIDE_ALERT;
};

export type ActionTypeT = ShowAlertT | HideAlertT;

export type StateT = {
  showAlert: boolean;
  message: string | undefined;
};

const initialState: StateT = {
  showAlert: false,
  message: undefined,
};

export default function statisticsReducer(
  state: StateT = initialState,
  action: ActionTypeT
): StateT {
  switch (action.type) {
    case SHOW_ALERT:
      return { ...state, showAlert: true, message: action.message };
    case HIDE_ALERT:
      return { ...state, showAlert: false, message: undefined };

    default:
      return state;
  }
}

export function hideAlert() {
  return {
    type: HIDE_ALERT,
  };
}
