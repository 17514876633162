import React, { ReactNode } from "react";
import cx from "classnames";
import styles from "./WhiteBlock.module.scss";

type PropsT = {
  children: ReactNode;
  className?: any;
};

const WhiteBlock = (props: PropsT) => <div className={cx(styles.wrapper, props.className)}>{props.children}</div>;

export default WhiteBlock;
