import React, { useCallback } from "react";
import cx from "classnames";
import { useHistory } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import WhiteBlock from "../WhiteBlock/WhiteBlock";
import UpcomingBill from "../UpcomingBill/UpcomingBill";
import DesktopNomination from "../DesktopNomination/DesktopNomination";
import PayNowButton from "../PayNowButton/PayNowButton";
import Button from "../Button/Button";
import RectangleNomination from "../RectangleNomination/RectangleNomination";
import PowerFlow from "../PowerFlow/PowerFlow";
import DesktopLifetimeSavingsBlock from "../DesktopLifetimeSavingsBlock/DesktopLifetimeSavingsBlock";
import NumericValueFormat from "../NumericValueFormat/NumericValueFormat";
import { AppState } from "../../store/modules";
import { StateT as AuthState } from "../../store/modules/authorization";
import { UpcomingBillsT } from "../../api/bills";
import { PowerFlowItemT, AdditionalDataT } from "../../api/dashboard";

import { ReactComponent as IconBill } from "../../assets/icons/Bill.svg";
import { ReactComponent as OnPeak } from "../../assets/icons/OnPeak.svg";
import { ReactComponent as OffPeak } from "../../assets/icons/OffPeak.svg";
import { ReactComponent as Chart } from "../../assets/icons/Chart.svg";
import { ReactComponent as Devices } from "../../assets/icons/Devices.svg";
import { StateT as userState } from "../../store/modules/users";
import styles from "../../containers/Home/Home.module.scss";

type PropsT = {
  upcoming: UpcomingBillsT;
  additional: AdditionalDataT | null;
  powerData: Array<PowerFlowItemT> | null;
};

export default function StatisticsBlockForDashboard(props: PropsT) {
  const { upcoming, additional, powerData } = props;
  const history = useHistory();
  let { userId }: any = useParams();
  const {isFlat} = useSelector<AppState, userState>(
    (state) => state.users
  );

  //@ts-ignore
  const { admin, payments, currentRate } = useSelector<AppState, AuthState>(
    (state) => state.authorization
  );

  const navigateToBillsPage = useCallback(() => {
    history.push(`/bills${admin ? `/${userId}` : ""}`);
  }, [history, admin, userId]);

  const renderPayNowButton = useCallback(() => {
    if (upcoming.savingsShare < 1 && payments && !payments.configured) {
      return <PayNowButton className={styles.payNowButton} />;
    }
  }, [payments, upcoming]);

  return (
    <div className="wrap">
      <div className={cx(styles.space, "row")}>
        <div className="col-12 md_col-4">
          <MobileView>
            <WhiteBlock className={styles.statisticsWrapper}>
              <UpcomingBill bill={upcoming.upcoming.projectedCharge} />
            </WhiteBlock>
          </MobileView>

          <BrowserView>
            <WhiteBlock className={styles.desktopBlockIndent}>
              <DesktopNomination
                Icon={IconBill}
                title="Charges"
                subtitle="Projected Charges"
                dates={upcoming.upcoming.projectedPeriod}
                bill={upcoming.upcoming.projectedCharge}
              >
                {(currentRate?.flat || isFlat) ? (
                  <div className={styles.descriptionBlock}>
                    <div className={cx("row", styles.peakRow)}>
                      <div className={styles.peakTitle}>
                        <OnPeak />
                        Consumption
                      </div>
                      <div className={styles.values}>
                        <NumericValueFormat
                          value={
                            upcoming.onPeakConsumption +
                            upcoming.offPeakConsumption
                          }
                          suffix="kWh"
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={styles.descriptionBlock}>
                    <div className={cx("row", styles.peakRow)}>
                      <div className={styles.peakTitle}>
                        <OnPeak />
                        On Peak
                      </div>
                      <div className={styles.values}>
                        <NumericValueFormat
                          value={upcoming.onPeakConsumption}
                          suffix="kWh"
                        />
                      </div>
                    </div>
                    <div className={cx("row", styles.peakRow)}>
                      <div className={styles.peakTitle}>
                        <OffPeak />
                        Off Peak
                      </div>
                      <div className={styles.values}>
                        <NumericValueFormat
                          value={upcoming.offPeakConsumption}
                          suffix="kWh"
                        />
                      </div>
                    </div>
                  </div>
                )}
                {admin ? (
                  <div
                    className={cx(styles.sideIndent, styles.viewReportIndent)}
                  >
                    <Button onClick={navigateToBillsPage}>
                      View payment history
                    </Button>
                  </div>
                ) : (
                  <div
                    style={{ marginTop: (!currentRate?.flat && !isFlat) ? 0 : "16px" }}
                    className={styles.sideIndent}
                  >
                    {renderPayNowButton()}

                    <Button variant="outlined" onClick={navigateToBillsPage}>
                      View payment history
                    </Button>
                  </div>
                )}
              </DesktopNomination>
            </WhiteBlock>
          </BrowserView>
        </div>

        <div className="col-6 md_col-4">
          <MobileView>
            <RectangleNomination
              name="Your lifetime Savings"
              number={additional?.lifetimeSavings.my || 0}
              isMoney
              Icon={Chart}
            />
          </MobileView>

          <BrowserView style={{ display: "flex" }}>
            <WhiteBlock
              className={cx(styles.desktopBlockIndent, styles.flexBlock)}
            >
              <DesktopLifetimeSavingsBlock
                lifetimeSavings={additional?.lifetimeSavings.total || 0}
                lifetimeSavingsShare={additional?.lifetimeSavings.my || 0}
                admin={admin}
                navigateToBillsPage={navigateToBillsPage}
              />
            </WhiteBlock>
          </BrowserView>
        </div>

        <div className="col-6 md_col-4">
          <MobileView>
            <RectangleNomination
              name="Solar System"
              number={additional?.panelSize || 0}
              suffix="kW"
              Icon={Devices}
            />
          </MobileView>

          <BrowserView style={{ display: "flex" }}>
            <WhiteBlock
              className={cx(
                styles.desktopBlockIndent,
                styles.sideIndent,
                styles.flexBlock
              )}
            >
              <PowerFlow data={powerData} />
            </WhiteBlock>
          </BrowserView>
        </div>
      </div>
    </div>
  );
}
