import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory,
  useParams,
} from "react-router-dom";
import cx from "classnames";

import {
  FAQ,
  ProfilePage,
  UsersListPage,
  EnergyPage,
  Home,
  UserDashboard,
  Bills,
} from "../";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Loading from "../../components/Loading/Loading";
import DashboardTitle from "../../components/DashboardTitle/DashboardTitle";
import SuccessAlert from "../../components/SuccessAlert/SuccessAlert";
import { AppState } from "../../store/modules";
import { StateT as AuthState } from "../../store/modules/authorization";
import { getUserInfo, StateT as userState } from "../../store/modules/users";
import {
  getAdditionalData,
  StateT as DashboardState,
} from "../../store/modules/dashboard";
import { StateT as SuccessAlertState } from "../../store/modules/successAlert";

import { ReactComponent as Chevron } from "../../assets/icons/Chevron-left.svg";
import breakpoints from "./../../variables.scss";
import styles from "./Dashboard.module.scss";
import DashboardTitleForAdmin from "../../components/DashboardTitle/DashboardTitleForAdmin";

export const ShowHeaderContext = React.createContext([true, () => {}]);

const Dashboard = () => {
  const history = useHistory();
  let { pathname } = useLocation();
  const { userId } = useParams<{ userId: string}>();
  const dispatch = useDispatch();
  const [showHeader, setShowHeader] = useState(true);

  const { name, admin, loading: authLoading } = useSelector<
    AppState,
    AuthState
  >((state) => state.authorization);

  const { showAlert, message } = useSelector<AppState, SuccessAlertState>(
    (state) => state.successAlert
  );

  const { additional } = useSelector<AppState, DashboardState>(
    ({ dashboard }) => dashboard
  );

  useEffect(() => {
    if (pathname === "/" && name) {
      history.push(admin ? "/users-list" : "/dashboard");
    }
  }, [pathname, name, admin, history]);

  useEffect(() => {
    if (!authLoading && !additional && !admin) {
      dispatch(getAdditionalData());
    }
  }, [additional, dispatch, admin, authLoading]);

  const {isFlat} = useSelector<AppState, userState>(
    (state) => state.users
  );
  
  const { currentRate } = useSelector<AppState, AuthState>(
    (state) => state.authorization
  );

  const isResponsiveView = useMediaQuery({
    query: `(max-width: ${breakpoints["tablet"]}px)`,
  });

  useEffect(() => {
    if ((!currentRate?.flat && !isFlat  && userId)) {
      dispatch(getUserInfo(userId));
    }
  }, [currentRate, dispatch, isFlat, userId])

  const isUserInfoLoaded =
    (currentRate?.flat !== null && currentRate?.flat !== undefined) ||
    (isFlat !== null && isFlat !== undefined);

  if (isUserInfoLoaded && (!name || (!admin && !additional))) {
    return <Loading />;
  }

  function getHeaderNameWithChevron(title: string) {
    return (
      <>
        <Chevron className={styles.chevronIcon} />
        {title}
      </>
    );
  }

  function renderHeaderContent() {
    if (!showHeader) {
      return null;
    }

    return (
      <>
        <Route exact path="/dashboard" component={DashboardTitle} />
        <Route
          exact
          path="/user-dashboard/:userId"
          render={() => <DashboardTitleForAdmin title="Energy Usage for" />}
        />
        <Route
          exact
          path="/profile"
          render={() => getHeaderNameWithChevron("User details")}
        />
        <Route
          exact
          path="/faq"
          render={() => getHeaderNameWithChevron("FAQ")}
        />
        <Route
          exact
          path="/energy"
          render={() => getHeaderNameWithChevron("Energy")}
        />
        <Route
          exact
          path="/bills"
          render={() => getHeaderNameWithChevron("My Bills")}
        />
        <Route exact path="/users-list" render={() => "Users"} />
      </>
    );
  }

  return (
    // @ts-ignore
    <ShowHeaderContext.Provider value={[showHeader, setShowHeader]}>
      <div className={cx({ [styles.dashboardWrapper]: isResponsiveView })}>
        <Header admin={admin} homeUrl="/">
          {renderHeaderContent()}
        </Header>

        <Switch>
          {admin ? (
            <>
              <Route exact path="/users-list" component={UsersListPage} />
              <Route
                exact
                path="/user-dashboard/:userId"
                component={UserDashboard}
              />
              <Route exact path="/bills/:userId" component={Bills} />
            </>
          ) : (
            <>
              <Route exact path="/dashboard" component={Home} />
              <Route exact path="/faq" component={FAQ} />
              <Route exact path="/profile" component={ProfilePage} />
              <Route exact path="/energy" component={EnergyPage} />
              <Route exact path="/bills" component={Bills} />
            </>
          )}
          <Redirect from="*" to="/404" />
        </Switch>

        {isResponsiveView && !admin && <Footer />}

      </div>
      <SuccessAlert isOpen={showAlert} message={message} />
    </ShowHeaderContext.Provider>
  );
};

export default Dashboard;
