import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import Loading from "../../components/Loading/Loading";
import DesktopEnergyBlock from "../../components/DesktopEnergyBlock/DesktopEnergyBlock";
import MobileEnergyBlock from "../../components/MobileEnergyBlock/MobileEnergyBlock";
import StatisticsBlockForDashboard from "../../components/StatisticsBlockForDashboard/StatisticsBlockForDashboard";

import { AppState } from "../../store/modules";
import {
  getEnergyData,
  StateT as DashboardState,
} from "../../store/modules/dashboard";
import {
  getUpcomingBills,
  StateT as BillsState,
} from "../../store/modules/bills";

import breakpoints from "../../variables.scss";

const Home = () => {
  const dispatch = useDispatch();
  const { additional, loading, energy, period, powerData } = useSelector<
    AppState,
    DashboardState
  >((state) => state.dashboard);

  const { upcoming, loaded: billsLoaded, error } = useSelector<
    AppState,
    BillsState
  >((state) => state.bills);

  const isResponsiveView = useMediaQuery({
    query: `(max-width: ${breakpoints["tablet"]}px)`,
  });

  const [, forceUpdate] = useState<undefined>();

  const resizeChart = () => {
    forceUpdate(undefined);
  };

  useEffect(() => {
    resizeChart();
    window.addEventListener("resize", resizeChart);

    return () => {
      window.removeEventListener("resize", resizeChart);
    };
  }, []);

  useEffect(() => {
    if (!upcoming && !billsLoaded && !error) dispatch(getUpcomingBills());
  }, [dispatch, upcoming, billsLoaded, error]);

  useEffect(() => {
    function counter() {
      if (!energy || period === "today") dispatch(getEnergyData());
    }

    if (!energy) {
      counter();
    }

    let id = setInterval(counter, 300000); // Update energy info each 5 minutes

    return () => clearInterval(id);
  }, [energy, dispatch, period]);

  if ((!additional && loading) || !upcoming) {
    return <Loading />;
  }

  return (
    <>
      {energy ? (
        isResponsiveView ? (
          <MobileEnergyBlock energy={energy} period={period} />
        ) : (
          <DesktopEnergyBlock energy={energy} period={period} />
        )
      ) : null}

      {additional && (
        <StatisticsBlockForDashboard
          upcoming={upcoming}
          additional={additional}
          powerData={powerData}
        />
      )}
    </>
  );
};

export default Home;
