/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactQuill from "react-quill";

import ModalHeader from "../Modal/ModalHeader";
import ModalContent from "../Modal/ModalContent";
import ModalFooter from "../Modal/ModalFooter";
import Button from "../Button/Button";
import { getUsersList, StateT as UsersState } from "../../store/modules/users";
import { StateT as AuthState } from "../../store/modules/authorization";
import { AppState } from "../../store/modules";
import { createReferralLink } from "../../api/users";
import "./ReferralLinkModal.scss";
import isValidUrl from "../../helpers/validateURL";
import ReactTooltip from "../ReactTooltip/ReactTooltip";

type PropsT = {
  closeModal: () => void;
  setSuccessReferralLinkModal?: (value: boolean) => void;
};

export default function AdditionalServicesModal(props: PropsT) {
  const { closeModal, setSuccessReferralLinkModal } = props;
  const { selectedUser } = useSelector<AppState, UsersState>(
    (state) => state.users
  );
  const { admin, referralText: referralInfo } = useSelector<
    AppState,
    AuthState
  >((state) => state.authorization);

  const [referralText, setReferralText] = useState<string>(
    selectedUser?.referralText ?? ""
  );
  const [clipboardText, setClipboardText] = useState<string>("");
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (referralInfo?.trim()) {
      const textToArray = referralInfo?.split('"');
      for (let index = 0; index < textToArray.length; index++) {
        if (isValidUrl(textToArray[index])) {
          setClipboardText(textToArray[index]);
          return;
        }
      }
    }
  }, []);

  const onSubmit = async () => {
    const data = {
      referralText,
    };
    const result = await createReferralLink(selectedUser!.id, data);
    if (result.status === 200) {
      setSuccessReferralLinkModal && setSuccessReferralLinkModal(true);
      closeModal();
      dispatch(getUsersList());
    }
  };

  const handleEditorChange = (content: string) => {
    setReferralText(content);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(clipboardText);
    setShowTooltip(true);
  };

  return (
    <>
      <ModalHeader>Referral Link</ModalHeader>
      <ModalContent>
        {admin ? (
          <ReactQuill
            value={referralText}
            theme="snow"
            onChange={handleEditorChange}
          />
        ) : (
          <div
            dangerouslySetInnerHTML={{ __html: referralInfo ?? "" }}
            className="ql-editor"
            v-html="result"
          />
        )}
      </ModalContent>
      <ModalFooter>
        {admin ? (
          <Button
            variant="outlined"
            disabled={!referralText.trim() || referralText === "<p><br></p>"}
            onClick={onSubmit}
          >
            Save
          </Button>
        ) : (
          <ReactTooltip visible={showTooltip} text="Copied to clipboard">
            <Button
              variant="outlined"
              disabled={!clipboardText}
              onClick={copyToClipboard}
              onMouseLeave={() => setShowTooltip(false)}
            >
              Copy Link
            </Button>
          </ReactTooltip>
        )}
        <Button onClick={closeModal}>Cancel</Button>
      </ModalFooter>
    </>
  );
}
