import React from "react";
import cx from "classnames";
import { FieldProps } from "formik";
import MaskedInput from "react-text-mask";
import get from 'lodash/get';
import ValidateMessage from "../ValidateMessage/ValidateMessage";
import styles from "./InputField.module.scss";

type PropsT = {
  label?: string | null;
  placeholder?: string;
  type?: "email" | "text" | "number";
  className?: string;
  inputClassName?: string;
  onBlur?: () => void;
  hasValidationMessage?: boolean;
  mask?: RegExp[];
  variant?: "clear" | "outline";
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  iconStyle?: React.CSSProperties;
  disabled?: boolean;
  max?: number;
  min?: number;
  step?: number;
  defaultValue?: string | number;
} & FieldProps;

const InputField = (props: PropsT) => {
  const { mask, form, field, placeholder, Icon, label, type, className, iconStyle, hasValidationMessage = true, variant = "clear", disabled, max, min, step, defaultValue } = props;
  const { name } = field;
  const { touched, errors } = form;

  field.onBlur = () => {
    form.setFieldTouched(name, true);

    if (props.onBlur) {
      props.onBlur();
    }
  };

  const hasError = get(touched, name) && get(errors, name);

  const inputClassName = cx(styles.input, styles[variant], props.inputClassName, {
    [styles.hasIcon]: !!Icon,
    [styles.error]: hasError
  });

  return (
    <div className={cx(styles.element, className)}>
      <label className={styles.content} htmlFor={name}>
        {label && <p className={styles.label}>{label}</p>}
        <div className={styles.inputContent}>
          {mask ? (
            <MaskedInput
              className={inputClassName}
              {...field}
              id={field.name}
              placeholder={placeholder}
              mask={mask}
            />
          ) : (
            <input
              {...field}
              id={name}
              type={type ? type : "text"}
              name={name}
              placeholder={placeholder}
              className={inputClassName}
              disabled={disabled}
              step={step}
              min={min}
              max={max}
              defaultValue={defaultValue}
            />
          )}

          {Icon && (
            <div className={cx(styles.iconContent, styles[variant])}>
              <Icon style={iconStyle} className={styles.icon} width={14} height={10} />
            </div>
          )}
        </div>
      </label>

      {hasValidationMessage && <ValidateMessage name={name} />}
    </div>
  );
};

export default InputField;
