import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, FormikProps, Form, FormikConfig, Field } from "formik";
import cx from "classnames";

import Button from "../../components/Button/Button";
import InputField from "../../components/InputField/InputField";
import SelectField from "../../components/SelectField/SelectField";
import ModalHeader from "../../components/Modal/ModalHeader";
import ModalContent from "../../components/Modal/ModalContent";
import ModalFooter from "../../components/Modal/ModalFooter";
import { AppState } from "../../store/modules";
import { dateFormat } from "../../helpers/formatDate";
import {
  StateT as InfoState,
  getPowerProviders,
  getRates,
  getUpdatedRatesHistory,
  showRatesHistory,
} from "../../store/modules/additionalInfo";
import styles from "./EditFPLRates.module.scss";
import { getLastUpdatedRate, updateLatestRate } from "../../api/additionalInfo";
import ReactDatePicker from "react-datepicker";

type PropsT = {
  closeModal: () => void;
  setSuccessEditFPLRates: (value: boolean) => void;
  setShowListOfUpdates: (value: boolean) => void;
};

const FPLRateEditModal = (props: PropsT) => {
  const dispatch = useDispatch();
  const { closeModal, setSuccessEditFPLRates, setShowListOfUpdates } = props;
  const [lastUpdatedRate, setLastUpdatedRate] = useState<any>({});
  const [enableRates, setEnableRates] = useState<boolean>(false);
  const [showFields, setShowFields] = useState<boolean>(false);
  const [date, setDate] = useState(new Date());
  const [powerProviderId, setPowerProviderId] = useState<string>("");
  const [rateId, setRateId] = useState<string>("");

  const { powerProviders, rates } = useSelector<AppState, InfoState>(
    (state) => state.additionalInfo
  );

  useEffect(() => {
    if (!powerProviders) {
      dispatch(getPowerProviders());
    }
  }, [powerProviders, dispatch]);

  useEffect(() => {
    if (rateId) {
      (async () => {
        const result = await getLastUpdatedRate(powerProviderId, rateId, (new Date()).toISOString());
        if (result.status >= 200 && result.status < 300) {
          setLastUpdatedRate(result.data);
        }
      })();
    }
  }, [rateId, powerProviderId]);

  const validate = (values: any) => {
    const errors: any = {};

    if (!values.boundary && values.boundary !== 0) {
      errors.boundary = "Required field";
    } else if (isNaN(values.boundary)) {
      errors.boundary = "Invalid value";
    }

    if (
      !values.overBoundary?.nonFuelCharge &&
      values.overBoundary?.nonFuelCharge !== 0
    ) {
      errors.overBoundary = {};
      errors.overBoundary.nonFuelCharge = "Required field";
    } else if (isNaN(values.overBoundary.nonFuelCharge)) {
      errors.overBoundary = {};
      errors.overBoundary.nonFuelCharge = "Invalid value";
    }

    if (
      !values.overBoundary?.fuelCharge &&
      values.overBoundary?.fuelCharge !== 0
    ) {
      errors.overBoundary = {};
      errors.overBoundary.fuelCharge = "Required field";
    } else if (isNaN(values.overBoundary.fuelCharge)) {
      errors.overBoundary = {};
      errors.overBoundary.fuelCharge = "Invalid value";
    }

    if (!values.variable?.conservation && values.variable?.conservation !== 0) {
      errors.variable = {};
      errors.variable.conservation = "Required field";
    } else if (isNaN(values.variable.conservation)) {
      errors.variable = {};
      errors.variable.conservation = "Invalid value";
    }

    if (!values.variable?.storm && values.variable?.storm !== 0) {
      errors.variable = {};
      errors.variable.storm = "Required field";
    } else if (isNaN(values.variable.storm)) {
      errors.variable = {};
      errors.variable.storm = "Invalid value";
    }

    if (
      !values.variable?.environmental &&
      values.variable?.environmental !== 0
    ) {
      errors.variable = {};
      errors.variable.environmental = "Required field";
    } else if (isNaN(values.variable.environmental)) {
      errors.variable = {};
      errors.variable.environmental = "Invalid value";
    }

    if (!values.variable?.capacity && values.variable?.capacity !== 0) {
      errors.variable = {};
      errors.variable.capacity = "Required field";
    } else if (isNaN(values.variable.capacity)) {
      errors.variable = {};
      errors.variable.capacity = "Invalid value";
    }

    if (!values.fixed?.customer && values.fixed?.customer !== 0) {
      errors.fixed = {};
      errors.fixed.customer = "Required field";
    } else if (isNaN(values.fixed.customer)) {
      errors.fixed = {};
      errors.fixed.customer = "Invalid value";
    }

    if (!values.basic?.nonFuelCharge && values.basic?.nonFuelCharge !== 0) {
      errors.basic = {};
      errors.basic.nonFuelCharge = "Required field";
    } else if (isNaN(values.basic.nonFuelCharge)) {
      errors.basic = {};
      errors.basic.nonFuelCharge = "Invalid value";
    }

    if (!values.basic?.fuelCharge && values.basic?.fuelCharge !== 0) {
      errors.basic = {};
      errors.basic.fuelCharge = "Required field";
    } else if (isNaN(values.basic.fuelCharge)) {
      errors.basic = {};
      errors.basic.fuelCharge = "Invalid value";
    }
    return errors;
  };

  const onSubmit = async (values: any) => {
    values.validFrom = date;
    const result = await updateLatestRate(powerProviderId, rateId, values);
    if (result.status >= 200 && result.status < 300) {
      setSuccessEditFPLRates(true);
      closeModal();
    }
  };

  const form: FormikConfig<any> = {
    initialValues: {
      //@ts-ignore
      ...lastUpdatedRate,
    },
    enableReinitialize: true,
    onSubmit,
    validate,
  };

  function OpenListOfUpdates() {
    setShowListOfUpdates(true);
    dispatch(getUpdatedRatesHistory(powerProviderId, rateId));
    dispatch(showRatesHistory(true));
  }

  const powerProvidersOptions = powerProviders
    ? powerProviders.map((provider) => ({
        label: provider.name,
        value: provider.id,
      }))
    : [];

  const ratesOptions = rates
    ? rates.map((rate: any) => ({
        label: rate.name,
        value: rate.id,
      }))
    : [];

  const changeProviderCallback = (id: string) => {
    setPowerProviderId(id);
    setEnableRates(true);
    setShowFields(false);
    dispatch(getRates(id));
  };
  const selectRate = (id: string) => {
    setRateId(id);
    if (rates?.length) {
      //@ts-ignore
      setShowFields(true);
    }
  };

  const handleChangeDate = (date: Date) => setDate(date);

  return (
    <Formik {...form}>
      {(formik: FormikProps<any>): React.ReactNode => {
        const { values, dirty, isValid } = formik;
        return (
          <Form noValidate className={cx(styles.form)}>
            <ModalHeader>Power Provider Rates</ModalHeader>

            <ModalContent>
              <hr className={styles.divider} />
              <Field
                component={SelectField}
                variant="outline"
                name="powerProvider"
                label="Power Provider"
                placeholder="Select your provider"
                options={powerProvidersOptions}
                className={styles.formItem}
                handleChangeCallback={changeProviderCallback}
              />
              <Field
                component={SelectField}
                variant="outline"
                name="rate"
                label="Rate"
                placeholder="Select your power rate"
                options={ratesOptions}
                handleChangeCallback={selectRate}
                disabled={!enableRates}
              />
              {values.validFrom && showFields && (
                <>
                  <br />
                  <div style={{ textAlign: "right" }}>
                    <button
                      className={cx("link", "default")}
                      type="button"
                      onClick={OpenListOfUpdates}
                    >
                      Update History List
                    </button>
                  </div>
                  <h3 className={styles.rateHeader}>RATE</h3>
                  <div className={styles.date}>
                    <p>Valid from:&nbsp;&nbsp;&nbsp;</p>
                    <ReactDatePicker
                      selected={date}
                      onChange={handleChangeDate}
                      dateFormat={dateFormat}
                      showMonthDropdown
                      showYearDropdown
                    />
                  </div>
                  <p className={styles.subtitle}>
                    <span>Fixed</span>
                  </p>
                  <Field
                    component={InputField}
                    variant="outline"
                    name={`fixed.customer`}
                    label="Customer"
                    placeholder="Enter customer"
                    className={styles.powerSupplierFormItem}
                    type="number"
                  />
                  <p className={styles.subtitle}>
                    <span>Variables</span>
                  </p>
                  <Field
                    component={InputField}
                    variant="outline"
                    name={`variable.conservation`}
                    label="Conservation"
                    placeholder="Enter conservation"
                    className={styles.powerSupplierFormItem}
                    type="number"
                  />
                  <Field
                    component={InputField}
                    variant="outline"
                    name={`variable.storm`}
                    label="Storm"
                    placeholder="Enter storm"
                    className={styles.powerSupplierFormItem}
                    type="number"
                  />
                  <Field
                    component={InputField}
                    variant="outline"
                    name={`variable.environmental`}
                    label="Environmental"
                    placeholder="Enter environmental"
                    className={styles.powerSupplierFormItem}
                    type="number"
                  />
                  <Field
                    component={InputField}
                    variant="outline"
                    name={`variable.capacity`}
                    label="Capacity"
                    placeholder="Enter capacity"
                    className={styles.powerSupplierFormItem}
                    type="number"
                  />
                  <p className={styles.subtitle}>
                    <span>Basic</span>
                  </p>
                  <div className={styles.fieldWrapper}>
                    <p className={styles.dollar}>$</p>
                    <Field
                      component={InputField}
                      variant="outline"
                      name={`basic.nonFuelCharge`}
                      label="Non fuel charge"
                      placeholder="Enter Non fuel charge"
                      className={styles.powerSupplierFormItem}
                      type="number"
                    />
                  </div>
                  <div className={styles.fieldWrapper}>
                    <p className={styles.dollar}>$</p>
                    <Field
                      component={InputField}
                      variant="outline"
                      name={`basic.fuelCharge`}
                      label="fuel charge"
                      placeholder="Enter fuel charge"
                      className={styles.powerSupplierFormItem}
                      type="number"
                    />
                  </div>
                  {values.peak && (
                    <>
                      <p className={styles.subtitle}>
                        <span>Peak</span>
                      </p>
                      <p className={styles.subHeader}>
                        <span>On Peak</span>
                      </p>
                      <div className={styles.fieldWrapper}>
                        <p className={styles.dollar}>$</p>
                        <Field
                          component={InputField}
                          variant="outline"
                          name={`peak.onPeak.nonFuelCharge`}
                          label="Non fuel charge"
                          placeholder="Enter Non fuel charge"
                          className={styles.powerSupplierFormItem}
                          type="number"
                        />
                      </div>
                      <div className={styles.fieldWrapper}>
                        <p className={styles.dollar}>$</p>
                        <Field
                          component={InputField}
                          variant="outline"
                          name={`peak.onPeak.fuelCharge`}
                          label="fuel charge"
                          placeholder="Enter fuel charge"
                          className={styles.powerSupplierFormItem}
                          type="number"
                        />
                      </div>
                      <p className={styles.subHeader}>
                        <span>Off Peak</span>
                      </p>
                      <div className={styles.fieldWrapper}>
                        <p className={styles.dollar}>$</p>
                        <Field
                          component={InputField}
                          variant="outline"
                          name={`peak.offPeak.nonFuelCharge`}
                          label="Non fuel charge"
                          placeholder="Enter Non fuel charge"
                          className={styles.powerSupplierFormItem}
                          type="number"
                        />
                      </div>
                      <div className={styles.fieldWrapper}>
                        <p className={styles.dollar}>$</p>
                        <Field
                          component={InputField}
                          variant="outline"
                          name={`peak.offPeak.fuelCharge`}
                          label="fuel charge"
                          placeholder="Enter fuel charge"
                          className={styles.powerSupplierFormItem}
                          type="number"
                        />
                      </div>
                    </>
                  )}
                  <p className={styles.subtitle}>
                    <span>Boundary</span>
                  </p>
                  <Field
                    component={InputField}
                    variant="outline"
                    name={`boundary`}
                    label="Boundary"
                    placeholder="Enter boundary"
                    className={styles.powerSupplierFormItem}
                    type="number"
                  />
                  <p className={styles.subHeader}>
                    <span>Over Boundary</span>
                  </p>
                  <div className={styles.fieldWrapper}>
                    <p className={styles.dollar}>$</p>
                    <Field
                      component={InputField}
                      variant="outline"
                      name={`overBoundary.nonFuelCharge`}
                      label="Non fuel charge"
                      placeholder="Enter Non fuel charge"
                      className={styles.powerSupplierFormItem}
                      type="number"
                    />
                  </div>
                  <div className={styles.fieldWrapper}>
                    <p className={styles.dollar}>$</p>
                    <Field
                      component={InputField}
                      variant="outline"
                      name={`overBoundary.fuelCharge`}
                      label="fuel charge"
                      placeholder="Enter fuel charge"
                      className={styles.powerSupplierFormItem}
                      type="number"
                    />
                  </div>
                </>
              )}
            </ModalContent>

            <ModalFooter>
              <Button
                variant="outlined"
                type="submit"
                className={styles.buttonPrimary}
                disabled={!(isValid && dirty)}
              >
                Update Rate
              </Button>
              <Button onClick={closeModal} className={styles.buttonSecondary}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FPLRateEditModal;
