import React, { ReactNode } from "react";
import styles from "./Modal.module.scss";

type TProps = {
  children: ReactNode;
};

const ModalContent = (props: TProps) => {
  const { children } = props;

  return <main className={styles.wrap}>{children}</main>;
};

export default ModalContent;
