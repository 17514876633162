import {
  UserConfirmT,
  UserLoginT,
  RecoverPasswordT,
  TokensT,
  ChangingPasswordT,
} from "../../api/authorization";

export const CONFIRM = "CONFIRM";
export const CONFIRM_SUCCESS = "CONFIRM_SUCCESS";
export const CONFIRM_FAILURE = "CONFIRM_FAILURE";

export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const FORGOT = "FORGOT";
export const FORGOT_SUCCESS = "FORGOT_SUCCESS";
export const FORGOT_FAILURE = "FORGOT_FAILURE";

export const RECOVERY = "RECOVERY";
export const RECOVERY_SUCCESS = "RECOVERY_SUCCESS";
export const RECOVERY_FAILURE = "RECOVERY_FAILURE";

export const CHECK = "CHECK";
export const CHECK_SUCCESS = "CHECK_SUCCESS";
export const CHECK_FAILURE = "CHECK_FAILURE";

export const RENEW_TOKEN = "RENEW_TOKEN";
export const RENEW_TOKEN_SUCCESS = "RENEW_TOKEN_SUCCESS";
export const RENEW_TOKEN_FAILURE = "RENEW_TOKEN_FAILURE";

export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const UPLOAD_PHOTO = "UPLOAD_PHOTO";
export const UPLOAD_PHOTO_SUCCESS = "UPLOAD_PHOTO_SUCCESS";
export const UPLOAD_PHOTO_FAILURE = "UPLOAD_PHOTO_FAILURE";

export const REMOVE_PHOTO = "REMOVE_PHOTO";
export const REMOVE_PHOTO_SUCCESS = "REMOVE_PHOTO_SUCCESS";
export const REMOVE_PHOTO_FAILURE = "REMOVE_PHOTO_FAILURE";

export const RESET_UPLOAD_STATUS = "RESET_UPLOAD_STATUS";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export type ConfirmT = {
  type: typeof CONFIRM;
  data: UserConfirmT;
};

type ConfirmSuccessT = {
  type: typeof CONFIRM_SUCCESS;
  payload: any;
};

type ConfirmFailureT = {
  type: typeof CONFIRM_FAILURE;
  error: string;
};

export type LoginT = {
  type: typeof LOGIN;
  data: UserLoginT;
};

type LoginSuccessT = {
  type: typeof LOGIN_SUCCESS;
  payload: any;
};

type LoginFailureT = {
  type: typeof LOGIN_FAILURE;
  error: string;
};

export type ForgotT = {
  type: typeof FORGOT;
  data: { email: string };
};

type ForgotSuccessT = {
  type: typeof FORGOT_SUCCESS;
  payload: any;
};

type ForgotFailureT = {
  type: typeof FORGOT_FAILURE;
  error: string;
};

export type RecoveryT = {
  type: typeof RECOVERY;
  data: RecoverPasswordT;
};

type RecoverySuccessT = {
  type: typeof RECOVERY_SUCCESS;
  payload: any;
};

type RecoveryFailureT = {
  type: typeof RECOVERY_FAILURE;
  error: string;
};

export type CheckT = {
  type: typeof CHECK;
};

type CheckSuccessT = {
  type: typeof CHECK_SUCCESS;
  payload: any;
};

type CheckFailureT = {
  type: typeof CHECK_FAILURE;
  error: string;
};

export type RenewTokenT = {
  type: typeof RENEW_TOKEN;
};

type RenewTokenSuccessT = {
  type: typeof RENEW_TOKEN_SUCCESS;
  payload: TokensT;
};

type RenewTokenFailureT = {
  type: typeof RENEW_TOKEN_FAILURE;
  error: string;
};

export type LogoutT = {
  type: typeof LOGOUT;
};

type LogoutSuccessT = {
  type: typeof LOGOUT_SUCCESS;
  payload: { userId: null };
};

type LogoutFailureT = {
  type: typeof LOGOUT_FAILURE;
  error: string;
};

export type UploadPhotoT = {
  type: typeof UPLOAD_PHOTO;
  file: File;
};

type UploadPhotoSuccessT = {
  type: typeof UPLOAD_PHOTO_SUCCESS;
  payload: any;
};

type UploadPhotoFailureT = {
  type: typeof UPLOAD_PHOTO_FAILURE;
  error: string;
};

export type RemovePhotoT = {
  type: typeof REMOVE_PHOTO;
};

type RemovePhotoSuccessT = {
  type: typeof REMOVE_PHOTO_SUCCESS;
  editedPhoto: true;
};

type RemovePhotoFailureT = {
  type: typeof REMOVE_PHOTO_FAILURE;
  error: string;
};

type ResetUploadStatusT = {
  type: typeof RESET_UPLOAD_STATUS;
};

export type ChangePasswordT = {
  type: typeof CHANGE_PASSWORD;
  data: ChangingPasswordT;
};

type ChangePasswordSuccessT = {
  type: typeof CHANGE_PASSWORD_SUCCESS;
};

type ChangePasswordFailureT = {
  type: typeof CHANGE_PASSWORD_FAILURE;
  error: string;
};

export type ActionTypeT =
  | ConfirmT
  | ConfirmSuccessT
  | ConfirmFailureT
  | LoginT
  | LoginSuccessT
  | LoginFailureT
  | ForgotT
  | ForgotSuccessT
  | ForgotFailureT
  | RecoveryT
  | RecoverySuccessT
  | RecoveryFailureT
  | CheckT
  | CheckSuccessT
  | CheckFailureT
  | RenewTokenT
  | RenewTokenSuccessT
  | RenewTokenFailureT
  | LogoutT
  | LogoutSuccessT
  | LogoutFailureT
  | UploadPhotoT
  | UploadPhotoSuccessT
  | UploadPhotoFailureT
  | RemovePhotoT
  | RemovePhotoSuccessT
  | RemovePhotoFailureT
  | ResetUploadStatusT
  | ChangePasswordT
  | ChangePasswordSuccessT
  | ChangePasswordFailureT;

export type StateT = {
  loading: boolean;
  loaded: boolean;
  id: string | null;
  name: string | null;
  admin: boolean;
  error: string | null;
  email: string | null;
  message: string | null;
  photoLink: string | null;
  address: string | null;
  ecobeeId: string | null;
  sensorNames: null | Array<string>;
  supplierCode: null | string;
  addedPhoto: boolean;
  deletedPhoto: boolean;
  referralText?: string;
  currentRate: any;
  accountId: null | string;
  payments: null | {
    configured?: boolean,
    accountName?: string,
    accountNumber?: string,
  }
};

const initialState: StateT = {
  loading: false,
  loaded: false,
  id: null,
  name: null,
  admin: false,
  error: null,
  email: null,
  message: null,
  photoLink: null,
  address: null,
  ecobeeId: null,
  sensorNames: null,
  supplierCode: null,
  addedPhoto: false,
  deletedPhoto: false,
  accountId: null,
  payments: null,
  currentRate: null
};

export default function authorizationReducer(
  state: StateT = initialState,
  action: ActionTypeT
): StateT {
  switch (action.type) {
    case CONFIRM:
    case LOGIN:
    case FORGOT:
    case RECOVERY:
    case CHECK:
    case RENEW_TOKEN:
    case LOGOUT:
      return { ...state, loading: true, message: null, error: null };
    case UPLOAD_PHOTO:
    case REMOVE_PHOTO:
    case CHANGE_PASSWORD:
      return {
        ...state,
        loading: true,
        loaded: false,
        addedPhoto: false,
        deletedPhoto: false,
      };
    case CONFIRM_SUCCESS:
    case LOGIN_SUCCESS:
    case FORGOT_SUCCESS:
    case RECOVERY_SUCCESS:
    case CHECK_SUCCESS:
    case RENEW_TOKEN_SUCCESS:
    case LOGOUT_SUCCESS:
      return { ...state, loading: false, ...action.payload };
    case CHANGE_PASSWORD_SUCCESS:
      return { ...state, loading: false, loaded: true };
    case UPLOAD_PHOTO_SUCCESS:
      return { ...state, loading: false, addedPhoto: true };
    case REMOVE_PHOTO_SUCCESS:
      return { ...state, loading: false, deletedPhoto: true };
    case CONFIRM_FAILURE:
    case LOGIN_FAILURE:
    case FORGOT_FAILURE:
    case RECOVERY_FAILURE:
    case CHECK_FAILURE:
    case RENEW_TOKEN_FAILURE:
    case LOGOUT_FAILURE:
    case UPLOAD_PHOTO_FAILURE:
    case REMOVE_PHOTO_FAILURE:
    case CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case RESET_UPLOAD_STATUS: {
      return {
        ...state,
        loading: false,
        addedPhoto: false,
        deletedPhoto: false,
      };
    }

    default:
      return state;
  }
}

export function confirmUser(data: UserConfirmT) {
  return {
    type: CONFIRM,
    data,
  };
}

export function loginUser(data: UserLoginT) {
  return {
    type: LOGIN,
    data,
  };
}

export function logOut() {
  return {
    type: LOGOUT,
  };
}

export function forgotPassword(data: ForgotT) {
  return {
    type: FORGOT,
    data,
  };
}

export function checkUser() {
  return {
    type: CHECK,
  };
}

export function renewToken() {
  return {
    type: RENEW_TOKEN,
  };
}

export function recoveryPassword(data: RecoveryT) {
  return {
    type: RECOVERY,
    data,
  };
}

export function uploadPhoto(file: FormData) {
  return {
    type: UPLOAD_PHOTO,
    file,
  };
}

export function resetUploadStatus() {
  return {
    type: RESET_UPLOAD_STATUS,
  };
}

export function removePhoto() {
  return {
    type: REMOVE_PHOTO,
  };
}

export function changePassword(data: ChangingPasswordT) {
  return {
    type: CHANGE_PASSWORD,
    data,
  };
}
