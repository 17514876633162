import React from "react";
import { useMediaQuery } from "react-responsive";
import cx from "classnames";
import TooltipStatisticsView from "../TooltipStatisticsView/TooltipStatisticsView";
import StatisticsView from "../StatisticsView/StatisticsView";

import breakpoints from "../../variables.scss";
import styles from "../CustomChartTooltip/CustomChartTooltip.module.scss";

const CustomTooltip = ({ active, payload }: any) => {
  const info = (payload && payload[0] && payload[0].payload) || {};
  const { consumption } = info;
  const isResponsiveView = useMediaQuery({
    query: `(max-width: ${breakpoints["tablet"]}px)`,
  });

  if (active && ("consumption" in info || "label" in info)) {
    if (isResponsiveView) {
      return <StatisticsView {...info} />;
    }
    return (
      <div
        className={cx(styles.tooltipWrapper, !consumption && styles.wideWindow)}
      >
        <TooltipStatisticsView {...info} />
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
