import React, { ReactNode } from "react";
import cx from "classnames";
import Title from "../Title/Title";
import styles from "./Modal.module.scss";

type PropsT = {
  children: ReactNode;
  className?: string;
};

const ModalHeader = (props: PropsT) => {
  const { children } = props;

  return (
    <header className={cx(styles.header, props.className)}>
      <div className={styles.wrap}>
        <Title>{children}</Title>
      </div>
    </header>
  );
};

export default ModalHeader;
