import axios from "./axiosConfig";

export type InvitedUserT = {
  name: string;
  email: string;
  accountId: string;
  isAdmin?: boolean;
  address: string;
  ecobeeId: string;
  neurioIds: string[];
  powerSupplierId?: string;
  powerSupplierAccountId?: string;
  supplierRateId?: string;
  panelSize: number | string;
};

export type UserT = {
  id: string;
  name: string;
  email: string;
  address: string;
  ecobeeId: string;
  supplierAccount: string;
  confirmed?: boolean;
  sensorIds?: Array<string>;
  supplierName?: string;
  supplier: any;
  supplierCode?: string;
  balance?: number;
  upcomingBill?: number;
  uploaded?: false;
  isAdmin?: boolean;
  photoLink?: string;
  panelSize?: number;
  solarPanelSize?: number;
  referralText?: string;
  onPeakRatio?: {
    month?: number;
    yesterday?: number;
  };
  installationPerformance?: {
    month?: number;
    yesterday?: number;
  };
  baseline?: BaselineT;
  accountId: string;
  currentSavingsShare?: number;
};

export type MonthT = {
  january?: number;
  february?: number;
  march?: number;
  april?: number;
  may?: number;
  june?: number;
  july?: number;
  august?: number;
  september?: number;
  october?: number;
  november?: number;
  december?: number;
};

export type BaselineT = {
  homeId?: string;
  baseline: MonthT;
  onPeak: MonthT;
  offPeak: MonthT;
  generation: MonthT;
};

export type InviteFriendT = {
  name: string;
  email: string;
  phone: string;
  address: string;
};

export type SavingShareT = {
  share: number;
  validFrom: Date;
  validTo: Date;
};

export function receiveUsersList() {
  return axios.get("/admin/homes");
}

export function receiveUserById(id: string) {
  return axios.get(`/admin/homes/${id}`);
}

export function inviteUser(user: InvitedUserT) {
  return axios.post("/auth/invite", user);
}

export function deleteUserById(id: string) {
  return axios.delete(`/admin/homes/${id}`);
}

export function sendBaseline({
  data,
  homeId,
}: {
  data: BaselineT;
  homeId: string;
}) {
  return axios.post(`/admin/homes/${homeId}/baseline`, data);
}

export function getBaseline(homeId: string) {
  return axios.get(`/admin/homes/${homeId}/baseline`);
}

export function setBaseline(homeId: string, data: BaselineT) {
  return axios.post(`/admin/homes/${homeId}/baseline`, data);
}

export function getUserEnergy(homeId: string, period: string) {
  return axios.get(`/admin/homes/${homeId}/energy/${period}`);
}

export function inviteFriend(data: InviteFriendT) {
  return axios.post("/me/friend", data);
}

export function resendInvite(homeId: string) {
  return axios.post(`/admin/homes/${homeId}/resend`);
}

export function getUserEnergyFlow(homeId: string) {
  return axios.get(`/admin/homes/${homeId}/energy/flow`);
}

export function getUserAdditionalData(homeId: string) {
  return axios.get(`/admin/homes/${homeId}`);
}

export function changeSavingShare(
  homeId?: string,
  savingShare?: number,
  date?: Date
) {
  return axios.post(`/admin/homes/${homeId}/share`, { savingShare, date });
}

export function getSavingShare(homeId: string) {
  return axios.get(`/admin/homes/${homeId}/share`);
}

export function getAdditionalServices(userId: string) {
  return axios.get(`/admin/homes/${userId}/additional-charges`);
}

export function changeAdditionalService(
  homeId?: string,
  additionalCharges?: number,
  date?: Date
) {
  return axios.post(`/admin/homes/${homeId}/additional-charges`, { additionalCharges, date });
}

export function createReferralLink(homeId: string, data: any) {
  return axios.post(`/admin/homes/${homeId}/referral`, data);
}

export function getUserRate(homeId: string) {
  return axios.get(`/admin/homes/${homeId}/rate`);
}

export function updateUserRate(homeId: string, data: any) {
  return axios.post(`/admin/homes/${homeId}/rate`, data);
}
