import {
  take,
  put,
  takeEvery,
  call,
  all,
  fork,
  select,
  delay,
} from "redux-saga/effects";
import {
  GetUserByIdT,
  DeleteUserT,
  SwitchUserDetailsT,
  GetBaselineT,
  GetUserEnergyDataT,
  GetUserEnergyDataByIdT,
  UpdateUserBaselineRequestT,
  GetUserAdditionalDataRequestT,
  OpenUserEditModalT,
  ChangeSavingShareT,
  GetSavingShareT,
  GET_USERS_LIST,
  GET_USERS_LIST_SUCCESS,
  GET_USERS_LIST_FAILURE,
  GET_USER_BY_ID,
  GET_USER_BY_ID_SUCCESS,
  GET_USER_BY_ID_FAILURE,
  CHANGE_USERS_LIST,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  SWITCH_USER_DETAIL,
  SWITCHED_USER_DETAIL,
  GET_BASELINE,
  GET_BASELINE_SUCCESS,
  GET_BASELINE_FAILURE,
  OPEN_USER_ENERGY,
  OPENED_USER_ENERGY,
  GET_USER_ENERGY_DATA_BY_ID,
  GET_USER_ENERGY_DATA,
  GET_USER_ENERGY_DATA_SUCCESS,
  GET_USER_ENERGY_DATA_FAILURE,
  GET_USER_ADDITIONAL_DATA_REQUEST,
  UPDATE_USER_BASELINE_REQUEST,
  OPEN_USER_EDIT_MODAL,
  CHANGE_SAVING_SHARE,
  CHANGE_SAVING_SHARE_SUCCESS,
  CHANGE_SAVING_SHARE_FAILURE,
  GET_SAVING_SHARE,
  GET_SAVING_SHARE_SUCCESS,
  GET_SAVING_SHARE_FAILURE,
  GET_USER_INFO,
  GET_USER_INFO_SUCCESS,
  getBaseline as getBaselineAction,
  getUserAdditionalDataSuccess,
  getUserAdditionalDataFailure,
  updateUserBaselineSuccess,
  updateUserBaselineFailure,
  toggleUserEditModal,
} from "../modules/users";
import { GET_UPCOMING_BILLS_BY_HOME_ID } from "../modules/bills";
import {
  UserT,
  receiveUsersList,
  receiveUserById,
  deleteUserById,
  getBaseline,
  getUserEnergy,
  getUserEnergyFlow,
  setBaseline,
  changeSavingShare,
  getUserAdditionalData,
  getSavingShare,
} from "../../api/users";
import { CHANGED_ENERGY_REPORT_PERIOD } from "../modules/dashboard";
import { HIDE_ALERT, SHOW_ALERT } from "../modules/successAlert";

function* getUsersSaga() {
  try {
    const { data: users } = yield call(receiveUsersList);
    yield put({
      type: GET_USERS_LIST_SUCCESS,
      payload: { users, loaded: true },
    });
    const ids = users.map((item: UserT) => item.id);
    yield all(ids.map((id: string) => fork(getUserById, id)));
  } catch (error) {
    yield put({ type: GET_USERS_LIST_FAILURE, error });
  }
}

function* getUserById(id: string) {
  try {
    const { data } = yield call(receiveUserById, id);
    const users: Array<UserT> = yield select((state) => state.users.users);
    const updatedUsers = users.reduce((acc: Array<UserT>, curr: UserT) => {
      return [
        ...acc,
        curr.id === id ? { ...curr, ...data, uploaded: true } : curr,
      ];
    }, []);

    yield put({
      type: CHANGE_USERS_LIST,
      payload: { users: updatedUsers },
    });
  } catch (error) {
    yield put({ type: GET_USER_BY_ID_FAILURE, error });
    const users: Array<any> = yield select((state) => state.users.users);
    const updatedUsers = users.reduce((acc: Array<UserT>, curr: UserT) => {
      return [...acc, curr.id === id ? { ...curr, uploaded: true } : curr];
    }, []);

    yield put({
      type: CHANGE_USERS_LIST,
      payload: {
        users: updatedUsers,
      },
    });
  }
}

function* getUserByIdSaga(action: GetUserByIdT) {
  try {
    yield put({ type: GET_USER_BY_ID_SUCCESS /*, payload: { users } */ });
  } catch (error) {
    yield put({ type: GET_USER_BY_ID_FAILURE, error });
  }
}

function* deleteUserSaga(action: DeleteUserT) {
  try {
    yield call(deleteUserById, action.id);
    yield put({ type: DELETE_USER_SUCCESS });
    yield put({ type: GET_USERS_LIST });
  } catch (error) {
    yield put({ type: DELETE_USER_FAILURE, error });
  }
}

function* switchUserDetailSaga(action: SwitchUserDetailsT) {
  const { id, isUserDetailsOpen } = action;
  let selectedUser;

  if (id) {
    const users: Array<UserT> = yield select((state) => state.users.users);
    selectedUser = users.find((user: UserT) => user.id === id);
  } else {
    selectedUser = null;
  }

  yield put({
    type: SWITCHED_USER_DETAIL,
    payload: { selectedUser, isUserDetailsOpen },
  });
}

function* getBaselineSaga(action: GetBaselineT) {
  try {
    const { data } = yield call(getBaseline, action.homeId);
    const selectedUser: UserT = yield select((state) => state.users.selectedUser);

    yield put({
      type: GET_BASELINE_SUCCESS,
      payload: {
        selectedUser: {
          ...selectedUser,
          baseline: data,
        },
      },
    });
  } catch (error) {
    yield put({
      type: GET_BASELINE_FAILURE,
      error,
    });
  }
}

function* openUserEnergySaga() {
  yield put({ type: GET_USER_ENERGY_DATA, period: "today" });
  yield put({
    type: OPENED_USER_ENERGY,
    payload: {
      isEnergyModalOpen: true,
    },
  });
}

function* getUserEnergyDataSaga(action: GetUserEnergyDataT) {
  try {
    const openedId: string = yield select((state) => state.users.openedId);
    const { data: energy } = yield call(getUserEnergy, openedId, action.period);

    yield put({ type: CHANGED_ENERGY_REPORT_PERIOD, period: action.period });
    yield put({
      type: GET_USER_ENERGY_DATA_SUCCESS,
      payload: {
        energy,
      },
    });
  } catch (error) {
    yield put({ type: GET_USER_ENERGY_DATA_FAILURE, error });
  }
}

function* getUserEnergyDataByIdSaga(action: GetUserEnergyDataByIdT) {
  try {
    const { data: energy } = yield call(
      getUserEnergy,
      action.userId,
      action.period
    );

    yield put({ type: CHANGED_ENERGY_REPORT_PERIOD, period: action.period });
    yield put({
      type: GET_USER_ENERGY_DATA_SUCCESS,
      payload: {
        energy,
      },
    });
    yield put({
      type: GET_UPCOMING_BILLS_BY_HOME_ID,
      userId: action.userId,
    });
  } catch (error) {
    yield put({ type: GET_USER_ENERGY_DATA_FAILURE, error });
  }
}

function* getUserAdditionalDataSaga({ userId }: GetUserAdditionalDataRequestT) {
  try {
    const [
      {
        data: { elements: powerData },
      },
    ] = yield all([call(getUserEnergyFlow, userId)]);

    const { data: additional } = yield call(getUserAdditionalData, userId);

    yield put(getUserAdditionalDataSuccess({ additional, powerData }));
  } catch (error) {
    yield put(getUserAdditionalDataFailure(error));
  }
}

function* updateUserBaselineSaga({ homeId, data }: UpdateUserBaselineRequestT) {
  try {
    const { data: responseData } = yield call(setBaseline, homeId, data);
    yield put(updateUserBaselineSuccess(responseData));
  } catch (error) {
    yield put(updateUserBaselineFailure(error));
  }
}

function* openUserEditModalSaga({ homeId, isEdit }: OpenUserEditModalT) {
  try {
    yield put(getBaselineAction(homeId));
    const action: { type: string, error: any, payload: any } = yield take([GET_BASELINE_SUCCESS, GET_BASELINE_FAILURE]);
    if (action.type === GET_BASELINE_FAILURE) {
      throw action.error;
    }

    yield put(toggleUserEditModal(true, isEdit));
  } catch (error) {
    yield put(updateUserBaselineFailure(error));
  }
}

function* changeSavingShareSaga({
  homeId,
  savingShare,
  date,
}: ChangeSavingShareT) {
  try {
    yield call(changeSavingShare, homeId, savingShare, date);
    yield put({ type: CHANGE_SAVING_SHARE_SUCCESS });
    yield put({ type: SHOW_ALERT, message: "saving share changed!" });
    yield delay(1000);
    yield put({ type: HIDE_ALERT });
  } catch (error) {
    yield put({ type: CHANGE_SAVING_SHARE_FAILURE, error });
  }
}

function* getSavingShareSaga({ homeId }: GetSavingShareT) {
  try {
    //@ts-ignore
    const { data } = yield call(getSavingShare, homeId);
    yield put({ type: GET_SAVING_SHARE_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_SAVING_SHARE_FAILURE, error });
  }
}

function* getUserInfoSaga({ userId }: { userId: string }) {
  try {
    //@ts-ignore
    const { data } = yield call(receiveUserById, userId);
    yield put({ type: GET_USER_INFO_SUCCESS, data });
  } catch (error) {
    console.error(error);
  }
}

export default function* usersSaga() {
  yield takeEvery(GET_USERS_LIST, getUsersSaga);
  yield takeEvery(GET_USER_BY_ID, getUserByIdSaga);
  yield takeEvery(DELETE_USER, deleteUserSaga);
  yield takeEvery(SWITCH_USER_DETAIL, switchUserDetailSaga);
  yield takeEvery(GET_BASELINE, getBaselineSaga);
  yield takeEvery(OPEN_USER_ENERGY, openUserEnergySaga);
  yield takeEvery(GET_USER_ENERGY_DATA, getUserEnergyDataSaga);
  yield takeEvery(GET_USER_ENERGY_DATA_BY_ID, getUserEnergyDataByIdSaga);
  yield takeEvery(GET_USER_ADDITIONAL_DATA_REQUEST, getUserAdditionalDataSaga);
  yield takeEvery(UPDATE_USER_BASELINE_REQUEST, updateUserBaselineSaga);
  yield takeEvery(OPEN_USER_EDIT_MODAL, openUserEditModalSaga);
  yield takeEvery(CHANGE_SAVING_SHARE, changeSavingShareSaga);
  yield takeEvery(GET_SAVING_SHARE, getSavingShareSaga);
  //@ts-ignore
  yield takeEvery(GET_USER_INFO, getUserInfoSaga);
}
