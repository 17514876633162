import React, { useState, useEffect } from "react";
import cn from "classnames";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";
import { useMediaQuery } from "react-responsive";

import { PowerFlowItemT } from "../../api/dashboard";
import Icon from "./Icon";
import { ReactComponent as Devices } from "../../assets/icons/Devices.svg";
import colors from "./../../assets/style/variables/colors.scss";
import styles from "./PowerFlow.module.scss";

const data = [
  { name: "house", iconName: "house" },
  { name: "solar", iconName: "solar" },
  { name: "grid", iconName: "grid" },
];

export const computedColor: Record<string, string> = {
  house: "cyan",
  solar: "orange",
  grid: "dark-grey",
};

type PropsT = {
  data: Array<PowerFlowItemT> | null;
  isBig?: boolean;
};

function PowerFlow({ data: energyFlowData, isBig = false }: PropsT) {
  const [activeChart, setActiveChart] = useState("");
  const [ratio, setRatio] = useState(4 / 3);
  const selectChart = (name: string) => {
    if (activeChart === name) {
      return setActiveChart("");
    }
    setActiveChart(name);
  };

  const portrait = useMediaQuery({
    query: `(orientation: portrait)`,
  });

  useEffect(() => {
    if (!portrait) {
      setRatio(16 / 5);
      return;
    }

    setRatio(4 / 3);
  }, [portrait]);

  if (!energyFlowData) return null;

  return (
    <div className={cn(styles.blockWrapper, { [styles.smallChart]: !isBig })}>
      <div className={cn("row", styles.headerWrapper)}>
        <Devices className={styles.titleIcon} />
        <div className={styles.title}>
          Power Flow (last 24h - in kWh)
        </div>
        <div className={styles.iconsWrapper}>
          {data.map((item: { name: string; iconName: string }) => {
            return (
              <Icon
                key={item.name}
                type={item.name}
                name={item.iconName}
                activeChart={activeChart}
                selectChart={selectChart}
              />
            );
          })}
        </div>
      </div>
      <div className={styles.chartsWrapper}>
        <ResponsiveContainer height="100%" width="100%" aspect={ratio}>
          <LineChart
            data={energyFlowData}
            margin={{ left: -40, top: 0, right: 25 }}
          >
            <CartesianGrid
              horizontal={false}
              vertical={true}
              strokeDasharray="3 3"
            />
            <XAxis
              dataKey="label"
              axisLine={false}
              tick={{
                stroke: colors["grey"],
                strokeWidth: 0.5,
                fontWeight: 300,
                fontSize: 12,
              }}
              tickSize={0}
              tickFormatter={(time) => time === 0 ? 'now':`${time}h`}
              tickMargin={15}
              ticks={[-24, -20, -16, -12, -8, -4, 0]}
            />
            <YAxis
              axisLine={false}
              tick={{
                stroke: colors["grey"],
                strokeWidth: 0.5,
                fontWeight: 300,
                fontSize: 12,
              }}
              tickSize={0}
              tickMargin={10}
              minTickGap={1}
            />
            {data.map((item: { name: string }) => (
              <Line
                key={item.name}
                dataKey={item.name}
                dot={false}
                strokeWidth={2}
                stroke={
                  colors[
                    !activeChart.length || activeChart === item.name
                      ? computedColor[item.name]
                      : "swans-down"
                  ]
                }
              />
            ))}
            <ReferenceLine
              y={0}
              stroke={colors["swans-down"]}
              strokeDasharray="3 3"
            />

            <ReferenceLine
              x={0}
              strokeDasharray="3 3"
              strokeWidth={1}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default PowerFlow;
