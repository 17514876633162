import React from "react";
import { BLACK, GREY } from "../CircleChart/constants";
import { formatMoney } from "../../helpers/formatValues";
import colors from "../../assets/style/variables/colors.scss";

export const BLUE = colors["blue"];
export const ORANGE = colors["orange"];
export const LIGHTGREEN = colors["light-green"];

const strokeWidth = 28;
const radius = 30;
const perimeter = 2 * Math.PI * radius;
const calcDashOffset = (amount: number) =>
  perimeter - (perimeter * amount) / 100;
const rotation = (previousAmount: number) => (previousAmount * 360) / 100 - 90;

type PropsT = {
  svgRef?: (ref: any) => void;
  svgProps?: any | null;
  animation?: boolean;
  savingsAmount?: number;
  sprightfulSavings?: number;
  actualCharge?: number;
};

export default function PieChart(props: PropsT) {
  const { savingsAmount = 0, sprightfulSavings = 0, actualCharge = 0 } = props;
  const amount = savingsAmount + sprightfulSavings + actualCharge;
  const orangePercent = (savingsAmount / amount) * 100;
  const greenPercent = (actualCharge / amount) * 100;
  const bluePercent = (sprightfulSavings / amount) * 100;

  return (
    <>
      <svg width="800" height="200" viewBox="0 0 100 100">
        <g transform="translate(30)">
          <circle
            cx="50"
            cy="50"
            r={radius}
            fill="transparent"
            strokeWidth={strokeWidth}
            stroke={ORANGE}
            strokeDasharray={perimeter}
            strokeDashoffset={calcDashOffset(orangePercent)}
            transform={`rotate(${rotation(0)} 50 50)`}
          />
          <circle
            cx="50"
            cy="50"
            r={radius}
            fill="transparent"
            strokeWidth={strokeWidth}
            stroke={LIGHTGREEN}
            strokeDasharray={perimeter}
            strokeDashoffset={calcDashOffset(greenPercent)}
            transform={`rotate(${rotation(orangePercent)} 50 50)`}
          />
          <circle
            cx="50"
            cy="50"
            r={radius}
            fill="transparent"
            strokeWidth={strokeWidth}
            stroke={BLUE}
            strokeDasharray={perimeter}
            strokeDashoffset={calcDashOffset(bluePercent)}
            transform={`rotate(${rotation(
              orangePercent + greenPercent
            )} 50 50)`}
          />
        </g>

        <g transform="translate(20) scale(0.5, 0.5)">
          <g>
            <rect
              style={{ fill: ORANGE }}
              width="14"
              height="14"
              x="222.83344"
              y="24.93755"
              rx="4"
            />

            <text
              xmlSpace="preserve"
              style={{
                fontWeight: 600,
                fontSize: 14,
                lineHeight: 1.25,
                fill: BLACK,
                fontFamily: "Manrope",
              }}
              x="242.75659"
              y="34.997375"
            >
              {formatMoney(savingsAmount)}
            </text>

            <text
              xmlSpace="preserve"
              style={{
                fontWeight: 500,
                fontSize: 12,
                lineHeight: 1.25,
                fill: GREY,
                fontFamily: "Manrope",
              }}
              x="242.66841"
              y="55.204899"
            >
              Your Savings
            </text>
          </g>

          <g>
            <rect
              style={{ fill: LIGHTGREEN }}
              width="14"
              height="14"
              x="222.83344"
              y="85.750198"
              rx="4"
            />

            <text
              xmlSpace="preserve"
              style={{
                fontWeight: 600,
                fontSize: 14,
                lineHeight: 1.25,
                fill: BLACK,
                fontFamily: "Manrope",
              }}
              x="242.88127"
              y="94.733505"
            >
              {formatMoney(actualCharge)}
            </text>

            <text
              xmlSpace="preserve"
              style={{
                fontWeight: 500,
                fontSize: 12,
                lineHeight: 1.25,
                fill: GREY,
                fontFamily: "Manrope",
              }}
              x="242.74146"
              y="116.02226"
            >
              Actual Electric Bill
            </text>
          </g>

          <g>
            <rect
              style={{ fill: BLUE }}
              width="14"
              height="14"
              x="222.83344"
              y="152.76987"
              rx="4"
            />

            <text
              xmlSpace="preserve"
              style={{
                fontWeight: 600,
                fontSize: 14,
                lineHeight: 1.25,
                fill: BLACK,
                fontFamily: "Manrope",
              }}
              x="242.84476"
              y="162.62599"
            >
              {formatMoney(sprightfulSavings)}
            </text>

            <text
              xmlSpace="preserve"
              style={{
                fontWeight: 500,
                fontSize: 12,
                lineHeight: 1.25,
                fill: GREY,
                fontFamily: "Manrope",
              }}
              x="243.02109"
              y="183.20758"
            >
              Sprightful Share
            </text>
          </g>
        </g>
      </svg>
    </>
  );
}
