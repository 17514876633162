import React, { useState, useEffect, useCallback } from "react";
import distanceInWords from "date-fns/formatDistanceToNow";
import { useSelector } from "react-redux";

import { AppState } from "../../store/modules";
import { StateT as DashboardState } from "../../store/modules/dashboard";
import styles from "./UpdatesCounter.module.scss";

const UpdatesCounter = () => {
  const { energy, period } = useSelector<AppState, DashboardState>(
    (state) => state.dashboard
  );

  const calculateLastUpdate = useCallback(() => {
    //@ts-ignore
    const time = (period !== "week" && energy) ? energy?.lastUpdate : new Date();
    return distanceInWords(time);
  }, [energy, period]);

  const initialUpdate = calculateLastUpdate();
  const [lastUpdate, setLastUpdate] = useState<string>(initialUpdate);

  useEffect(() => {
    function counter() {
      const value = calculateLastUpdate();
      setLastUpdate(value);
    }

    counter(); // For change last update value when energy was updated

    let id = setInterval(counter, 60000); // Update title each 1 minute

    return () => clearInterval(id);
  }, [energy, calculateLastUpdate]);

  return (
    <div className={styles.updateTitle}>{`Last Update ${lastUpdate} ago`}</div>
  );
};

export default UpdatesCounter;
