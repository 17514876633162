import React from "react";
import { useField } from "formik";
import cx from "classnames";
import styles from "./ValidateMessage.module.scss";

type PropsT = {
  name: string;
  className?: string;
};

const ValidateMessage = (params: PropsT) => {
  const { name, className } = params;
  const [, meta] = useField(name);

  return <>{meta.touched && meta.error && <div className={cx(styles.element, className)}>{meta.error}</div>}</>;
};

export default ValidateMessage;
