import { InvitedUserT, BaselineT, InviteFriendT } from "../../api/users";

export const CREATE_USER = "CREATE_USER";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAILURE = "CREATE_USER_FAILURE";

export const INVITE_USER = "INVITE_USER";
export const INVITE_USER_SUCCESS = "INVITE_USER_SUCCESS";
export const INVITE_USER_FAILURE = "INVITE_USER_FAILURE";

export const RESEND_INVITE = "RESEND_INVITE";
export const RESEND_INVITE_SUCCESS = "RESEND_INVITE_SUCCESS";
export const RESEND_INVITE_FAILURE = "RESEND_INVITE_FAILURE";

export const INVITE_FRIEND_REQUEST = "INVITE_FRIEND_REQUEST";
export const INVITE_FRIEND_SUCCESS = "INVITE_FRIEND_SUCCESS";
export const INVITE_FRIEND_FAILURE = "INVITE_FRIEND_FAILURE";

export const SEND_BASE_LINE = "SEND_BASE_LINE";
export const SEND_BASE_LINE_SUCCESS = "SEND_BASE_LINE_SUCCESS";
export const SEND_BASE_LINE_FAILURE = "SEND_BASE_LINE_FAILURE";

export const TOGGLE_OPEN_INVITE_FRIEND_MODAL =
  "TOGGLE_OPEN_INVITE_FRIEND_MODAL";

export const SET_CAN_INVITE = "SET_CAN_INVITE";

export type CreateUserT = {
  type: typeof CREATE_USER;
} & NewUserT;

type CreateUserSuccessT = {
  type: typeof CREATE_USER_SUCCESS;
  payload: { invited: true };
};

type CreateUserFailureT = {
  type: typeof CREATE_USER_FAILURE;
  error: string;
};

export type InviteUserT = {
  type: typeof INVITE_USER;
  userData: InvitedUserT;
};

type InviteUserSuccessT = {
  type: typeof INVITE_USER_SUCCESS;
  homeId: string;
};

type InviteUserFailureT = {
  type: typeof INVITE_USER_FAILURE;
  payload: { inviteError: string };
};

export type SendBaseLineT = {
  type: typeof SEND_BASE_LINE;
  homeId: string;
  data: BaselineT;
};

type SendBaseLineSuccessT = {
  type: typeof SEND_BASE_LINE_SUCCESS;
};

type SendBaseLineFailureT = {
  type: typeof SEND_BASE_LINE_FAILURE;
  payload: { sendError: string };
};

type ToggleOpenInviteFriendModalT = {
  type: typeof TOGGLE_OPEN_INVITE_FRIEND_MODAL;
  isOpen: boolean;
};

export type NewUserT = {
  userData: InvitedUserT;
  data?: BaselineT;
};

export type InviteFriendRequestT = {
  type: typeof INVITE_FRIEND_REQUEST;
  data: InviteFriendT;
};

type InviteFriendSuccessT = {
  type: typeof INVITE_FRIEND_SUCCESS;
};

type InviteFriendFailureT = {
  type: typeof INVITE_FRIEND_FAILURE;
  error: string;
};

export type ResendInviteT = {
  type: typeof RESEND_INVITE;
  homeId: string;
};

type ResendInviteSuccessT = {
  type: typeof RESEND_INVITE_SUCCESS;
};

type ResendInviteFailureT = {
  type: typeof RESEND_INVITE_FAILURE;
  error: string;
};

export type SetCanInviteT = {
  type: typeof SET_CAN_INVITE;
  canInvite: boolean;
};

export type ActionTypeT =
  | CreateUserT
  | CreateUserSuccessT
  | CreateUserFailureT
  | InviteUserT
  | InviteUserSuccessT
  | InviteUserFailureT
  | SendBaseLineT
  | SendBaseLineSuccessT
  | SendBaseLineFailureT
  | ToggleOpenInviteFriendModalT
  | InviteFriendRequestT
  | InviteFriendSuccessT
  | InviteFriendFailureT
  | ResendInviteT
  | ResendInviteSuccessT
  | ResendInviteFailureT
  | SetCanInviteT;

export type StateT = {
  inviting: boolean;
  invited: boolean;
  error: string | null;
  inviteError: string | null;
  sendError: string | null;
  isModalOpen: boolean;
  canInvite: boolean;
};

const initialState: StateT = {
  inviting: false,
  invited: false,
  error: null,
  inviteError: null,
  sendError: null,
  isModalOpen: false,
  canInvite: false,
};

const inviting = {
  inviting: true,
  invited: false,
  inviteError: null,
  sendError: null,
};

const invited = {
  inviting: false,
  invited: true,
  canInvite: false,
};

const inviteError = {
  inviting: false,
  invited: false,
};

export default function usersReducer(
  state: StateT = initialState,
  action: ActionTypeT
): StateT {
  switch (action.type) {
    case CREATE_USER:
    case RESEND_INVITE:
    case INVITE_FRIEND_REQUEST:
      return { ...state, ...inviting };
    case CREATE_USER_SUCCESS:
    case RESEND_INVITE_SUCCESS:
    case INVITE_FRIEND_SUCCESS:
      return { ...state, ...invited };
    case INVITE_USER_FAILURE:
    case SEND_BASE_LINE_FAILURE:
      return { ...state, ...inviteError, ...action.payload };
    case TOGGLE_OPEN_INVITE_FRIEND_MODAL:
      return {
        ...state,
        isModalOpen: action.isOpen,
        inviting: false,
        invited: false,
        inviteError: null,
      };
    case INVITE_FRIEND_FAILURE:
    case RESEND_INVITE_FAILURE:
      return { ...state, ...inviteError, inviteError: action.error };
    case SET_CAN_INVITE:
      return {
        ...state,
        canInvite: action.canInvite,
      };
    case INVITE_USER_SUCCESS:
      return {
        ...state,
        invited: true,
      };

    default:
      return state;
  }
}

export function createUser({ userData, data }: NewUserT) {
  return {
    type: CREATE_USER,
    userData,
    data,
  };
}

export function inviteFriendRequest(data: InviteFriendT): InviteFriendRequestT {
  return { type: INVITE_FRIEND_REQUEST, data };
}

export function inviteFriendSuccess(): InviteFriendSuccessT {
  return { type: INVITE_FRIEND_SUCCESS };
}

export function inviteFriendFailure(error: string): InviteFriendFailureT {
  return { type: INVITE_FRIEND_FAILURE, error };
}

export function toggleOpenInviteFriendModal(isOpen: boolean) {
  return {
    type: TOGGLE_OPEN_INVITE_FRIEND_MODAL,
    isOpen,
  };
}

export function resendInvite(homeId: string) {
  return {
    type: RESEND_INVITE,
    homeId,
  };
}

export function setCanInvite(canInvite: boolean) {
  return {
    type: SET_CAN_INVITE,
    canInvite,
  };
}
