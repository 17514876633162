import React from "react";
import styles from "./Footer.module.scss";
import { ReactComponent as Home } from "../../assets/icons/Home.svg";
import { ReactComponent as Energy } from "../../assets/icons/Energy.svg";
import { ReactComponent as Wallet } from "../../assets/icons/Wallet.svg";
import { ReactComponent as Profile } from "../../assets/icons/Profile.svg";
import NavigationButton from "../NavigationButton/NavigationButton";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className="wrap">
        <div className="row">
          <div className="col-3">
            <NavigationButton title="Home" Icon={Home} link="/dashboard" />
          </div>
          <div className="col-3">
            <NavigationButton title="Energy" Icon={Energy} link="/energy" />
          </div>
          <div className="col-3">
            <NavigationButton title="Bills" Icon={Wallet} link="/bills" />
          </div>
          <div className="col-3">
            <NavigationButton title="Profile" Icon={Profile} link="/profile" />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
