import {
  PowerFlowItemT,
  EnergyT,
  WeeklyEnergyT,
  AdditionalDataT,
} from "../../api/dashboard";

export const GET_ADDITIONAL_DATA = "GET_ADDITIONAL_DATA";
export const GET_ADDITIONAL_DATA_SUCCESS = "GET_ADDITIONAL_DATA_SUCCESS";
export const GET_ADDITIONAL_DATA_FAILURE = "GET_ADDITIONAL_DATA_FAILURE";

export const GET_ENERGY_DATA = "GET_ENERGY_DATA";
export const GET_ENERGY_DATA_SUCCESS = "GET_ENERGY_DATA_SUCCESS";
export const GET_ENERGY_DATA_FAILURE = "GET_ENERGY_DATA_FAILURE";

export const CHANGE_ENERGY_REPORT_PERIOD = "CHANGE_ENERGY_REPORT_PERIOD";
export const CHANGED_ENERGY_REPORT_PERIOD = "CHANGED_ENERGY_REPORT_PERIOD";

export type GetAdditionalDataT = {
  type: typeof GET_ADDITIONAL_DATA;
};

type GetAdditionalDataSuccessT = {
  type: typeof GET_ADDITIONAL_DATA_SUCCESS;
  payload: any;
};

type GetAdditionalDataFailureT = {
  type: typeof GET_ADDITIONAL_DATA_FAILURE;
  error: string;
};

export type GetEnergyDataT = {
  type: typeof GET_ENERGY_DATA;
};

type GetEnergyDataSuccessT = {
  type: typeof GET_ENERGY_DATA_SUCCESS;
  payload: any;
};

type GetEnergyDataFailureT = {
  type: typeof GET_ENERGY_DATA_FAILURE;
  error: string;
};

export type ChangeEnergyReportPeriodT = {
  type: typeof CHANGE_ENERGY_REPORT_PERIOD;
  period: string;
};

type ChangedEnergyReportPeriodT = {
  type: typeof CHANGED_ENERGY_REPORT_PERIOD;
  period: string;
};

export type ActionTypeT =
  | GetAdditionalDataT
  | GetAdditionalDataSuccessT
  | GetAdditionalDataFailureT
  | GetEnergyDataT
  | GetEnergyDataSuccessT
  | GetEnergyDataFailureT
  | ChangeEnergyReportPeriodT
  | ChangedEnergyReportPeriodT;

export type StateT = {
  loading: boolean;
  error: string | null;
  additional: AdditionalDataT | null;
  powerData: Array<PowerFlowItemT> | null;
  energy: EnergyT | WeeklyEnergyT | null;
  period: string;
  score: null | number;
};

const initialState: StateT = {
  loading: false,
  error: null,
  additional: null,
  powerData: null,
  energy: null,
  period: "today",
  score: null,
};

export default function statisticsReducer(
  state: StateT = initialState,
  action: ActionTypeT
): StateT {
  switch (action.type) {
    case GET_ADDITIONAL_DATA:
    case GET_ENERGY_DATA:
      return { ...state, loading: true };
    case GET_ADDITIONAL_DATA_SUCCESS:
    case GET_ENERGY_DATA_SUCCESS:
      return { ...state, loading: false, ...action.payload };
    case GET_ADDITIONAL_DATA_FAILURE:
    case GET_ENERGY_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case CHANGED_ENERGY_REPORT_PERIOD:
      return {
        ...state,
        period: action.period,
      };

    default:
      return state;
  }
}

export function getAdditionalData() {
  return {
    type: GET_ADDITIONAL_DATA,
  };
}

export function getEnergyData() {
  return {
    type: GET_ENERGY_DATA,
  };
}

export function changeEnergyReportPeriod(period: string) {
  return {
    type: CHANGE_ENERGY_REPORT_PERIOD,
    period,
  };
}
