import React from "react";
import DesktopChart from "../DesktopChart/DesktopChart";
import DesktopWeeklyChart from "../DesktopWeeklyChart/DesktopWeeklyChart";
import DesktopMonthlyChart from "../DesktopMonthlyChart/DesktopMonthlyChart";
import StatisticsView from "../StatisticsView/StatisticsView";
import styles from "./DesktopEnergyBlock.module.scss";

type PropsT = {
  energy: any;
  period: string;
};

const DesktopEnergyBlock = (props: PropsT) => {
  const { energy, period } = props;

  function renderChart() {
    switch (period) {
      case "today":
      case "yesterday":
        return energy?.date ? <DesktopChart energy={energy} /> : <div />;
      case "week":
        return energy?.label === "This week" ? (
          <DesktopWeeklyChart energy={energy} />
        ) : (
          <div />
        );
      case "month":
        return energy?.label === "This month" ? (
          <DesktopMonthlyChart energy={energy} />
        ) : (
          <div />
        );
      default:
        return <div />;
    }
  }

  return (
    <div className={styles.blockWrapper}>
      {renderChart()}
      <StatisticsView
        savings={energy.totalSavings || energy.savings}
        generation={energy.totalGeneration}
        onPeakConsumption={energy.totalOnPeak || energy.totalOnPeakConsumption}
        offPeakConsumption={energy.totalOffPeak || energy.totalOffPeakConsumption}
        className={styles.verticalView}
      />
    </div>
  );
};

export default DesktopEnergyBlock;
