import { put, takeEvery, call } from "redux-saga/effects";
import {
  GetRatesT,
  GET_POWER_PROVIDERS,
  GET_POWER_PROVIDERS_SUCCESS,
  GET_POWER_PROVIDERS_FAILURE,
  GET_RATES,
  GET_RATES_SUCCESS,
  GET_RATES_FAILURE,
  GET_RATES_HISTORY,
  GetRatesHistoryT,
  GET_RATES_HISTORY_SUCCESS,
  GET_RATES_HISTORY_FAILURE,
} from "../modules/additionalInfo";
import {
  getPowerProviders,
  getRates,
  getUpdatedRateList,
} from "../../api/additionalInfo";

function* getPowerProvidersSaga() {
  try {
    const { data: powerProviders } = yield call(getPowerProviders);

    yield put({
      type: GET_POWER_PROVIDERS_SUCCESS,
      payload: {
        powerProviders,
      },
    });
  } catch (error) {
    yield put({ type: GET_POWER_PROVIDERS_FAILURE, error });
  }
}

function* getRatesSaga(action: GetRatesT) {
  try {
    const { data: rates } = yield call(getRates, action.id);

    yield put({
      type: GET_RATES_SUCCESS,
      payload: {
        rates,
      },
    });
  } catch (error) {
    yield put({ type: GET_RATES_FAILURE, error });
  }
}

function* getRatesHistory(action: GetRatesHistoryT) {
  try {
    const {
      data: { definitions },
    } = yield call(getUpdatedRateList, action.powerProviderId, action.rateId);
    yield put({
      type: GET_RATES_HISTORY_SUCCESS,
      payload: {
        definitions,
      },
    });
  } catch (error) {
    yield put({ type: GET_RATES_HISTORY_FAILURE, error });
  }
}

export default function* userSaga() {
  yield takeEvery(GET_POWER_PROVIDERS, getPowerProvidersSaga);
  yield takeEvery(GET_RATES, getRatesSaga);
  yield takeEvery(GET_RATES_HISTORY, getRatesHistory);
}
