export const GET_POWER_PROVIDERS = "GET_POWER_PROVIDERS";
export const GET_POWER_PROVIDERS_SUCCESS = "GET_POWER_PROVIDERS_SUCCESS";
export const GET_POWER_PROVIDERS_FAILURE = "GET_POWER_PROVIDERS_FAILURE";

export const GET_RATES = "GET_RATES";
export const GET_RATES_SUCCESS = "GET_RATES_SUCCESS";
export const GET_RATES_FAILURE = "GET_RATES_FAILURE";

export const GET_RATES_HISTORY = "GET_RATES_HISTORY";
export const GET_RATES_HISTORY_SUCCESS = "GET_RATES_HISTORY_SUCCESS";
export const GET_RATES_HISTORY_FAILURE = "GET_RATES_HISTORY_FAILURE";

export const SHOW_TABLE = "SHOW_TABLE";

export type GetPowerProvidersT = {
  type: typeof GET_POWER_PROVIDERS;
};

type GetPowerProvidersSuccessT = {
  type: typeof GET_POWER_PROVIDERS_SUCCESS;
  payload: { powerProviders: Array<Record<string, string>> | null };
};

type GetPowerProvidersFailureT = {
  type: typeof GET_POWER_PROVIDERS_FAILURE;
  error: string;
};

export type GetRatesT = {
  type: typeof GET_RATES;
  id: string;
};

export type ShowTableT = {
  type: typeof SHOW_TABLE;
  showTable: boolean;
};

export type GetRatesHistoryT = {
  type: typeof GET_RATES_HISTORY;
  powerProviderId: string;
  rateId: string;
};

type GetRatesSuccessT = {
  type: typeof GET_RATES_SUCCESS;
  payload: { rates: Array<Record<string, string>> | null };
};

type GetRatesFailureT = {
  type: typeof GET_RATES_FAILURE;
  error: string;
};

type GetRatesHistorySuccessT = {
  type: typeof GET_RATES_HISTORY_SUCCESS;
  payload: { definitions: Array<any> | null };
};

type GetRatesHistoryFailureT = {
  type: typeof GET_RATES_HISTORY_FAILURE;
  error: string;
};

export type ActionTypeT =
  | GetPowerProvidersT
  | GetPowerProvidersSuccessT
  | GetPowerProvidersFailureT
  | GetRatesT
  | GetRatesHistoryT
  | GetRatesHistorySuccessT
  | GetRatesHistoryFailureT
  | GetRatesSuccessT
  | ShowTableT
  | GetRatesFailureT;

export type StateT = {
  loading: boolean;
  error: string | null;
  powerProviders: Array<Record<string, string>> | null;
  rates: Array<Record<string, string>> | null;
  updatedRates: any[] | null;
  showTable: boolean;
};

const initialState: StateT = {
  loading: false,
  error: null,
  powerProviders: null,
  rates: null,
  updatedRates: null,
  showTable: false,
};

export default function additionalInfoReducer(
  state: StateT = initialState,
  action: ActionTypeT
): StateT {
  switch (action.type) {
    case GET_POWER_PROVIDERS:
    case GET_RATES:
      return { ...state, loading: true };
    case SHOW_TABLE:
      return { ...state, showTable: action.showTable };
    case GET_POWER_PROVIDERS_SUCCESS:
    case GET_RATES_SUCCESS:
      return { ...state, loading: false, ...action.payload };
    case GET_RATES_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        updatedRates: action.payload.definitions,
      };
    case GET_RATES_HISTORY:
      return { ...state, loading: true };
    case GET_POWER_PROVIDERS_FAILURE:
    case GET_RATES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case GET_RATES_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function getPowerProviders() {
  return {
    type: GET_POWER_PROVIDERS,
  };
}

export function getRates(id: string) {
  return {
    type: GET_RATES,
    id,
  };
}

export function showRatesHistory(showTable: boolean) {
  return {
    type: SHOW_TABLE,
    showTable,
  };
}

export function getUpdatedRatesHistory(
  powerProviderId: string,
  rateId: string
) {
  return {
    type: GET_RATES_HISTORY,
    powerProviderId,
    rateId,
  };
}
