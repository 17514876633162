import React from "react";
import Select from "react-select";

import { OptionT, timeOptions } from "../../constants";
import NumericValueFormat from "../NumericValueFormat/NumericValueFormat";
import { selectFieldStyle } from "./SelectFieldStype";
import styles from "./DashboardTitle.module.scss";

type PropsT = {
  title: string;
  onChange: (option: OptionT) => void;
  selectedValue?: OptionT;
  valueDisplay?: number;
};

export default function Title(props: PropsT) {
  const { title, onChange, selectedValue, valueDisplay } = props;

  return (
    <div className={styles.dashboardTitle}>
      {title}
      {valueDisplay ? (
          <NumericValueFormat value={valueDisplay} isMoney />
      ): <span /> }
      <Select
        name="name"
        options={timeOptions}
        //@ts-ignore
        onChange={onChange}
        value={selectedValue}
        styles={selectFieldStyle}
        isSearchable={false}
      />
    </div>
  );
}
