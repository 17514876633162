/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useMediaQuery } from "react-responsive";
import cx from "classnames";
import Nomination from "../Nomination/Nomination";
import colors from "./../../assets/style/variables/colors.scss";
import breakpoints from "../../variables.scss";
import * as styles from "./StatisticsView.module.scss";
import { useSelector } from "react-redux";
import { AppState } from "../../store/modules";
import { StateT as AuthState } from "../../store/modules/authorization";
import { StateT as userState } from "../../store/modules/users";

type PropsT = {
  savings?: number;
  generation?: number;
  consumption?: number;
  onPeakConsumption?: number;
  offPeakConsumption?: number;
  className?: string;
};

const StatisticsView = (props: PropsT) => {
  const { currentRate } = useSelector<AppState, AuthState>(
    (state) => state.authorization
  );

  const {isFlat} = useSelector<AppState, userState>(
    (state) => state.users
  );

  const {
    generation = 0,
    savings = 0,
    offPeakConsumption = 0,
    onPeakConsumption = 0,
    className,
  } = props;
  const isResponsiveView = useMediaQuery({
    query: `(max-width: ${breakpoints["tablet"]}px)`,
  });

  if (isResponsiveView) {
    return (
      <div className={styles.mobileWrapper}>
        <div>
          <Nomination name="Net Savings" number={savings} isMoney />
          <Nomination
            name="Solar"
            suffix="kWh"
            number={generation}
            color={colors["orange"]}
          />
        </div>
        {(currentRate?.flat || isFlat) ? (
          <div>
            <Nomination
              name="Consumption"
              suffix="kWh"
              number={onPeakConsumption + offPeakConsumption}
              color={colors["cyan"]}
            />
          </div>
        ) : (
          <div>
            <Nomination
              name="Off-Peak"
              suffix="kWh"
              number={offPeakConsumption}
              color={colors["cyan"]}
            />
            <Nomination
              name="On-Peak"
              suffix="kWh"
              number={onPeakConsumption}
              color={colors["dark-ocean"]}
            />
          </div>
        )}
      </div>
    );
  }
  return (
    <div className={cx(styles.wrapper, className)}>
      <Nomination name="Net Savings" number={savings} isMoney />
      <Nomination
        name="Solar"
        number={generation}
        suffix="kWh"
        color={colors["orange"]}
      />
      {(currentRate?.flat || isFlat) ? (
        <Nomination
          name="Consumption"
          number={offPeakConsumption + onPeakConsumption}
          suffix="kWh"
          color={colors["cyan"]}
        />
      ) : (
        <>
          <Nomination
            name="Off-Peak"
            number={offPeakConsumption}
            suffix="kWh"
            color={colors["cyan"]}
          />
          <Nomination
            name="On-Peak"
            number={onPeakConsumption}
            suffix="kWh"
            color={colors["dark-ocean"]}
          />
        </>
      )}
    </div>
  );
};

export default StatisticsView;
