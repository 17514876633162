import React from "react";
import cx from "classnames";
import { FieldProps } from "formik";
import Select from "react-select";

import ValidateMessage from "../ValidateMessage/ValidateMessage";
import { selectFieldStyle } from "./selectFieldStyle.module";
import styles from "./SelectField.module.scss";

type PropsT = {
  label?: string | null;
  placeholder?: string;
  className?: string;
  name: string;
  onBlur?: () => void;
  hasValidationMessage?: boolean;
  options: any;
  handleChangeCallback?: (id: number) => void;
  defaultValue: any;
  disabled?: boolean;
} & FieldProps;

const SelectField = (props: PropsT) => {
  const {
    onBlur,
    form,
    field,
    placeholder,
    defaultValue,
    disabled,
    options,
    label,
    className,
    hasValidationMessage = true,
    handleChangeCallback,
  } = props;
  const { name } = field;

  field.onBlur = () => {
    if (onBlur) {
      onBlur();
    }
  };

  const onChange = (option: any) => {
    form.setFieldValue(name, option);
    handleChangeCallback && handleChangeCallback(option.value);
  };

  return (
    <div className={cx(styles.element, className)}>
      <label className={styles.content} htmlFor={field.name}>
        {label && <p className={styles.label}>{label}</p>}

        <div className={styles.inputContent}>
          <Select
            name={name}
            options={options}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
            styles={selectFieldStyle}
            isSearchable={false}
            menuPortalTarget={document.body}
            defaultValue={defaultValue}
            isDisabled={disabled}
          />
        </div>
      </label>

      {hasValidationMessage && <ValidateMessage name={name} />}
    </div>
  );
};

export default SelectField;
