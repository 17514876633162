import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import cx from "classnames";

import { AppState } from "../../store/modules";
import { logOut } from "../../store/modules/authorization";
// import { toggleOpenInviteFriendModal } from "../../store/modules/inviteUser";
import { apiURL } from "../../api/axiosConfig";

import { ReactComponent as Profile } from "../../assets/icons/ProfileGray.svg";
// import { ReactComponent as Envelope } from "../../assets/icons/EnvelopeGray.svg";
import { ReactComponent as LogOut } from "../../assets/icons/LogOut.svg";
import { ReactComponent as ChevronIcon } from "../../assets/icons/Chevron.svg";
import styles from "./ProfileDrawer.module.scss";

const url = apiURL();

const ProfileDrawer = () => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [isOpen, setOpen] = useState(false);
  const { name, photoLink, admin } = useSelector(
    (state: AppState) => state.authorization
  );

  useEffect(() => {
    document.addEventListener("click", clickListener);
    return () => {
      document.removeEventListener("click", clickListener);
    };
  }, []);

  const photoStyle: React.CSSProperties = photoLink
    ? { backgroundImage: `url(${url}${photoLink})` }
    : {};

  function clickListener(event: MouseEvent) {
    if (!(ref.current! as any).contains(event.target)) {
      setOpen(false);
    }
  }

  function handleOpenMenu() {
    setOpen(!isOpen);
  }

  function goToProfile() {
    setOpen(false);
    history.push("/profile");
  }

  // function handleOpenInviteModal() {
  //   setOpen(false);
  //   dispatch(toggleOpenInviteFriendModal(true));
  // }

  function handleLogOut() {
    dispatch(logOut());
    setOpen(false);
  }

  return (
    <div className={styles.content} ref={ref}>
      <button className={styles.button} type="button" onClick={handleOpenMenu}>
        <div className={styles.photo} style={photoStyle} />
        <div className={styles.body}>
          <p className={styles.title}>{name}</p>
          {admin && <p className={styles.subtitle}>Admin</p>}
        </div>
        <div className={cx(styles.chevron, isOpen && styles.rotate)}>
          <ChevronIcon className={styles.chevronIcon} />
        </div>
      </button>
      {isOpen && (
        <div className={styles.menu}>
          {!admin && (
            <>
              <button onClick={goToProfile}>
                <div className={styles.iconWrapper}>
                  <Profile />
                </div>
                Profile
              </button>
              {/* <button onClick={handleOpenInviteModal}>
                <div className={styles.iconWrapper}>
                  <Envelope />
                </div>
                Invite Friend
              </button> */}
            </>
          )}
          <button onClick={handleLogOut}>
            <div className={styles.iconWrapper}>
              <LogOut />
            </div>
            Log Out
          </button>
        </div>
      )}
    </div>
  );
};

export default ProfileDrawer;
