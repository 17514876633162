import React from "react";
import styles from "./NavigationButton.module.scss";
import { NavLink } from "react-router-dom";

type PropsT = {
  title: string;
  link: string;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

const NavigationButton = (props: PropsT) => {
  const { title, link, Icon } = props;

  return (
    <NavLink to={link} className={styles.element} activeClassName={styles.isActive}>
      <div className={styles.header}>
        <Icon className={styles.icon} />
      </div>
      <p className={styles.title}>{title}</p>
    </NavLink>
  );
};

export default NavigationButton;
