import React, { ReactNode } from "react";
import cn from "classnames";
import NumericValueFormat from "../NumericValueFormat/NumericValueFormat";
import { ReactComponent as Calendar } from "../../assets/icons/Calendar.svg";
import styles from "./DesktopNomination.module.scss";

type PropsT = {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  title: string;
  dates?: string;
  bill: number;
  children: ReactNode;
  subtitle: string;
};

export default function DesktopNomination(props: PropsT) {
  const { Icon, title, dates, bill, children, subtitle } = props;
  return (
    <div className={styles.nominationWrapper}>
      <div className={styles.headline}>
      <Icon className={styles.icon} />
      <span>{title}</span>
      </div>
      <div className={cn(styles.headerWrapper, "row")}>
        <div>
          <div className={styles.title}>{subtitle}</div>
          {dates ? (
            <div className={styles.dates}>
              <Calendar />
              {dates}
            </div>
          ) : (<div></div>)}
        </div>
        <div className={styles.bill}>
          <NumericValueFormat value={bill} isMoney />
        </div>
      </div>
      <div className={styles.descriptionBlock}>{children}</div>
    </div>
  );
}
