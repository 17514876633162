import Styles from "react-select";
import colors from "./../../assets/style/variables/colors.scss";

export const selectFieldStyle: Partial<Styles> = {
  control: (style: any, state: any) => {
    const { isFocused } = state;

    return {
      cursor: "pointer",
      position: "relative",
      width: "100%",
      padding: "9px 44px 9px 16px",
      overflow: "hidden",
      transition: "border-color 0.2s",
      border: `1px solid ${colors["botticelli"]}`,
      borderRadius: `4px 4px ${isFocused ? "0px 0px" : "4px 4px"}`,
      outline: "0",
      fontSize: 14,
      letterSpacing: 0.1,
      lineHeight: "20px",
    };
  },
  indicatorsContainer: () => {
    return {
      position: "absolute",
      top: 0,
      right: 0,
      width: 44,
      height: "100%",
    };
  },
  dropdownIndicator: (style: any, state: any) => {
    const { isFocused } = state;

    return {
      transition: "transform .2s",
      height: "100%",
      width: 44,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      transform: `rotate(${isFocused ? "180deg" : "0deg"})`,
    };
  },
  indicatorSeparator: () => {
    return {
      display: "none",
    };
  },
  placeholder: () => {
    return {
      position: "absolute",
      top: "50%",
      left: 0,
      transform: "translateY(-50%)",
    };
  },
  container: () => ({
    display: "flex",
    position: "relative",
    fontWeight: 600,
    fontSize: 14,
    lineHeight: "20px",
    letterSpacing: 0.1,
    color: colors["blue-zodiac"],
    zIndex: 999,
  }),
  singleValue: () => ({}),
  input: () => ({}),
  menu: () => {
    return {
      border: `1px solid ${colors["botticelli"]}`,
      borderTop: 0,
      position: "absolute",
      cursor: "pointer",
      left: 0,
      top: "100%",
      width: "100%",
      maxHeight: 160,
      zIndex: 9999999,
      margin: 0,
      background: colors["white"],
    };
  },
  valueContainer: () => {
    return {
      display: "flex",
      position: "relative",
      minHeight: 20,
    };
  },
  menuList: () => ({}),
  menuPortal: (style: any) => ({
    ...style,
    zIndex: 9999,
  }),

  option: (style: any, state: any) => {
    const { isSelected, isFocused } = state;

    const background = () => {
      if (isSelected) {
        return colors["blue"];
      }

      if (isFocused) {
        return `${colors["blue"]}40`;
      }

      return colors["white"];
    };

    const color = () => {
      if (isSelected) {
        return colors["white"];
      }

      return colors["black"];
    };

    return {
      fontWeight: 600,
      cursor: "pointer",
      overflow: "hidden",
      fontSize: 14,
      letterSpacing: 0.1,
      padding: "10px 16px",
      lineHeight: "20px",
      transition: "background 0.2s, color 0.2s",
      color: color(),
      background: background(),
    };
  },
};
