import React from "react";
import { isMobileOnly } from "react-device-detect";
import { useSelector } from "react-redux";
import { StateT as AuthState } from "../../store/modules/authorization";
import { CellProps as CellPropsT, Row as RowT } from "react-table";

import TextWithDate from "../TextWithDate/TextWithDate";
import Table from "../PlainTable/Table";
import getArrowCodeAndClassName from "../../helpers/getArrowCodeAndClassName";
import NumericValueFormat from "../NumericValueFormat/NumericValueFormat";
import { StateT as userState } from "../../store/modules/users";
import { AppState } from "../../store/modules";
import { StateT as BillsState } from "../../store/modules/bills";

type YearConsumptionRowT = {
  name: string;
  prevYear: string;
  currentYear: string;
  difference: number;
  change: number;
};

function renderChange(value: number) {
  const { arrow, className } = getArrowCodeAndClassName(value);
  return (
    <span>
      {value === 0 ? (
        "-"
      ) : (
        <>
          <span className={className}>{arrow}</span>&nbsp;
          {Math.abs(value * 100).toFixed(1)}%
        </>
      )}
    </span>
  );
}

const calculatedOptions = (name: string) =>
  name === "Savings" ? { isMoney: true } : { suffix: " kWh" };

const renderCurrentValue = (row: RowT<any>) => {
  const options = calculatedOptions(row.original.name);
  return (
    <NumericValueFormat
      value={row.original.currentValue}
      decimalScale={0}
      {...options}
    />
  );
};

const renderPreviousValue = (row: RowT<any>) => {
  const options = calculatedOptions(row.original.name);
  return (
    <NumericValueFormat
      value={row.original.previousValue}
      decimalScale={0}
      {...options}
    />
  );
};

const getLastYearComparisonColumns = (
  isMobile: boolean,
  previousYear: string,
  currentYear: string
) => {
  if (isMobile) {
    return [
      {
        Header: "",
        accessor: "name",
        Cell: ({ row, value }: CellPropsT<YearConsumptionRowT>) => (
          <>
            {value} {renderChange(row.original.change)}
          </>
        ),
      },
      {
        Header: previousYear,
        accessor: "previousValue",
        Cell: ({ row }: CellPropsT<any>) => renderPreviousValue(row),
      },
      {
        Header: currentYear,
        accessor: "currentValue",
        Cell: ({ row }: CellPropsT<any>) => renderCurrentValue(row),
      },
    ];
  }

  return [
    {
      Header: "",
      accessor: "name",
    },
    {
      Header: previousYear || "",
      accessor: "previousValue",
      Cell: ({ row }: CellPropsT<any>) => renderPreviousValue(row),
    },
    {
      Header: currentYear || "",
      accessor: "currentValue",
      Cell: ({ row }: CellPropsT<any>) => renderCurrentValue(row),
    },
    {
      Header: "Difference",
      accessor: "change",
      Cell: ({ value }: CellPropsT<YearConsumptionRowT>) => renderChange(value),
    },
  ];
};

export default function LastYearComparison() {
  const { comparison } = useSelector<AppState, BillsState>(
    (state) => state.bills
  );
  const {isFlat} = useSelector<AppState, userState>(
    (state) => state.users
  );
  const { currentRate } = useSelector<AppState, AuthState>(
    (state) => state.authorization
  );

  if (!comparison) return <div />;

  const {
    previousYear,
    currentYear,
    onPeak,
    offPeak,
    savings,
    generation,
  } = comparison;

  const columns = getLastYearComparisonColumns(
    isMobileOnly,
    previousYear,
    currentYear
  );

  function sumObjectsByKey(...peaks: any) {
    return peaks.reduce((a: any, b: any) => {
      for (let k in b) {
        if (b.hasOwnProperty(k)) a[k] = (a[k] || 0) + b[k];
      }
      return a;
    }, {});
  }

  const consumption = !currentRate?.flat && !isFlat
    ? [
        {
          name: "On-Peak Consumption",
          ...onPeak,
        },
        {
          name: "Off-Peak Consumption",
          ...offPeak,
        },
      ]
    : [{ name: "Total Consumption", ...sumObjectsByKey(onPeak, offPeak) }];

  const transformedData = [
    ...consumption,
    {
      name: "Savings",
      ...savings,
    },
    {
      name: "Generation",
      ...generation,
    },
  ];

  return (
    <>
      <TextWithDate
        title="Last Year Comparison"
        date={`${previousYear || ""} - ${currentYear || ""}`}
      />
      <Table columns={columns} data={transformedData} />
    </>
  );
}
