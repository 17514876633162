import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { History } from "history";
import authorization, { ActionTypeT, LOGOUT_SUCCESS } from "./authorization";
import users from "./users";
import additionalInfo from "./additionalInfo";
import dashboard from "./dashboard";
import paymentDetails from "./paymentDetails";
import inviteUser from "./inviteUser";
import bills from "./bills";
import successAlert from "./successAlert";

const createAppReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    authorization,
    users,
    additionalInfo,
    dashboard,
    paymentDetails,
    inviteUser,
    bills,
    successAlert,
  });

type AppReducerT = ReturnType<typeof createAppReducer>;
export type AppState = ReturnType<AppReducerT>;

const initialState: AppState = {} as AppState;

let appReducer: AppReducerT;

export default function (history: History) {
  if (!appReducer) {
    appReducer = createAppReducer(history);
  }

  return function (
    state: AppState = initialState,
    action: ActionTypeT
  ): AppState {
    if (action.type === LOGOUT_SUCCESS) {
      return appReducer(undefined, action);
    }

    return appReducer(state, action);
  };
}
