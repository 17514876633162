import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { parse } from "papaparse";
import cx from "classnames";
import { useSelector, useDispatch } from "react-redux";

import Button from "../Button/Button";
import validateCSV from "../../helpers/validateCSV";
import { months } from "../BaselineForm/constants";
import { AppState } from "../../store/modules";
import {
  StateT as InviteUsersState,
  setCanInvite,
} from "../../store/modules/inviteUser";
import LinearLoader from "../LinearLoader/LinearLoader";

import { ReactComponent as CSVIcon } from "./../../assets/icons/CSV.svg";
import { ReactComponent as CSVError } from "./../../assets/icons/CSVError.svg";
import styles from "./CSVLoader.module.scss";

const monthsArray = Object.keys(months);
const baseLineValues = ["baseline", "onPeak", "offPeak", "generation"];

type PropsT = {
  setBaselineData: any;
};

export default function CSVLoader(props: PropsT) {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [progress, setProgress] = useState(0);
  const { setBaselineData } = props;
  const { canInvite } = useSelector<AppState, InviteUsersState>(
    (state: AppState) => state.inviteUser
  );

  function clearUploading() {
    setProgress(0);
    setError("");
    canInvite && dispatch(setCanInvite(false));
  }

  function throwError() {
    setProgress(100);
    setError("Wrong file format");
  }

  async function onDrop(acceptedFiles: Array<File>) {
    clearUploading();
    setProgress(50);

    const baseline: any = {
      baseline: {},
      onPeak: {},
      offPeak: {},
      generation: {},
    };

    Array.from(acceptedFiles).forEach(async (file) => {
      const text = await file.text();
      const result = parse(text);
      const formatedResult = result.data;

      const isValid = validateCSV(formatedResult);
      if (isValid) {
        dispatch(setCanInvite(true));
        const [
          january,
          february,
          march,
          april,
          may,
          june,
          july,
          august,
          september,
          october,
          november,
          december,
        ] = formatedResult;

        const data: any = [
          january,
          february,
          march,
          april,
          may,
          june,
          july,
          august,
          september,
          october,
          november,
          december,
        ];

        data.forEach((itm: string, idx: number) => {
          baseLineValues.forEach((item: string, index: number) => {
            baseline[item][monthsArray[idx]] = +itm[index];
          });
        });
        setProgress(100);
        setBaselineData(baseline);
      } else {
        throwError();
      }
    });
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected: throwError,
    // @ts-ignore
    accept: "text/csv",
  });
  const { onClick: selectFile, ...dragNDropProps } = getRootProps();
  return (
    <div
      {...dragNDropProps}
      className={cx(styles.dropZone, { [styles.error]: error })}
    >
      <LinearLoader completed={progress} />
      <div className={styles.mainBlock}>
        {error ? <CSVError /> : <CSVIcon />}
        <div className={styles.uploadTitle}>
          Drop your .csv file here or press
          <br />
          the button below
        </div>
        {error && <div className={styles.errorMessage}>{error}</div>}
        <input {...getInputProps()} multiple={false} />
        <Button
          onClick={selectFile}
          variant="outlined"
          className={styles.uploadButton}
        >
          Upload CSV
        </Button>
      </div>
    </div>
  );
}
