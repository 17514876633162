import { UserT, BaselineT, SavingShareT } from "../../api/users";
import { AdditionalDataT, PowerFlowItemT } from "../../api/dashboard";

export const GET_USERS_LIST = "GET_USERS_LIST";
export const GET_USERS_LIST_SUCCESS = "GET_USERS_LIST_SUCCESS";
export const GET_USERS_LIST_FAILURE = "GET_USERS_LIST_FAILURE";

export const CHANGE_USERS_LIST = "CHANGE_USERS_LIST";

export const GET_USER_BY_ID = "GET_USER_BY_ID";
export const GET_USER_BY_ID_SUCCESS = "GET_USER_BY_ID_SUCCESS";
export const GET_USER_BY_ID_FAILURE = "GET_USER_BY_ID_FAILURE";

export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

export const SWITCH_USER_DETAIL = "SWITCH_USER_DETAIL";
export const SWITCHED_USER_DETAIL = "SWITCHED_USER_DETAIL";

export const GET_BASELINE = "GET_BASELINE";
export const GET_BASELINE_SUCCESS = "GET_BASELINE_SUCCESS";
export const GET_BASELINE_FAILURE = "GET_BASELINE_FAILURE";

export const OPEN_USER_ENERGY = "OPEN_USER_ENERGY";
export const OPENED_USER_ENERGY = "OPENED_USER_ENERGY";

export const CLOSE_USER_ENERGY = "CLOSE_USER_ENERGY";

export const GET_USER_ENERGY_DATA_BY_ID = "GET_USER_ENERGY_DATA_BY_ID";

export const GET_USER_ENERGY_DATA = "GET_USER_ENERGY_DATA";
export const GET_USER_ENERGY_DATA_SUCCESS = "GET_USER_ENERGY_DATA_SUCCESS";
export const GET_USER_ENERGY_DATA_FAILURE = "GET_USER_ENERGY_DATA_FAILURE";

export const GET_USER_ADDITIONAL_DATA_REQUEST = "GET_USER_STATISTICS_REQUEST";
export const GET_USER_ADDITIONAL_DATA_SUCCESS = "GET_USER_STATISTICS_SUCCESS";
export const GET_USER_ADDITIONAL_DATA_FAILURE = "GET_USER_STATISTICS_FAILURE";

export const UPDATE_USER_BASELINE_REQUEST = "UPDATE_USER_BASELINE_REQUEST";
export const UPDATE_USER_BASELINE_SUCCESS = "UPDATE_USER_BASELINE_SUCCESS";
export const UPDATE_USER_BASELINE_FAILURE = "UPDATE_USER_BASELINE_FAILURE";

export const RESET_UPDATE_BASELINE_STATE = "RESET_UPDATE_BASELINE_STATE";

export const OPEN_USER_EDIT_MODAL = "OPEN_USER_EDIT_MODAL";

export const RESET_USER_DASHBOARD = "RESET_USER_DASHBOARD";

export const TOGGLE_USER_EDIT_MODAL = "TOGGLE_USER_EDIT_MODAL";

export const CHANGE_SAVING_SHARE = "CHANGE_SAVING_SHARE";
export const CHANGE_SAVING_SHARE_SUCCESS = "CHANGE_SAVING_SHARE_SUCCESS";
export const CHANGE_SAVING_SHARE_FAILURE = "CHANGE_SAVING_SHARE_FAILURE";

export const GET_SAVING_SHARE = "GET_SAVING_SHARE";
export const GET_SAVING_SHARE_SUCCESS = "GET_SAVING_SHARE_SUCCESS";
export const GET_SAVING_SHARE_FAILURE = "GET_SAVING_SHARE_FAILURE";

export const SWITCH_DELETE_MODAL = "SWITCH_DELETE_MODAL";

export const SWITCH_SAVING_SHARE_MODAL = "SWITCH_SAVING_SHARE_MODAL";

export const SWITCH_ADDITIONAL_SERVICES_MODAL = "SWITCH_ADDITIONAL_SERVICES_MODAL";

export const SWITCH_REFERRAL_LINK_MODAL = "SWITCH_REFERRAL_LINK_MODAL";

export const SWITCH_RATE_MODAL = "SWITCH_RATE_MODAL";

export const GET_USER_INFO = "GET_USER_INFO";
export const GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS";

export type GetUsersT = {
  type: typeof GET_USERS_LIST;
};

type GetUsersSuccessT = {
  type: typeof GET_USERS_LIST_SUCCESS;
  payload: any;
};

type GetUsersFailureT = {
  type: typeof GET_USERS_LIST_FAILURE;
  error: string;
};

export type GetUserByIdT = {
  type: typeof GET_USER_BY_ID;
  id: string;
};

type GetUserByIdSuccessT = {
  type: typeof GET_USER_BY_ID_SUCCESS;
  payload: any;
};

type GetUserByIdFailureT = {
  type: typeof GET_USER_BY_ID_FAILURE;
  error: string;
};

type ChangeUsersListT = {
  type: typeof CHANGE_USERS_LIST;
  payload: any;
};

export type DeleteUserT = {
  type: typeof DELETE_USER;
  id: string;
};

type DeleteUserSuccessT = {
  type: typeof DELETE_USER_SUCCESS;
  payload: string;
};

type DeleteUserFailureT = {
  type: typeof DELETE_USER_FAILURE;
  error: string;
};

type UserDetailsT = {
  isUserDetailsOpen: boolean;
  id?: string | null;
};

export type SwitchUserDetailsT = {
  type: typeof SWITCH_USER_DETAIL;
} & UserDetailsT;

type SwitchedUserDetailsT = {
  type: typeof SWITCHED_USER_DETAIL;
  payload: { selectedUser: UserT | null };
};

export type GetBaselineT = {
  type: typeof GET_BASELINE;
  homeId: string;
};

type GetBaselineSuccessT = {
  type: typeof GET_BASELINE_SUCCESS;
  payload: { selectedUser: UserT | null };
};

type GetBaselineFailureT = {
  type: typeof GET_BASELINE_FAILURE;
  error: string;
};

export type OpenUserEnergyT = {
  type: typeof OPEN_USER_ENERGY;
  id: string;
};

type OpenedUserEnergyT = {
  type: typeof OPENED_USER_ENERGY;
  payload: { isEnergyModalOpen: true };
};

type CloseUserEnergyT = {
  type: typeof CLOSE_USER_ENERGY;
};

export type GetUserEnergyDataByIdT = {
  type: typeof GET_USER_ENERGY_DATA_BY_ID;
  userId: string;
  period: string;
};

export type GetUserEnergyDataT = {
  type: typeof GET_USER_ENERGY_DATA;
  period: string;
};

export type GetUserEnergyDataSuccessT = {
  type: typeof GET_USER_ENERGY_DATA_SUCCESS;
  payload: any;
};

export type GetUserEnergyDataFailureT = {
  type: typeof GET_USER_ENERGY_DATA_FAILURE;
  error: string;
};

export type GetUserAdditionalDataRequestT = {
  type: typeof GET_USER_ADDITIONAL_DATA_REQUEST;
  userId: string;
};

export type GetUserAdditionalDataSuccessT = {
  type: typeof GET_USER_ADDITIONAL_DATA_SUCCESS;
  data: {
    additional: AdditionalDataT;
    powerData: PowerFlowItemT;
  };
};

export type GetUserAdditionalDataFailureT = {
  type: typeof GET_USER_ADDITIONAL_DATA_FAILURE;
  error: string;
};

export type ResetUserDashboardT = {
  type: typeof RESET_USER_DASHBOARD;
};

export type OpenUserEditModalT = {
  type: typeof OPEN_USER_EDIT_MODAL;
  homeId: string;
  isEdit: boolean;
};

export type ToggleUserEditModalT = {
  type: typeof TOGGLE_USER_EDIT_MODAL;
  isBaselineModalOpen?: boolean;
  isEdit?: boolean;
};

export type UpdateUserBaselineRequestT = {
  type: typeof UPDATE_USER_BASELINE_REQUEST;
  homeId: string;
  data: BaselineT;
};

export type UpdateUserBaselineSuccessT = {
  type: typeof UPDATE_USER_BASELINE_SUCCESS;
  data: BaselineT;
};

export type UpdateUserBaselineFailureT = {
  type: typeof UPDATE_USER_BASELINE_FAILURE;
  error: string;
};

export type ResetUserBaselineStateT = {
  type: typeof RESET_UPDATE_BASELINE_STATE;
};

export type ChangeSavingShareT = {
  type: typeof CHANGE_SAVING_SHARE;
  homeId?: string;
  savingShare?: number;
  date: Date;
};

export type ChangeSavingShareSuccessT = {
  type: typeof CHANGE_SAVING_SHARE_SUCCESS;
  // data: BaselineT;
};

export type ChangeSavingShareFailureT = {
  type: typeof CHANGE_SAVING_SHARE_FAILURE;
  error: string;
};

export type GetSavingShareT = {
  type: typeof GET_SAVING_SHARE;
  homeId?: string;
};

export type GetSavingShareSuccessT = {
  type: typeof GET_SAVING_SHARE_SUCCESS;
  data: Array<SavingShareT>;
};

export type GetSavingShareFailureT = {
  type: typeof GET_SAVING_SHARE_FAILURE;
  error: string;
};

export type SwitchDeleteModalT = {
  type: typeof SWITCH_DELETE_MODAL;
  isOpen: boolean;
  deleteId?: string;
};

export type SwitchSavingShareModalT = {
  type: typeof SWITCH_SAVING_SHARE_MODAL;
  isOpen: boolean;
  userId: string;
};

export type switchAdditionalServicesModalT = {
  type: typeof SWITCH_ADDITIONAL_SERVICES_MODAL;
  isOpen: boolean;
  userId: string;
};

export type switchReferralLinkModalT = {
  type: typeof SWITCH_REFERRAL_LINK_MODAL;
  isOpen: boolean;
  userId: string;
};

export type switchRateModalT = {
  type: typeof SWITCH_RATE_MODAL;
  isOpen: boolean;
  userId: string;
};

export type getUserInfoT = {
  type: typeof GET_USER_INFO;
  userId: string;
};

export type getUserInfoSuccessT = {
  type: typeof GET_USER_INFO_SUCCESS;
  data: any;
};

export type ActionTypeT =
  | GetUsersT
  | GetUsersSuccessT
  | GetUsersFailureT
  | GetUserByIdT
  | GetUserByIdSuccessT
  | GetUserByIdFailureT
  | ChangeUsersListT
  | DeleteUserT
  | DeleteUserSuccessT
  | DeleteUserFailureT
  | SwitchUserDetailsT
  | SwitchedUserDetailsT
  | GetBaselineT
  | GetBaselineSuccessT
  | GetBaselineFailureT
  | OpenUserEnergyT
  | OpenedUserEnergyT
  | CloseUserEnergyT
  | GetUserEnergyDataByIdT
  | GetUserEnergyDataT
  | GetUserEnergyDataSuccessT
  | GetUserEnergyDataFailureT
  | GetUserAdditionalDataRequestT
  | GetUserAdditionalDataSuccessT
  | GetUserAdditionalDataFailureT
  | ResetUserDashboardT
  | ToggleUserEditModalT
  | UpdateUserBaselineRequestT
  | UpdateUserBaselineSuccessT
  | UpdateUserBaselineFailureT
  | ResetUserBaselineStateT
  | ChangeSavingShareT
  | ChangeSavingShareSuccessT
  | ChangeSavingShareFailureT
  | GetSavingShareT
  | GetSavingShareSuccessT
  | GetSavingShareFailureT
  | SwitchDeleteModalT
  | switchAdditionalServicesModalT
  | switchReferralLinkModalT
  | switchRateModalT
  | SwitchSavingShareModalT
  |getUserInfoSuccessT;

export type StateT = {
  loading: boolean;
  loaded: boolean;
  error: string | null;
  users: Array<UserT> | null;
  created: boolean;
  selectedUser: UserT | null;
  isUserDetailsOpen: boolean;
  isEnergyModalOpen: boolean;
  isBaselineModalOpen: boolean;
  energy: any | null;
  openedId: string | null;
  userDashboard: {
    loading: boolean;
    loaded: boolean;
    error: string | null;
    additional: AdditionalDataT | null;
    powerData: any | null;
  };
  updateBaselineData: {
    updating: boolean;
    updated: boolean;
    error: string | null;
  };
  isEdit: boolean;
  isDeleteModalOpen: boolean;
  deleteUserError: string | null;
  deleteId: string | undefined;
  deleted: boolean;
  isSavingShareModalOpen: boolean;
  isAdditionalServicesModalOpen: boolean;
  isReferralLinkModalOpen: boolean;
  isRateModalOpen: boolean;
  savingShare: null | Array<SavingShareT>;
  isFlat: null | boolean;
};

const initialState: StateT = {
  loading: false,
  loaded: false,
  error: null,
  users: null,
  created: false,
  selectedUser: null,
  isUserDetailsOpen: false,
  isEnergyModalOpen: false,
  isBaselineModalOpen: false,
  energy: null,
  openedId: null,
  userDashboard: {
    loading: false,
    loaded: false,
    error: null,
    additional: null,
    powerData: null,
  },
  updateBaselineData: {
    updating: false,
    updated: false,
    error: null,
  },
  isEdit: false,
  isDeleteModalOpen: false,
  deleteUserError: null,
  deleteId: undefined,
  deleted: false,
  isSavingShareModalOpen: false,
  isAdditionalServicesModalOpen: false,
  isReferralLinkModalOpen: false,
  isRateModalOpen: false,
  savingShare: null,
  isFlat: null
};

const loading = {
  loading: true,
  loaded: false,
  error: null,
};

const loaded = {
  loading: false,
  loaded: true,
};

export default function usersReducer(
  state: StateT = initialState,
  action: ActionTypeT
): StateT {
  switch (action.type) {
    case GET_USERS_LIST:
    case GET_BASELINE: {
      return { ...state, ...loading, created: false };
    }
    case GET_USERS_LIST_SUCCESS:
    case CHANGE_USERS_LIST:
    case SWITCHED_USER_DETAIL:
    case GET_BASELINE_SUCCESS:
    case OPENED_USER_ENERGY:
    case GET_USER_ENERGY_DATA_SUCCESS:
      return { ...state, ...loaded, ...action.payload };
    case GET_USERS_LIST_FAILURE:
    case GET_BASELINE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case OPEN_USER_ENERGY:
      return {
        ...state,
        openedId: action.id,
      };
    case CLOSE_USER_ENERGY:
      return {
        ...state,
        isEnergyModalOpen: false,
        openedId: null,
      };
    case GET_USER_ADDITIONAL_DATA_REQUEST: {
      return {
        ...state,
        userDashboard: {
          ...state.userDashboard,
          ...loading,
        },
      };
    }
    case GET_USER_ADDITIONAL_DATA_SUCCESS: {
      return {
        ...state,
        userDashboard: {
          ...state.userDashboard,
          ...loaded,
          additional: action.data.additional,
          powerData: action.data.powerData,
        },
      };
    }
    case RESET_USER_DASHBOARD: {
      return {
        ...state,
        energy: null,
        userDashboard: {
          ...initialState.userDashboard,
        },
      };
    }
    case TOGGLE_USER_EDIT_MODAL: {
      const isBaselineModalOpen =
        typeof action.isBaselineModalOpen === "boolean"
          ? action.isBaselineModalOpen
          : !state.isBaselineModalOpen;

      return {
        ...state,
        isBaselineModalOpen,
        isEdit: action.isEdit || false,
      };
    }
    case UPDATE_USER_BASELINE_REQUEST: {
      return {
        ...state,
        updateBaselineData: {
          ...state.updateBaselineData,
          updating: true,
        },
      };
    }
    case UPDATE_USER_BASELINE_SUCCESS: {
      return {
        ...state,
        // @ts-ignore
        selectedUser: {
          ...state.selectedUser,
          baseline: {
            ...state.selectedUser?.baseline,
            ...action.data,
          },
        },
        updateBaselineData: {
          ...state.updateBaselineData,
          updating: false,
          updated: true,
        },
      };
    }
    case UPDATE_USER_BASELINE_FAILURE: {
      return {
        ...state,
        updateBaselineData: {
          updating: false,
          updated: false,
          error: action.error,
        },
      };
    }
    case RESET_UPDATE_BASELINE_STATE: {
      return {
        ...state,
        updateBaselineData: {
          ...initialState.updateBaselineData,
        },
      };
    }
    case SWITCH_DELETE_MODAL: {
      return {
        ...state,
        isDeleteModalOpen: action.isOpen,
        deleteId: action.deleteId,
        deleteUserError: null,
      };
    }
    case DELETE_USER: {
      return {
        ...state,
        deleteUserError: null,
        deleted: false,
      };
    }
    case DELETE_USER_SUCCESS: {
      return {
        ...state,
        isDeleteModalOpen: false,
        deleted: true,
      };
    }
    case DELETE_USER_FAILURE: {
      return {
        ...state,
        deleteUserError: action.error,
      };
    }
    case SWITCH_SAVING_SHARE_MODAL: {
      return {
        ...state,
        isSavingShareModalOpen: action.isOpen,
        isUserDetailsOpen: false,
      };
    }
    case SWITCH_ADDITIONAL_SERVICES_MODAL: {
      return {
        ...state,
        isAdditionalServicesModalOpen: action.isOpen,
        isUserDetailsOpen: false,
      };
    }
    case SWITCH_REFERRAL_LINK_MODAL: {
      return {
        ...state,
        isReferralLinkModalOpen: action.isOpen,
        isUserDetailsOpen: false,
      };
    }
    case SWITCH_RATE_MODAL: {
      return {
        ...state,
        isRateModalOpen: action.isOpen,
        isUserDetailsOpen: false,
      };
    }
    case GET_USER_INFO_SUCCESS: {
      return {
        ...state,
        isFlat: action?.data?.currentRate?.flat
      }
    }   
    case CHANGE_SAVING_SHARE_SUCCESS: {
      return {
        ...state,
        isSavingShareModalOpen: false,
      };
    }
    case GET_SAVING_SHARE_SUCCESS: {
      return {
        ...state,
        savingShare: action.data,
      };
    }
    default:
      return state;
  }
}

export function getUsersList() {
  return {
    type: GET_USERS_LIST,
  };
}

export function getUserById(id: string) {
  return {
    type: GET_USER_BY_ID,
    id,
  };
}

export function deleteUserById(id: string) {
  return {
    type: DELETE_USER,
    id,
  };
}

export function switchUserDetails({ isUserDetailsOpen, id }: UserDetailsT) {
  return {
    type: SWITCH_USER_DETAIL,
    isUserDetailsOpen,
    id,
  };
}

export function getBaseline(homeId: string) {
  return {
    type: GET_BASELINE,
    homeId,
  };
}

export function openUserEnergy(id?: string) {
  return {
    type: OPEN_USER_ENERGY,
    id,
  };
}

export function closeUserEnergy() {
  return {
    type: CLOSE_USER_ENERGY,
  };
}

export function getUserEnergyData(period?: string) {
  return {
    type: GET_USER_ENERGY_DATA,
    period,
  };
}

export function getUserEnergyDataById(userId: string, period?: string) {
  return {
    type: GET_USER_ENERGY_DATA_BY_ID,
    userId,
    period,
  };
}

export function getUserAdditionalData(
  userId: string
): GetUserAdditionalDataRequestT {
  return {
    type: GET_USER_ADDITIONAL_DATA_REQUEST,
    userId,
  };
}

export function getUserAdditionalDataSuccess({
  additional,
  powerData,
}: {
  additional: AdditionalDataT;
  powerData: PowerFlowItemT;
}): GetUserAdditionalDataSuccessT {
  return {
    type: GET_USER_ADDITIONAL_DATA_SUCCESS,
    data: { additional, powerData },
  };
}

export function getUserAdditionalDataFailure(
  error: string
): GetUserAdditionalDataFailureT {
  return {
    type: GET_USER_ADDITIONAL_DATA_FAILURE,
    error,
  };
}

export function resetUserDashboard(): ResetUserDashboardT {
  return {
    type: RESET_USER_DASHBOARD,
  };
}

export function toggleUserEditModal(
  isBaselineModalOpen?: boolean,
  isEdit?: boolean
): ToggleUserEditModalT {
  return {
    type: TOGGLE_USER_EDIT_MODAL,
    isBaselineModalOpen,
    isEdit,
  };
}

export function updateUserBaseline(
  homeId: string,
  data: BaselineT
): UpdateUserBaselineRequestT {
  return {
    type: UPDATE_USER_BASELINE_REQUEST,
    data,
    homeId,
  };
}

export function updateUserBaselineSuccess(
  data: BaselineT
): UpdateUserBaselineSuccessT {
  return {
    type: UPDATE_USER_BASELINE_SUCCESS,
    data,
  };
}

export function updateUserBaselineFailure(
  error: string
): UpdateUserBaselineFailureT {
  return {
    type: UPDATE_USER_BASELINE_FAILURE,
    error,
  };
}

export function resetUpdateState() {
  return {
    type: RESET_UPDATE_BASELINE_STATE,
  };
}

export function openUserEditModal(
  homeId: string,
  isEdit: boolean = false
): OpenUserEditModalT {
  return {
    type: OPEN_USER_EDIT_MODAL,
    homeId,
    isEdit,
  };
}

export function changeSavingShare(
  homeId: string = "",
  savingShare: number = 0,
  date: Date
): ChangeSavingShareT {
  return {
    type: CHANGE_SAVING_SHARE,
    homeId,
    savingShare,
    date,
  };
}

export function getSavingShare(homeId: string = "") {
  return {
    type: GET_SAVING_SHARE,
    homeId,
  };
}

export function switchDeleteModal(isOpen: boolean, deleteId?: string) {
  return {
    type: SWITCH_DELETE_MODAL,
    isOpen,
    deleteId,
  };
}

export function switchSavingShareModal(isOpen: boolean, userId?: string) {
  return {
    type: SWITCH_SAVING_SHARE_MODAL,
    isOpen,
    userId,
  };
}

export function switchAdditionalServicesModal(isOpen: boolean, userId?: string) {
  return {
    type: SWITCH_ADDITIONAL_SERVICES_MODAL,
    isOpen,
    userId,
  };
}

export function switchReferralLinkModal(isOpen: boolean, userId?: string) {
  return {
    type: SWITCH_REFERRAL_LINK_MODAL,
    isOpen,
    userId,
  };
}

export function switchRateModal(isOpen: boolean, userId?: string) {
  return {
    type: SWITCH_RATE_MODAL,
    isOpen,
    userId,
  };
}

export function getUserInfo(userId: string) {
  return {
    type: GET_USER_INFO,
    userId,
  };
}
