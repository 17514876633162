import { DayT, HoursT, PeakT } from "../api/dashboard";

export const calculatePickHours = (peaks: any) =>
  peaks.reduce((acc: Array<number>, curr: PeakT) => {
    const values = [];
    for (let i = curr.start; i < curr.finish; i++) {
      values.push(i);
    }
    return [...acc, ...values];
  }, []);

export const calculatePicksIntervals = (peaks: any) => {
  return peaks.map((peak: { start: number; finish: number }) => {
    const interval = [];
    for (let i = peak.start; i < peak.finish; i++) {
      interval.push(i);
    }
    return interval;
  });
};

export const calculateConsumption = (info: HoursT) => -info.consumption;
export const calculateOnPeak = (info: HoursT) =>
  info.peak1 > 0 || info.peak2 > 0 ? -info.consumption : 0;
export const calculateOffPeak = (info: HoursT) =>
  info.peak1 === 0 && info.peak2 === 0 ? -info.consumption : 0;

export const calculateOnPeakDashboard = (info: HoursT) =>
  info.peak > 0 ? -info.consumption : 0;
export const calculateOffPeakDashboard = (info: HoursT) =>
  info.peak === 0 ? -info.consumption : 0;

export const calculateConsumptionDashboard = (info: HoursT) =>
  (info.peak === 0 ? -info.consumption : 0) +
  (info.peak > 0 ? -info.consumption : 0);

export const transformedData = Array(24).fill({ pick: 0 });
export const transformedWeekData = Array(7).fill({});
export const transformedMonthData = (length: number) =>
  length > 28 ? Array(4).fill({}) : Array(4).fill({});
export const transformedMonthDataByDays = (length: number) =>
  length >= 28 ? Array(length).fill({}) : Array(28).fill({});

export const calculatePick = (info: any, index?: number) => {
  if (index) {
    return info[`peak${index}`] ? -info[`peak${index}`] : 0;
  }
  return info.peak ? -info.peak : 0;
};

export const calculatePickValue = (hours: Array<HoursT>) => {
  const array = hours.map((item: HoursT) =>
    Math.max(item.consumption, item.generation)
  );
  return Math.max(...array) * 1.2;
};

export const calculateOffPeakConsumption = (item: DayT) =>
  -item.offPeakConsumption;
export const calculateOnPeakConsumption = (item: DayT) =>
  -item.onPeakConsumption;
export const calculateTotalConsumption = (item: DayT) => {
  return -item.onPeakConsumption + -item.offPeakConsumption;
};

export function calculateTransformedMonthData(elements: Array<DayT>) {
  return transformedMonthData(elements.length).reduce(
    (acc: Array<any>, curr: any, index: number) => {
      const days = elements.splice(0, 7);
      return [
        ...acc,
        {
          label: index + 1,
          onPeakConsumption: days.reduce(
            (acc: any, curr: any) => acc + curr.onPeakConsumption,
            0
          ),
          offPeakConsumption: days.reduce(
            (acc: any, curr: any) => acc + curr.offPeakConsumption,
            0
          ),
          generation: days.reduce(
            (acc: any, curr: any) =>
              acc + curr.onPeakGeneration + curr.offPeakGeneration,
            0
          ),
          savings: days.reduce((acc: any, curr: any) => acc + curr.savings, 0),
        },
      ];
    },
    []
  );
}
