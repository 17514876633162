import React from "react";
import ModalContent from "../Modal/ModalContent";
import styles from "./SuccessModal.module.scss";

type PropsT = {
  title: string;
};

export default function SuccessModal(props: PropsT) {
  const { title } = props;
  return (
    <ModalContent>
      <div className={styles.container}>User was successful {title}!</div>
    </ModalContent>
  );
}
