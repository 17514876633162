import React, { useState } from "react";
import UpdateForm from "./UpdateForm";
import UpdatesHistory from "./UpdatesHistory";

type PropsT = {
  closeModal: () => void;
  setSuccessEditFPLRates: (value: boolean) => void;
};

function EditFPLRates(props: PropsT) {
  const { closeModal, setSuccessEditFPLRates } = props;
  const [showListOfUpdates, setShowListOfUpdates] = useState<boolean>(false);
  return (
    <>
      {showListOfUpdates ? (
        <UpdatesHistory setShowListOfUpdates={setShowListOfUpdates} />
      ) : (
        <UpdateForm
          closeModal={closeModal}
          setSuccessEditFPLRates={setSuccessEditFPLRates}
          setShowListOfUpdates={setShowListOfUpdates}
        />
      )}
    </>
  );
}

export default EditFPLRates;
