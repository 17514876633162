import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputRange from "react-input-range";
import DatePicker from "react-datepicker";

import ModalHeader from "../Modal/ModalHeader";
import ModalContent from "../Modal/ModalContent";
import ModalFooter from "../Modal/ModalFooter";
import Button from "../Button/Button";
import {
  changeSavingShare,
  getSavingShare,
  StateT as UsersState,
} from "../../store/modules/users";
import { AppState } from "../../store/modules";
import { SavingShareT } from "../../api/users";
import { dateFormat, formatMonthDayYear } from "../../helpers/formatDate";
import styles from "./SavingShareModal.module.scss";

type PropsT = {
  closeModal: () => void;
};

export default function SavingShareModal(props: PropsT) {
  const { closeModal } = props;
  const dispatch = useDispatch();
  const { selectedUser, savingShare } = useSelector<AppState, UsersState>(
    (state) => state.users
  );
  const [share, setShare] = useState(0);
  const [date, setDate] = useState(new Date());
  const onChange = (val: any) => setShare(val);

  useEffect(() => {
    dispatch(getSavingShare(selectedUser?.id));
  }, [dispatch, selectedUser]);

  useEffect(() => {
    if (savingShare?.length) {
      setShare(savingShare[savingShare.length - 1]?.share * 100);
    }
  }, [savingShare]);

  const handleChangeDate = (date: Date) => setDate(date);

  function saveChanges() {
    if (selectedUser?.id) {
      dispatch(changeSavingShare(selectedUser.id, share / 100, date));
    }
  }

  return (
    <div className={styles.modalWrapper}>
      <ModalHeader>Change saving share</ModalHeader>
      <ModalContent>
        <div className={styles.title}>Saving share {share} %</div>
        <InputRange
          name="share"
          step={5}
          minValue={0}
          maxValue={100}
          value={share}
          onChange={onChange}
        />
        Valid from:&nbsp;&nbsp;&nbsp;
        <DatePicker
          selected={date}
          onChange={handleChangeDate}
          dateFormat={dateFormat}
          showMonthDropdown
          showYearDropdown
        />
        <div className={styles.historyLabel}>Change history:</div>
        <div className={styles.tableWrapper}>
          <b className={styles.box}>Share</b>
          <b className={styles.box}>Valid From</b>
          <b className={styles.box}>Valid To</b>

          {savingShare?.length
            ? savingShare.map((item: SavingShareT) => (
                <div className={styles.row} key={item.validFrom.toString()}>
                  <div className={styles.box}>{item.share * 100}</div>
                  <div className={styles.box}>
                    {formatMonthDayYear(item.validFrom)}
                  </div>
                  <div className={styles.box}>
                    {formatMonthDayYear(item.validTo)}
                  </div>
                </div>
              ))
            : "-"}
        </div>
      </ModalContent>
      <ModalFooter>
        <Button variant="outlined" onClick={saveChanges}>
          Save
        </Button>
        <Button onClick={closeModal}>Cancel</Button>
      </ModalFooter>
    </div>
  );
}
