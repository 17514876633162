import React, { useMemo, useState, useCallback } from "react";
import cx from "classnames";
import Button from "../../components/Button/Button";
import Modal from "../../components/Modal/Modal";
import ModalHeader from "../../components/Modal/ModalHeader";
import ModalContent from "../../components/Modal/ModalContent";
import ModalFooter from "../../components/Modal/ModalFooter";
import { BankAccountT } from "../../api/paymentDetails"

import { ReactComponent as RadioIcon } from "./../../assets/icons/Radio.svg";
import { ReactComponent as RadioCheckedIcon } from "./../../assets/icons/RadioChecked.svg";

import styles from "./CardSelectionModal.module.scss";

type PropsT = {
  data: Array<BankAccountT> | null,
  isOpen: boolean,
  onClose: () => void,
  onSubmit: (account: BankAccountT) => void,
}

export default function CardSelectionModal({ onSubmit, ...props }: PropsT) {
  const modalStyles = useMemo(() => ({}), []);
  const [selectedAccount, setSelectedAccount] = useState<null | BankAccountT>(null);
  const submit = useCallback(() => {
    if (selectedAccount) {
      onSubmit(selectedAccount);
    }
  }, [selectedAccount, onSubmit]);

  return (
    <Modal
      isOpen={props.isOpen}
      closeModal={props.onClose}
      style={modalStyles}
      contentClassName={styles.modalContent}
    >
      <ModalHeader>
        Payment Method
      </ModalHeader>

      <ModalContent>
        <span className={styles.description}>Connected Cards</span>

        <ul className={styles.cardList}>
          {props.data && props.data.map((account, idx) => (
            <li key={account.account_id} className={cx(styles.cardRow, { [styles.withBorder]: idx })}>
              <button onClick={() => setSelectedAccount(account)}>
                <div className={styles.radioContainer}>
                  {selectedAccount === account ? (
                    <RadioCheckedIcon />
                  ) : (
                    <RadioIcon />
                  )}
                </div>

                <div className={styles.cardDescription}>{account.name} *{account.mask}</div>
              </button>
            </li>
          ))}
        </ul>
      </ModalContent>

      <ModalFooter className={styles.footer}>
        <Button variant="outlined" onClick={submit} disabled={!selectedAccount}>
          Save
        </Button>

        <Button>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}
