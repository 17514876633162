import React, { ReactNode } from "react";
import cx from "classnames";
import styles from "./Modal.module.scss";

type TProps = {
  children: ReactNode;
  className?: string;
};

const ModalFooter = (props: TProps) => {
  const { children } = props;

  return (
    <footer className={cx(styles.footer, props.className)}>
      <div className={styles.wrap}>
        {children}
      </div>
    </footer>
  );
};

export default ModalFooter;
