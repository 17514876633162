import React, { useCallback, useEffect, useMemo } from "react";
import { Form, Field, withFormik } from "formik";
import * as Yup from "yup";
import cx from "classnames";
import { CellProps as CellPropsT } from "react-table";

import Button from "../Button/Button";
import Table from "../PlainTable/Table";
import InputField from "../InputField/InputField";
import ModalHeader from "../Modal/ModalHeader";
import ModalContent from "../Modal/ModalContent";
import ModalFooter from "../Modal/ModalFooter";
import ValidateFormMessage from "../ValidateFormMessage/ValidateFormMessage";
import CSVLoader from "../CSVLoader/CSVLoader";
import { months, MonthsT } from "./constants";
import { BaselineT } from "../../api/users";

import { ReactComponent as Chevron } from "../../assets/icons/Chevron-left.svg";
import styles from "./BaselineForm.module.scss";

type FormValuesT = {
  baseline: { [$month: string]: number | undefined };
  onPeak: { [$month: string]: number | undefined };
  offPeak: { [$month: string]: number | undefined };
  generation: { [$month: string]: number | undefined };
};

type PropsT = {
  closeModal: () => void;
  goBack: () => void;
  isHidden: boolean;
  onSubmit: (data: BaselineT) => void;
  sendError: null | string;
  inviting: boolean;
  invited: boolean;
  initialValues?: FormValuesT;
  submitText?: string;
  onComponentUnmount?: () => void;
  isInvite?: boolean;
  isEdit?: boolean;
  setBaselineData?: any;
  handleSubmit?: () => {};
};

type MonthsSchemaT = {
  [$month: string]: Yup.NumberSchema<number>;
};

function mapMonthsToSchema(months: MonthsT): MonthsSchemaT {
  return Object.keys(months).reduce((acc: any, month) => {
    acc[month] = Yup.number()
      .typeError("Wrong number format")
      .positive("Wrong number format")
      .required("Required field");

    return acc;
  }, {});
}

const TableRows = [
  { name: "Baseline, kWh", value: "baseline" },
  { name: "On Peak, kWh", value: "onPeak" },
  { name: "Off Peak, kWh", value: "offPeak" },
  { name: "Generation, kWh", value: "generation" },
];

const BaselineFormSchema = Yup.object().shape({
  ...TableRows.reduce((acc: MonthsSchemaT, { value }) => {
    // @ts-ignore
    acc[value] = Yup.object().shape(mapMonthsToSchema(months));

    return acc;
  }, {}),
});

function BaselineForm(props: PropsT) {
  const {
    closeModal,
    goBack,
    isHidden,
    sendError,
    inviting,
    invited,
    onComponentUnmount,
    isInvite,
    isEdit,
    setBaselineData,
  } = props;

  function close() {
    closeModal();
  }

  useEffect(() => {
    return () => {
      if (onComponentUnmount) {
        onComponentUnmount();
      }
    };
  }, [onComponentUnmount]);

  useEffect(() => {
    if (invited) {
      closeModal();
    }
  }, [invited, closeModal]);

  const renderField = useCallback(
    ({ row, value }: CellPropsT<{ type: string }>) => (
      <Field
        component={InputField}
        variant="outline"
        name={`${row.original.type}.${value}`}
        placeholder="kWh"
        className={cx(styles.fieldItem, {
          [styles.disabledInput]: !isInvite || !isEdit,
        })}
        inputClassName={styles.fieldItem1}
        autoComplete="off"
        disabled={!isInvite && !isEdit}
      />
    ),
    [isEdit, isInvite]
  );

  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "name",
        width: 150,
      },
      ...Object.keys(months).map((month) => ({
        Header: month.toUpperCase().slice(0, 3),
        accessor: month,
        Cell: renderField,
        width: 90,
      })),
    ],
    [renderField]
  );

  function getData() {
    return TableRows.map((row) => ({
      name: row.name,
      type: row.value,
      ...Object.keys(months).reduce(
        (acc: { [$month: string]: string }, month) => {
          acc[month] = month;
          return acc;
        },
        {}
      ),
    }));
  }

  return (
    <Form noValidate className={cx({ [styles.hidden]: isHidden })}>
      <ModalHeader>
        <span className={styles.title}>{`${
          isEdit ? "Edit user" : "User"
        } baseline usage data`}</span>
      </ModalHeader>

      <ModalContent>
        {isInvite ? (
          <CSVLoader setBaselineData={setBaselineData} />
        ) : (
          <Table
            columns={columns}
            data={getData()}
            className={styles.baselineTable}
            headClassName={styles.tableHead}
          />
        )}
      </ModalContent>

      <ModalFooter>
        {(isEdit || isInvite) && (
          <>
            <ValidateFormMessage error={sendError} className={styles.error} />
            <Button variant="outlined" type="submit" disabled={inviting}>
              {inviting ? "loading..." : props.submitText}
            </Button>
          </>
        )}

        <Button onClick={close}>Cancel</Button>

        {isInvite && (
          <Button className={styles.goBack} onClick={goBack}>
            <Chevron />
            Back
          </Button>
        )}
      </ModalFooter>
    </Form>
  );
}

BaselineForm.defaultProps = {
  submitText: "Send Invite",
};

export default withFormik<PropsT, FormValuesT>({
  displayName: `withFormik${BaselineForm.name}`,
  mapPropsToValues: ({ initialValues }) => {
    if (!initialValues) {
      return {
        baseline: months,
        onPeak: months,
        offPeak: months,
        generation: months,
      };
    }

    return {
      baseline: initialValues.baseline,
      onPeak: initialValues.onPeak,
      offPeak: initialValues.offPeak,
      generation: initialValues.generation,
    };
  },
  validationSchema: BaselineFormSchema,
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },
})(BaselineForm);
