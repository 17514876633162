import axios from "../api/axiosConfig";

type SaveTokensT = {
  token: string;
  refreshToken: string;
};

export function setToken(token: string) {
  axios.defaults.headers.common["Authorization"] = token
    ? `Bearer ${token}`
    : "";
}

export function saveTokens({ token, refreshToken }: SaveTokensT) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  localStorage.setItem("token", token);
  localStorage.setItem("refreshToken", refreshToken);
}

export function removeUser() {
  axios.defaults.headers.common["Authorization"] = "";
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
}

export function getTokens() {
  return {
    token: localStorage.getItem("token"),
    refreshToken: localStorage.getItem("refreshToken"),
  };
}

export function setTokenForRefresh() {
  const token = localStorage.getItem("refreshToken");
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}
