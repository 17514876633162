import React from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../store/modules";
import { StateT as AuthState } from "../../store/modules/authorization";
import { StateT as userState } from "../../store/modules/users";
import Nomination from "../Nomination/Nomination";
import colors from "./../../assets/style/variables/colors.scss";
import * as styles from "./TooltipStatisticsView.module.scss";

type PropsT = {
  savings?: number;
  generation?: number;
  consumption?: number;
  offPeakConsumption?: number;
  onPeakConsumption?: number;
  onPeak?: boolean;
};

const TooltipStatisticsView = (props: PropsT) => {
  const { currentRate } = useSelector<AppState, AuthState>(
    (state) => state.authorization
  );
  const {
    savings = 0,
    generation = 0,
    consumption = 0,
    offPeakConsumption = 0,
    onPeakConsumption = 0,
    onPeak = false,
  } = props;
  const {isFlat} = useSelector<AppState, userState>(
    (state) => state.users
  );
  return (
    <div className={styles.wrapper}>
      <Nomination name="Net Savings" number={savings} isMoney />
      <Nomination
        name="Solar"
        number={generation}
        color={colors["orange"]}
        suffix="kWh"
      />
      {consumption ? (
        <Nomination
          name="Usage"
          number={consumption}
          suffix="kWh"
          color={onPeak ? colors["dark-ocean"] : colors["green"]}
        />
      ) : (!currentRate?.flat && !isFlat) ? (
        <>
          <Nomination
            name="Off-Peak"
            number={offPeakConsumption}
            suffix="kWh"
            color={colors["cyan"]}
          />
          <Nomination
            name="On-Peak"
            number={onPeakConsumption}
            suffix="kWh"
            color={colors["dark-ocean"]}
          />
        </>
      ) : (
        <Nomination
          name="Consumption"
          number={onPeakConsumption + offPeakConsumption}
          suffix="kWh"
          color={colors["cyan"]}
        />
      )}
    </div>
  );
};

export default TooltipStatisticsView;
