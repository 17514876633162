import React, { useState, useContext, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { isMobileOnly } from "react-device-detect";
import { useHistory } from "react-router-dom";
import cx from "classnames";
import { ShowHeaderContext } from "../Dashboard/Dashboard";

import WhiteBlock from "../../components/WhiteBlock/WhiteBlock";
import Button from "../../components/Button/Button";
import UploadPhoto from "../../components/UploadPhoto/UploadPhoto";
import ReferralLinkModal from "../../components/ReferralLinkModal/ReferralLinkModal";
import Modal from "../../components/Modal/Modal";
import { AppState } from "../../store/modules";
import {
  StateT as AuthState,
  changePassword,
  logOut,
} from "../../store/modules/authorization";
import { ChangingPasswordT } from "../../api/authorization";
import { apiURL } from "../../api/axiosConfig";

import { ReactComponent as BeeIcon } from "../../assets/icons/Bee.svg";
import { ReactComponent as ResidenceIcon } from "../../assets/icons/Residence.svg";
import { ReactComponent as PowerIcon } from "../../assets/icons/Power.svg";
import { ReactComponent as Photo } from "../../assets/icons/Photo.svg";
import { ReactComponent as LogOut } from "../../assets/icons/LogOut.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/Edit.svg";
import styles from "./ProfilePage.module.scss";
import ChangePassword from "../../components/ChangePassword/ChangePassword";

const modalStyle = { width: "468px", height: "562px", top: "38%" };
const referralModalStyle = { width: "100%", height: "max-Content", top: "38%" };
const desktopChangeModalStyle = { width: "468px", height: "423px", top: "32%" };
const mobileChangeModalStyle = {
  width: "100%",
  height: "100%",
  transform: "none",
  top: "0",
  left: "0",
};
const url = apiURL();

function getInitials(name: any): string {
  if (!name || typeof name !== "string") {
    return "";
  }

  return name
    .split(" ")
    .slice(0, 2)
    .map((v) => v.charAt(0).toUpperCase())
    .join("");
}

const ProfilePage = () => {
  const {
    name,
    email,
    address,
    ecobeeId,
    sensorNames,
    supplierCode,
    photoLink,
    accountId = "-",
    payments,
  } = useSelector<AppState, AuthState>((state) => state.authorization);
  const dispatch = useDispatch();
  const [modalIsOpen, setModalOpen] = useState(false);
  const [changePasswordIsOpen, setPasswordOpen] = useState(false);
  const [referralLinkModal, setReferralLinkModal] = useState(false);
  const [, setShowHeader] = useContext(ShowHeaderContext);

  const history = useHistory();

  function openModal() {
    if (isMobileOnly) {
      // @ts-ignore
      setShowHeader(false);
    }

    setModalOpen(true);
  }

  function closeModal() {
    if (isMobileOnly) {
      // @ts-ignore
      setShowHeader(true);
    }

    setModalOpen(false);
  }

  function openChangePasswordModal() {
    setPasswordOpen(true);
  }

  function closeChangePasswordModal() {
    setPasswordOpen(false);
  }

  function submitChangePassword(data: ChangingPasswordT) {
    dispatch(changePassword(data));
  }

  const renderNeurio = () => {
    if (sensorNames?.length) {
      return sensorNames.map((item: string) => (
        <div key={item} className={cx(styles.neurioItem, "group-icon")}>
          <ResidenceIcon className={styles.neurioIcon} />
          {item}
        </div>
      ));
    }
    return "-";
  };

  const renderPowerProvider = () => {
    return (
      <div className="group-icon">
        {supplierCode === "FPL" && <PowerIcon className={styles.powerIcon} />}
        {supplierCode || "-"}
      </div>
    );
  };

  const renderCardData = useCallback(() => {
    if (!payments) {
      return null;
    }

    return (
      <span>
        {payments.accountName} * {payments.accountNumber}
      </span>
    );
  }, [payments]);

  const navigateToPaymentsPage = useCallback(() => {
    history.push("/payment-details");
  }, [history]);

  if (!email) return null;

  const initials = getInitials(name);

  function handleLogOut() {
    dispatch(logOut());
  }

  function renderContent() {
    return (
      <>
        <div className={styles.fieldWrapper}>
          <div className={styles.content}>
            <div
              className={cx(styles.img, styles.avatar)}
              style={
                photoLink ? { backgroundImage: `url(${url}${photoLink})` } : {}
              }
            >
              {!photoLink && initials}
            </div>
            <button className={styles.photoIcon} onClick={openModal}>
              <Photo />
            </button>
            <div className={styles.userBlock}>
              <div className={styles.userName}>{name}</div>
              <a href={`mailto:${email}`} className={styles.email}>
                {email}
              </a>

              <Button
                className={styles.changePassword}
                onClick={openChangePasswordModal}
              >
                Change Password
              </Button>
            </div>
          </div>
        </div>
        <div className={styles.fieldWrapper}>
          <div className={styles.name}>Account Id</div>
          <div className={styles.value}>{accountId}</div>
        </div>
        <div className={styles.fieldWrapper}>
          <div className={styles.name}>Address</div>
          <div className={styles.value}>{address}</div>
        </div>
        <div className={styles.fieldWrapper}>
          <div className={styles.name}>Ecobee</div>
          <div className={styles.value}>
            {(ecobeeId && (
              <div className="group-icon">
                <BeeIcon className={styles.ecobeeIcon} />
                {ecobeeId}
              </div>
            )) ||
              "-"}
          </div>
        </div>
        <div className={styles.fieldWrapper}>
          <div className={styles.name}>Neurio</div>
          <div className={styles.value}>{renderNeurio()}</div>
        </div>
        <div className={styles.fieldWrapper}>
          <div className={styles.name}>Power Provider</div>
          <div className={styles.value}>{renderPowerProvider()}</div>
        </div>
        <div className={cx(styles.fieldWrapper, styles.paymentMethod)}>
          <div className={styles.name}>Payment method</div>
          <div className={styles.value}>
            {renderCardData()} <EditIcon onClick={navigateToPaymentsPage} />
          </div>
        </div>
      </>
    );
  }

  if (isMobileOnly) {
    return (
      <WhiteBlock
        className={cx(styles.whiteBox, { [styles.modalIsOpen]: modalIsOpen })}
      >
        {modalIsOpen ? (
          <UploadPhoto closeModal={closeModal} photoLink={photoLink} />
        ) : (
          <>
            {renderContent()}

            <Button
              variant="outlined"
              onClick={() => setReferralLinkModal(true)}
              className={cx(styles.button, styles.inviteFriendButton)}
            >
              Referral Link
            </Button>

            <Button className={styles.button} onClick={handleLogOut}>
              <LogOut /> Log Out
            </Button>
          </>
        )}
        <Modal
          isOpen={changePasswordIsOpen}
          closeModal={closeChangePasswordModal}
          style={mobileChangeModalStyle}
        >
          <ChangePassword
            onSubmit={submitChangePassword}
            close={closeChangePasswordModal}
            email={email}
          />
        </Modal>
        <Modal
          isOpen={referralLinkModal}
          closeModal={() => setReferralLinkModal(false)}
          style={referralModalStyle}
        >
          <ReferralLinkModal closeModal={() => setReferralLinkModal(false)} />
        </Modal>
      </WhiteBlock>
    );
  }

  return (
    <WhiteBlock className={styles.whiteBox}>
      {renderContent()}

      <Modal
        isOpen={modalIsOpen}
        closeModal={closeModal}
        style={modalStyle}
        showCloseButton={false}
      >
        <UploadPhoto closeModal={closeModal} photoLink={photoLink} />
      </Modal>

      <Modal
        isOpen={changePasswordIsOpen}
        closeModal={closeChangePasswordModal}
        style={desktopChangeModalStyle}
      >
        <ChangePassword
          onSubmit={submitChangePassword}
          close={closeChangePasswordModal}
          email={email}
        />
      </Modal>
    </WhiteBlock>
  );
};

export default ProfilePage;
