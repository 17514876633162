import React from "react";
import CircleChart from "./CircleChart";

type PropsT = {
  onPeakConsumption?: number;
  offPeakConsumption?: number;
  totalGeneration?: number;
  predictedGeneration: number;
  predictedOffPeak: number;
  predictedOnPeak: number;
  className?: string;
  svgRef?: (ref: any) => void;
  svgProps?: any | null;
  animation?: boolean;
  withPieChart?: boolean;
  savingsAmount?: number;
  sprightfulSavings?: number;
  actualCharge?: number;
};

export default function CircleChartContainer({
  onPeakConsumption = 0,
  offPeakConsumption = 0,
  totalGeneration = 0,
  predictedGeneration,
  predictedOffPeak,
  predictedOnPeak,
  className,
  svgRef,
  svgProps,
  animation,
  withPieChart,
  savingsAmount,
  sprightfulSavings,
  actualCharge,
}: PropsT) {
  const offPeak = offPeakConsumption / predictedOffPeak;
  const generation = totalGeneration / predictedGeneration;
  const onPeak = onPeakConsumption / predictedOnPeak;
  const consumption = offPeak + onPeak;

  const middle = offPeak > 1 ? 100 : offPeak * 100;
  const inner = onPeak > 1 ? 100 : onPeak * 100;
  const outer = generation > 1 ? 100 : generation * 100;
  const totalConsumption = consumption > 1 ? 100: consumption * 100;

  return (
    <CircleChart
      middle={middle}
      inner={inner}
      outer={outer}
      totalConsumption={totalConsumption}
      onPeakConsumption={onPeakConsumption}
      offPeakConsumption={offPeakConsumption}
      totalGeneration={totalGeneration}
      className={className}
      svgRef={svgRef}
      svgProps={svgProps}
      animation={animation}
      withPieChart={withPieChart}
      savingsAmount={savingsAmount}
      sprightfulSavings={sprightfulSavings}
      actualCharge={actualCharge}
    />
  );
}
