/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ExceedingMark from "./ExceedingMark";
import PieChart from "../PieChart/PieChart";
import {
  LINE_LENGTH,
  baseStyle,
  CHART_STYLES,
  coordinates,
  RED,
  BLACK,
  GREY,
  ORANGE,
  CYAN,
  DARKOCEAN,
} from "./constants";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/modules";
import { StateT as AuthState } from "../../store/modules/authorization";
import { StateT as userState } from "../../store/modules/users";
import { useParams } from "react-router-dom";
import { getUserInfo } from "../../store/modules/users";

type PropsT = {
  outer: number;
  middle: number;
  inner: number;
  totalConsumption: number;
  className?: string;
  svgRef?: (ref: any) => void;
  svgProps?: any | null;
  animation?: boolean;
  onPeakConsumption?: number;
  offPeakConsumption?: number;
  totalGeneration?: number;
  withPieChart?: boolean;
  savingsAmount?: number;
  sprightfulSavings?: number;
  actualCharge?: number;
};

function calcStyles({ outer, middle, inner, totalConsumption }: PropsT) {
  return {
    outer: {
      strokeDashoffset: LINE_LENGTH.outer - LINE_LENGTH.outer * (outer / 100),
    },
    middle: {
      strokeDashoffset:
        LINE_LENGTH.middle - LINE_LENGTH.middle * (middle / 100),
    },
    inner: {
      strokeDashoffset: LINE_LENGTH.inner - LINE_LENGTH.inner * (inner / 100),
    },
    totalConsumption: {
      strokeDashoffset:
        LINE_LENGTH.middle - LINE_LENGTH.middle * (totalConsumption / 100),
    },
  };
}

export default function CircleChart(props: PropsT) {
  const {userId} = useParams<any>();
  const dispatch = useDispatch();

  const {isFlat} = useSelector<AppState, userState>(
    (state) => state.users
  );
  
  const { currentRate } = useSelector<AppState, AuthState>(
    (state) => state.authorization
  );  
  
  const [chartLengthStyles, setChartLengthStyles] = useState(
    props.animation
      ? {
          outer: { strokeDashoffset: LINE_LENGTH.outer },
          middle: { strokeDashoffset: LINE_LENGTH.middle },
          inner: { strokeDashoffset: LINE_LENGTH.inner },
          totalConsumption: {
            strokeDashoffset: LINE_LENGTH.middle + LINE_LENGTH.inner,
          },
        }
      : calcStyles(props)
  );
  const {
    onPeakConsumption = 0,
    offPeakConsumption = 0,
    totalGeneration = 0,
    withPieChart,
    savingsAmount,
    sprightfulSavings,
    actualCharge,
  } = props;

  useEffect(() => {
    if (!props.animation) {
      return;
    }

    const animationTimeoutId = setTimeout(() => {
      setChartLengthStyles(calcStyles(props));
    }, 100);

    return () => clearTimeout(animationTimeoutId);
  }, [props]);

  useEffect(() => {
    if ((!currentRate?.flat && !isFlat && userId)) {
      dispatch(getUserInfo(userId));
    }
  }, [])
  

  return (
    <div className="row">
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{ fill: "none" }}
          version="1.1"
          viewBox={`0 0 ${withPieChart ? 700 : 418.09926} 199.5004`}
          ref={props.svgRef}
          className={props.className}
          {...props.svgProps}
        >
          <g transform="translate(7.9027467)">
            <path
              style={baseStyle}
              d="m 177.59726,195.5 c -30.167,0 -58.7,0 -85.500007,0 -33.5,0 -95.9999997,-28 -95.9999997,-96.5 0,-54.8 46.4999997,-94.99998 95.9999997,-95 49.500007,-2e-5 74.000007,35 74.000007,35"
            />
            <path
              style={{
                ...baseStyle,
                ...CHART_STYLES.outer,
                ...chartLengthStyles.outer,
              }}
              d="m 177.59726,195.5 c -30.167,0 -58.7,0 -85.500007,0 -33.5,0 -95.9999997,-28 -95.9999997,-96.5 0,-54.8 46.4999997,-94.99998 95.9999997,-95 49.500007,-2e-5 74.000007,35 74.000007,35"
            />

            {(!currentRate?.flat && !isFlat) ? (
              <>
                <path
                  style={baseStyle}
                  d="m 178.09726,174 c -23.585,0 -63.993,0 -84.945007,0 -26.1914,0 -75.055,-21.786 -75.055,-75.0835 0,-42.6382 36.3545,-73.9165 75.055,-73.9165 38.700007,0 57.854007,27.2324 57.854007,27.2324"
                />
                <path
                  style={{
                    ...baseStyle,
                    ...CHART_STYLES.middle,
                    ...chartLengthStyles.middle,
                  }}
                  d="m 178.09726,174 c -23.585,0 -63.993,0 -84.945007,0 -26.1914,0 -75.055,-21.786 -75.055,-75.0835 0,-42.6382 36.3545,-73.9165 75.055,-73.9165 38.700007,0 57.854007,27.2324 57.854007,27.2324"
                />

                <path
                  style={baseStyle}
                  d="m 177.59726,152 c -16.455,0 -68.518,0 -83.136007,0 -18.2731,0 -52.364,-15.352 -52.364,-52.9112 0,-30.047 25.3636,-52.0888 52.364,-52.0888 27.000007,0 40.363007,19.1906 40.363007,19.1906"
                />
                <path
                  style={{
                    ...baseStyle,
                    ...CHART_STYLES.inner,
                    ...chartLengthStyles.inner,
                  }}
                  d="m 177.59726,152 c -16.455,0 -68.518,0 -83.136007,0 -18.2731,0 -52.364,-15.352 -52.364,-52.9112 0,-30.047 25.3636,-52.0888 52.364,-52.0888 27.000007,0 40.363007,19.1906 40.363007,19.1906"
                />
              </>
            ) : (
              <>
                <path
                  style={baseStyle}
                  d="m 178.09726,174 c -23.585,0 -63.993,0 -84.945007,0 -26.1914,0 -75.055,-21.786 -75.055,-75.0835 0,-42.6382 36.3545,-73.9165 75.055,-73.9165 38.700007,0 57.854007,27.2324 57.854007,27.2324"
                />
                <path
                  style={{
                    ...baseStyle,
                    ...CHART_STYLES.middle,
                    ...chartLengthStyles.totalConsumption,
                  }}
                  d="m 178.09726,174 c -23.585,0 -63.993,0 -84.945007,0 -26.1914,0 -75.055,-21.786 -75.055,-75.0835 0,-42.6382 36.3545,-73.9165 75.055,-73.9165 38.700007,0 57.854007,27.2324 57.854007,27.2324"
                />
              </>
            )}
          </g>

          <g transform="translate(-17)">
            <g>
              <rect
                style={{ fill: ORANGE }}
                width="14"
                height="14"
                x="222.83344"
                y="24.93755"
                rx="4"
              />

              <text
                xmlSpace="preserve"
                style={{
                  fontWeight: 600,
                  fontSize: 14,
                  lineHeight: 1.25,
                  fill: props.outer >= 100 ? RED : BLACK,
                  fontFamily: "Manrope",
                }}
                x="242.75659"
                y="34.997375"
              >
                {Math.round(totalGeneration)}
              </text>

              <text
                xmlSpace="preserve"
                style={{
                  fontWeight: 500,
                  fontSize: 12,
                  lineHeight: 1.25,
                  fill: props.outer >= 100 ? RED : GREY,
                  fontFamily: "Manrope",
                }}
                x="242.66841"
                y="55.204899"
              >
                solar generation, kWh
              </text>
              {props.outer >= 100 && !withPieChart && (
                <ExceedingMark coordinates={coordinates.generation} />
              )}
            </g>

            {(!currentRate?.flat && !isFlat) ? (
              <>
                <g>
                  <rect
                    style={{ fill: CYAN }}
                    width="14"
                    height="14"
                    x="222.83344"
                    y="152.76987"
                    rx="4"
                  />

                  <text
                    xmlSpace="preserve"
                    style={{
                      fontWeight: 600,
                      fontSize: 14,
                      lineHeight: 1.25,
                      fill: props.middle >= 100 ? RED : BLACK,
                      fontFamily: "Manrope",
                    }}
                    x="242.84476"
                    y="162.62599"
                  >
                    {Math.round(offPeakConsumption)}
                  </text>

                  <text
                    xmlSpace="preserve"
                    style={{
                      fontWeight: 500,
                      fontSize: 12,
                      lineHeight: 1.25,
                      fill: props.middle >= 100 ? RED : GREY,
                      fontFamily: "Manrope",
                    }}
                    x="243.02109"
                    y="183.20758"
                  >
                    off-peak usage, kWh
                  </text>
                  {props.middle >= 100 && !withPieChart && (
                    <ExceedingMark coordinates={coordinates.offPeak} />
                  )}
                </g>

                <g>
                  <rect
                    style={{ fill: DARKOCEAN }}
                    width="14"
                    height="14"
                    x="222.83344"
                    y="85.750198"
                    rx="4"
                  />

                  <text
                    xmlSpace="preserve"
                    style={{
                      fontWeight: 600,
                      fontSize: 14,
                      lineHeight: 1.25,
                      fill: props.inner >= 100 ? RED : BLACK,
                      fontFamily: "Manrope",
                    }}
                    x="242.88127"
                    y="94.733505"
                  >
                    {Math.round(onPeakConsumption)}
                  </text>

                  <text
                    xmlSpace="preserve"
                    style={{
                      fontWeight: 500,
                      fontSize: 12,
                      lineHeight: 1.25,
                      fill: props.inner >= 100 ? RED : GREY,
                      fontFamily: "Manrope",
                    }}
                    x="242.74146"
                    y="116.02226"
                  >
                    on-peak usage, kWh
                  </text>
                  {props.inner >= 100 && !withPieChart && (
                    <ExceedingMark coordinates={coordinates.onPeak} />
                  )}
                </g>
              </>
            ) : (
              <g>
                <rect
                  style={{ fill: CYAN }}
                  width="14"
                  height="14"
                  x="222.83344"
                  y="85.750198"
                  rx="4"
                />

                <text
                  xmlSpace="preserve"
                  style={{
                    fontWeight: 600,
                    fontSize: 14,
                    lineHeight: 1.25,
                    fill: props.middle >= 100 ? RED : BLACK,
                    fontFamily: "Manrope",
                  }}
                  x="242.88127"
                  y="94.733505"
                >
                  {Math.round(offPeakConsumption + onPeakConsumption)}
                </text>

                <text
                    xmlSpace="preserve"
                    style={{
                      fontWeight: 500,
                      fontSize: 12,
                      lineHeight: 1.25,
                      fill: props.inner >= 100 ? RED : GREY,
                      fontFamily: "Manrope",
                    }}
                    x="242.74146"
                    y="116.02226"
                  >
                    total usage, kWh
                  </text>
                {props.middle >= 100 && !withPieChart && (
                  <ExceedingMark coordinates={coordinates.offPeak} />
                )}
              </g>
            )}
          </g>
          {withPieChart && (
            <PieChart
              savingsAmount={savingsAmount}
              sprightfulSavings={sprightfulSavings}
              actualCharge={actualCharge}
            />
          )}
        </svg>
      </div>
    </div>
  );
}

CircleChart.defaultProps = {
  animation: true,
};
