export type MonthsT = {
  [$month: string]: number | undefined;
}

export const months: MonthsT = {
  january: undefined,
  february: undefined,
  march: undefined,
  april: undefined,
  may: undefined,
  june: undefined,
  july: undefined,
  august: undefined,
  september: undefined,
  october: undefined,
  november: undefined,
  december: undefined,
};
