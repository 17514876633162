import React, { useMemo, useEffect } from "react";
import { isMobileOnly } from "react-device-detect";
import { Form, Field, withFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";

import ModalHeader from "../Modal/ModalHeader";
import ModalFooter from "../Modal/ModalFooter";
import Button from "../Button/Button";
import InputField from "../InputField/InputField";
import { Loader } from "../Loading/Loading";
import { ReactComponent as Envelope } from "../../assets/icons/Envelope.svg";
import { ReactComponent as Phone } from "../../assets/icons/Phone.svg";
import { ReactComponent as Pin } from "../../assets/icons/Pin.svg";
import { InviteFriendT } from "../../api/users";
import { AppState } from "../../store/modules";
import { StateT as InviteUsersState } from "../../store/modules/inviteUser";
import { StateT as SuccessAlertState } from "../../store/modules/successAlert";
import styles from "./InviteFriendForm.module.scss";

type PropsT = {
  onSubmit: (data: InviteFriendT) => void;
  close: () => void;
};

type RenderFieldPropsT = {
  name: string;
  label: string;
  placeholder: string;
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

function renderField(row: RenderFieldPropsT) {
  return (
    <Field
      component={InputField}
      variant="outline"
      name={row.name}
      key={row.name}
      label={row.label}
      placeholder={row.placeholder}
      className={styles.fieldItem}
      autoComplete="off"
      Icon={row.Icon}
    />
  );
}

function InviteFriendForm(props: PropsT) {
  const rows = useMemo(
    () => [
      { name: "name", label: "User", placeholder: "Enter user name" },
      {
        name: "email",
        label: "Email",
        placeholder: "User’s email",
        Icon: Envelope,
      },
      {
        name: "phone",
        label: "Phone",
        placeholder: "User’s phone",
        Icon: Phone,
      },
      {
        name: "address",
        label: "Address",
        placeholder: "Enter user address",
        Icon: Pin,
      },
    ],
    []
  );

  const { inviting } = useSelector<AppState, InviteUsersState>(
    (state: AppState) => state.inviteUser
  );

  const { showAlert } = useSelector<AppState, SuccessAlertState>(
    (state: AppState) => state.successAlert
  );

  useEffect(() => {
    if (showAlert) {
      props.close();
    }
  }, [props, showAlert]);

  if (inviting) {
    return <Loader size={180} className={styles.loader} />;
  }

  return (
    <>
      {!isMobileOnly && (
        <ModalHeader className={styles.header}>Invite Friend</ModalHeader>
      )}

      <Form id="invite-friend" className={styles.contentWrapper}>
        {rows.map(renderField)}
      </Form>

      <ModalFooter>
        <Button
          variant="outlined"
          type="submit"
          form="invite-friend"
          className={styles.sendButton}
        >
          Send Invite
        </Button>

        <Button onClick={props.close}>Cancel</Button>
      </ModalFooter>
    </>
  );
}

export default withFormik<PropsT, InviteFriendT>({
  displayName: `withFormik${InviteFriendForm.name}`,
  mapPropsToValues: () => ({
    name: "",
    email: "",
    phone: "",
    address: "",
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string().required("Required field"),
    email: Yup.string()
      .email("That format doesn't look right")
      .when("phone", {
        is: (phone: string) => !phone,
        then: Yup.string().required("Required field"),
        otherwise: Yup.string(),
      }),
    phone: Yup.number().when("email", {
      is: (email: string) => !email,
      then: Yup.number().required("Required field"),
      otherwise: Yup.number(),
    }),
    address: Yup.string().required("Required field"),
  },[["phone", "email"]]),
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },
})(InviteFriendForm);
