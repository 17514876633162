import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Title from "./Title";
import { getUserEnergyDataById } from "../../store/modules/users";
import { timeOptions } from "../../constants";
import { StateT as DashboardState } from "../../store/modules/dashboard";
import { AppState } from "../../store/modules";

type PropsT = {
  title: string;
};

const DashboardTitleForAdmin = (props: PropsT) => {
  const dispatch = useDispatch();
  const { userId } = useParams<{ userId: string}>();
  const { period } = useSelector<AppState, DashboardState>(
    (state) => state.dashboard
  );

  const selectedValue = timeOptions.find((item) => item.value === period);

  const onChange = (option: any) => {
    dispatch(getUserEnergyDataById(userId, option.value));
  };

  return (
    <Title
      title={props.title}
      onChange={onChange}
      selectedValue={selectedValue}
    />
  );
};

export default DashboardTitleForAdmin;
