import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  body: {
    paddingTop: 32,
    paddingHorizontal: 60,
    fontFamily: "Avenir Light",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  logoBlock: {
    flex: 1,
  },
  logoContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  bold: { fontFamily: "Avenir Bold" },
  logo: {
    width: 30,
    height: 30,
  },
  sunLogo: {
    width: 17,
    height: 17,
  },
  logoTextContainer: {
    marginTop: 5,
    marginLeft: 30,
  },
  logoText: {
    fontSize: 12,
  },
  subHeaderText: {
    textAlign: "right",
    marginTop: "10px"
  },
  calendar: {
    width: 12,
    height: 12,
    marginRight: 5,
  },
  sprightful: {
    fontFamily: "Avenir Demi",
    fontSize: 24,
    color: "#00C8CB",
    letterSpacing: 0.5,
    fontWeight: 300,
  },
  noteContainer: {
    backgroundColor: "#F3F7FB",
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: 5,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flex: 1,
    marginLeft: 50,
  },
  name: {
    fontFamily: "Avenir Demi",
  },
  canvas: {
    height: 240,
    width: "80%",
  },
  textTo: {
    fontSize: 10,
    marginBottom: 4,
  },
  section: {
    marginTop: 30,
  },
  divider: {
    height: 1,
    borderBottom: 1,
    borderColor: "#9DA5B9",
    opacity: 0.3,
    marginTop: -6
  },
  rowBetween: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "6px 4px",
    color: "black",
  },
  chartsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  marginTop: {
    marginTop: -30
  },
  sectionTitle: {
    fontSize: 10,
    margin: "20px 0px 6px",
    paddingLeft: 5,
    fontFamily: "Avenir Demi",
    textDecoration: "underline",
  },
  sectionText: {
    fontSize: 10,
  },
  summaryText: {
    fontFamily: "Avenir Demi",
  },
  indentedText: {
    fontSize: 10,
    marginLeft: "20px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  titleRow: {
    alignItems: "center",
    marginTop: 20,
    marginLeft: 6,
  },
  markerTitle: {
    fontFamily: "Avenir Demi",
    fontSize: 10,
  },
  monthSavings: {
    color: "#00C8CB",
  },
  oddRow: {
    backgroundColor: "#F5F9F8",
  },
  energySection: {
    width: "80%",
    display: "flex",
    justifyContent: "center",
    marginTop: 40,
  },
});

export default styles;
