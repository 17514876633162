import React from "react";
import { useSelector } from "react-redux";
import PowerFlow from "../../components/PowerFlow/PowerFlow";
import WhiteBlock from "../../components/WhiteBlock/WhiteBlock";
import { AppState } from "../../store/modules";
import { StateT as DashboardState } from "../../store/modules/dashboard";
// import getArrowCodeAndClassName from "../../helpers/getArrowCodeAndClassName";
// import styles from "./EnergyPage.module.scss"

export default function EnergyPage() {
  const { powerData/*, score*/ } = useSelector<AppState, DashboardState>(
    ({ dashboard }) => dashboard
  );

  // const { className, arrow } = getArrowCodeAndClassName(score || 0);
  return (
    <WhiteBlock>
        {/*<div className={styles.statusLabel}>
        Last 24 hours:{" "}
        <span className={className}>
          {arrow} {score && score.toFixed(1)} kWh
        </span>
      </div>*/}
      <PowerFlow data={powerData} isBig />
    </WhiteBlock>
  );
}
