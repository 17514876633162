import React from "react";
import Nomination from "../Nomination/Nomination";
import { ReactComponent as IconBill } from "../../assets/icons/Bill.svg";
import styles from "./UpcomingBill.module.scss";

type PropsT = {
  bill: number;
};

const UpcomingBill = (props: PropsT) => {
    const { bill } = props;
  return (
    <div className={styles.wrapper}>
      <IconBill className={styles.icon} />

      <div className={styles.content}>
        <Nomination name="Projected Charges" number={bill} isMoney />
      </div>

    </div>
  );
};

export default UpcomingBill;
