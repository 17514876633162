import { UpcomingBillsT, ComparativeBillT, ClosedBillT } from "../../api/bills";
import { RESET_USER_DASHBOARD, ResetUserDashboardT } from "./users";

export const GET_UPCOMING_BILLS = "GET_UPCOMING_BILLS";
export const GET_UPCOMING_BILLS_SUCCESS = "GET_UPCOMING_BILLS_SUCCESS";
export const GET_UPCOMING_BILLS_FAILURE = "GET_UPCOMING_BILLS_FAILURE";

export const GET_CLOSED_BILLS_REQUEST = "GET_CLOSED_BILLS_REQUEST";
export const GET_CLOSED_BILLS_SUCCESS = "GET_CLOSED_BILLS_SUCCESS";
export const GET_CLOSED_BILLS_FAILURE = "GET_CLOSED_BILLS_FAILURE";

export const GET_BILLS_INFORMATION_REQUEST = "GET_BILLS_INFORMATION_REQUEST";
export const GET_BILLS_INFORMATION_SUCCESS = "GET_BILLS_INFORMATION_SUCCESS";
export const GET_BILLS_INFORMATION_FAILURE = "GET_BILLS_INFORMATION_FAILURE";

export const GET_BILLS_INFORMATION_BY_ID = "GET_BILLS_INFORMATION_BY_ID";
export const GET_BILLS_INFORMATION_BY_ID_SUCCESS =
  "GET_BILLS_INFORMATION_BY_ID_SUCCESS";
export const GET_BILLS_INFORMATION_BY_ID_FAILURE =
  "GET_BILLS_INFORMATION_BY_ID_FAILURE";

export const GET_UPCOMING_BILLS_BY_HOME_ID = "GET_UPCOMING_BILLS_BY_HOME_ID";
export const GET_UPCOMING_BILLS_BY_HOME_ID_SUCCESS =
  "GET_UPCOMING_BILLS_BY_HOME_ID_SUCCESS";
export const GET_UPCOMING_BILLS_BY_HOME_ID_FAILURE =
  "GET_UPCOMING_BILLS_BY_HOME_ID_FAILURE";

export const PAY_BILL_REQUEST = "PAY_BILL_REQUEST";
export const PAY_BILL_SUCCESS = "PAY_BILL_SUCCESS";
export const PAY_BILL_FAILURE = "PAY_BILL_FAILURE";

export const SET_BILL_ID = "SET_BILL_ID";

type GetBillsInformationRequestT = {
  type: typeof GET_BILLS_INFORMATION_REQUEST;
};

type GetBillsInformationSuccessT = {
  type: typeof GET_BILLS_INFORMATION_SUCCESS;
  upcoming: UpcomingBillsT;
  closed: any;
  comparison: ComparativeBillT;
};

type GetBillsInformationFailureT = {
  type: typeof GET_BILLS_INFORMATION_FAILURE;
  error: string;
};

export type GetUpcomingBillsT = {
  type: typeof GET_UPCOMING_BILLS;
};

type GetUpcomingBillsSuccessT = {
  type: typeof GET_UPCOMING_BILLS_SUCCESS;
  upcoming: UpcomingBillsT;
};

type GetUpcomingBillsFailureT = {
  type: typeof GET_UPCOMING_BILLS_FAILURE;
  error: string;
};

type GetClosedBillsRequestT = {
  type: typeof GET_CLOSED_BILLS_REQUEST;
};

type GetClosedBillsSuccessT = {
  type: typeof GET_CLOSED_BILLS_SUCCESS;
};

type GetClosedBillsFailureT = {
  type: typeof GET_CLOSED_BILLS_FAILURE;
};

export type GetUpcomingBillsByHomeIdT = {
  type: typeof GET_UPCOMING_BILLS_BY_HOME_ID;
  userId: string;
};

type GetUpcomingBillsByHomeIdSuccessT = {
  type: typeof GET_UPCOMING_BILLS_BY_HOME_ID_SUCCESS;
  userUpcoming: UpcomingBillsT;
};

type GetUpcomingBillsByHomeIdFailureT = {
  type: typeof GET_UPCOMING_BILLS_BY_HOME_ID_FAILURE;
  error: string;
};

export type PayBillRequestT = {
  type: typeof PAY_BILL_REQUEST;
  id: string;
};

type PayBillSuccessT = {
  type: typeof PAY_BILL_SUCCESS;
};

type PayBillFailureT = {
  type: typeof PAY_BILL_FAILURE;
  error: string;
};

export type GetBillsInformationByIdT = {
  type: typeof GET_BILLS_INFORMATION_BY_ID;
  userId: string;
};

type GetBillsInformationByIdSuccessT = {
  type: typeof GET_BILLS_INFORMATION_BY_ID_SUCCESS;
  closed: any;
};

type GetBillsInformationByIdFailureT = {
  type: typeof GET_BILLS_INFORMATION_BY_ID_FAILURE;
  error: string;
};

type SetBillIdT = {
  type: typeof SET_BILL_ID;
  billId: string | null;
};

export type ActionTypeT =
  | GetUpcomingBillsT
  | GetUpcomingBillsSuccessT
  | GetUpcomingBillsFailureT
  | GetClosedBillsRequestT
  | GetClosedBillsSuccessT
  | GetClosedBillsFailureT
  | GetBillsInformationRequestT
  | GetBillsInformationSuccessT
  | GetBillsInformationFailureT
  | GetUpcomingBillsByHomeIdT
  | GetUpcomingBillsByHomeIdSuccessT
  | GetUpcomingBillsByHomeIdFailureT
  | ResetUserDashboardT
  | PayBillRequestT
  | PayBillSuccessT
  | PayBillFailureT
  | GetBillsInformationByIdT
  | GetBillsInformationByIdSuccessT
  | GetBillsInformationByIdFailureT
  | SetBillIdT;

export type StateT = {
  loading: boolean;
  loaded: boolean;
  upcoming: null | UpcomingBillsT;
  userUpcoming: null | UpcomingBillsT;
  closed: null | any;
  comparison: null | ComparativeBillT;
  error: string | null;
  payError: string | null;
  paying: boolean;
  payed: boolean;
  billId: string | null;
};

const initialState: StateT = {
  loading: false,
  loaded: false,
  upcoming: null,
  userUpcoming: null,
  closed: [],
  comparison: null,
  error: null,
  payError: null,
  paying: false,
  payed: false,
  billId: null,
};

const loading = { loading: true, loaded: false };
const loaded = { loading: false, loaded: true };
const loadedWithError = { loading: false, loaded: false };

export default function billsReducer(
  state: StateT = initialState,
  action: ActionTypeT
): StateT {
  switch (action.type) {
    case GET_UPCOMING_BILLS:
    case GET_UPCOMING_BILLS_BY_HOME_ID:
      return { ...state, ...loading };
    case GET_UPCOMING_BILLS_SUCCESS:
      return { ...state, ...loaded, upcoming: action.upcoming };
    case GET_UPCOMING_BILLS_FAILURE:
    case GET_UPCOMING_BILLS_BY_HOME_ID_FAILURE:
      return { ...state, ...loadedWithError, error: action.error };

    case GET_BILLS_INFORMATION_REQUEST: {
      return { ...state, ...loading };
    }
    case GET_BILLS_INFORMATION_SUCCESS: {
      return {
        ...state,
        ...loaded,
        upcoming: action.upcoming,
        closed: action.closed,
        comparison: action.comparison,
      };
    }
    case GET_BILLS_INFORMATION_FAILURE: {
      return { ...state, ...loadedWithError, error: action.error };
    }
    case GET_UPCOMING_BILLS_BY_HOME_ID_SUCCESS: {
      return { ...state, ...loaded, userUpcoming: action.userUpcoming };
    }
    case RESET_USER_DASHBOARD: {
      return {
        ...state,
        userUpcoming: null,
      };
    }
    case PAY_BILL_REQUEST: {
      return {
        ...state,
        paying: true,
        payed: false,
        payError: null,
        billId: action.id,
      };
    }
    case PAY_BILL_SUCCESS: {
      return {
        ...state,
        payed: true,
        paying: false,
        billId: null,
      };
    }
    case PAY_BILL_FAILURE: {
      return {
        ...state,
        payed: false,
        paying: false,
        payError: action.error,
      };
    }
    case SET_BILL_ID: {
      return {
        ...state,
        billId: action.billId,
      };
    }
    default:
      return state;
  }
}

export function getUpcomingBills(): GetUpcomingBillsT {
  return {
    type: GET_UPCOMING_BILLS,
  };
}

export function getUpcomingBillsSuccess(
  upcoming: UpcomingBillsT
): GetUpcomingBillsSuccessT {
  return {
    type: GET_UPCOMING_BILLS_SUCCESS,
    upcoming,
  };
}

export function getUpcomingBillsFailure(
  error: string
): GetUpcomingBillsFailureT {
  return {
    type: GET_UPCOMING_BILLS_FAILURE,
    error: error,
  };
}

export function getClosedBills(): GetClosedBillsRequestT {
  return {
    type: GET_CLOSED_BILLS_REQUEST,
  };
}

export function getBillsInformation(): GetBillsInformationRequestT {
  return {
    type: GET_BILLS_INFORMATION_REQUEST,
  };
}

export function getBillsInformationSuccess(payload: {
  upcoming: UpcomingBillsT;
  closed: Array<ClosedBillT>;
  comparison: ComparativeBillT;
}): GetBillsInformationSuccessT {
  return {
    type: GET_BILLS_INFORMATION_SUCCESS,
    ...payload,
  };
}

export function getBillsInformationFailure(
  error: string
): GetBillsInformationFailureT {
  return {
    type: GET_BILLS_INFORMATION_FAILURE,
    error: error,
  };
}

export function getUpcomingBillsByHomeId(
  userId: string
): GetUpcomingBillsByHomeIdT {
  return {
    type: GET_UPCOMING_BILLS_BY_HOME_ID,
    userId,
  };
}

export function payBill(id: string): PayBillRequestT {
  return {
    type: PAY_BILL_REQUEST,
    id,
  };
}

export function payBillSuccess(): PayBillSuccessT {
  return {
    type: PAY_BILL_SUCCESS,
  };
}

export function payBillFailure(error: string): PayBillFailureT {
  return {
    type: PAY_BILL_FAILURE,
    error,
  };
}

export function getBillsInformationById(userId: string) {
  return {
    type: GET_BILLS_INFORMATION_BY_ID,
    userId,
  };
}

export function setBillId(billId: string | null) {
  return {
    type: SET_BILL_ID,
    billId,
  };
}
