import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePlaidLink } from "react-plaid-link";
import { useHistory } from "react-router-dom";

import { PLAID_PUBLIC_TOKEN } from "../../constants";
import {
  linkBankAccount,
  linkBankAccountSuccess,
  resetState,
} from "../../store/modules/paymentDetails";
import Loading from "../../components/Loading/Loading";
import Button from "../../components/Button/Button";
import CardSelectionModal from "../../components/CardSelectionModal/CardSelectionModal";
import Modal from "../../components/Modal/Modal";
import ModalHeader from "../../components/Modal/ModalHeader";
import ModalFooter from "../../components/Modal/ModalFooter";
import { AppState } from "../../store/modules";
import { StateT as PaymentStateT } from "../../store/modules/paymentDetails";
import { checkUser } from "../../store/modules/authorization";
import { BankAccountT } from "../../api/paymentDetails";

import styles from "./PaymentDetails.module.scss";
import Logo from "../../assets/images/logo.png";

interface BankAccountMetaDataT {
  id: string;
  accounts: Array<BankAccountT>;
}

function useModalMessage(
  linked?: boolean,
  error?: boolean,
  responseError?: string | null
) {
  if (error || responseError) {
    return "Something went wrong";
  }

  if (linked) {
    return "Account successfully linked";
  }

  return null;
}

const plaidEnvironment = () => {
    if (process.env.REACT_APP_PLAID_ENVIRONMENT) {
        return process.env.REACT_APP_PLAID_ENVIRONMENT;
    } else {
        return "sandbox";
    }
};

export default function PaymentDetails() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading, linked, error: responseError } = useSelector<
    AppState,
    PaymentStateT
  >(({ paymentDetails }) => paymentDetails);
  const { location } = useSelector<AppState, any>(({ router }) => router);
  const [noAccountError, setNoAccountError] = useState<boolean>(false);
  const modalTitle = useModalMessage(linked, noAccountError, responseError);
  const [bankAccounts, setBankAccounts] = useState<null | Array<BankAccountT>>(
    null
  );
  const [token, setToken] = useState<null | string>(null);
  const [bankAccount, setBankAccount] = useState<null | BankAccountT>(null);
  const proceedAfterConfigure = useCallback(() => {
    if (location?.state?.toBills) {
      if (location?.state?.billId) {
        dispatch(checkUser());
      }
      history.push("/bills", { billId: location?.state?.billId });
    } else {
      history.push("/profile");
    }
  }, [history, location, dispatch]);
  const onBankAccountSelected = useCallback(
    (account: BankAccountT) => {
      setBankAccount(account);
    },
    [setBankAccount]
  );

  const { open } = usePlaidLink({
    publicKey: PLAID_PUBLIC_TOKEN,
    clientName: "Sprightful",
    env: plaidEnvironment(),
    product: ["auth"],
    /* @ts-ignore */
    onSuccess: (public_token: string, metadata: BankAccountMetaDataT) => {
      const accounts = metadata.accounts.filter(
        ({ subtype }) => subtype === "checking"
      );

      setToken(public_token);

      if (accounts.length > 1) {
        setBankAccounts(accounts);
      } else if (accounts.length === 1) {
        setBankAccount(accounts[0]);
      } else {
        setNoAccountError(true);
      }
    },
  });

  const handleLinkBankAccount = useCallback(() => {
    dispatch(linkBankAccount());
    open();
  }, [open, dispatch]);

  const goToDashboard = useCallback(() => {
    history.go(-1);
  }, [history]);

  useEffect(() => {
    if (bankAccount && token) {
      dispatch(linkBankAccountSuccess(token, bankAccount));
    }
  }, [token, bankAccount, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, [dispatch]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <img src={Logo} alt="Sprightful logo" className={styles.logoImg} />
      </div>

      <p className={styles.notice}>
        I authorize Sprightful to electronically debit my account and, if
        necessary, electronically credit my account to correct erroneous debits.
      </p>

      <Button onClick={handleLinkBankAccount} variant="outlined">
        Connect a bank account
      </Button>

      <Button onClick={goToDashboard} className={styles.cancelButton}>
        Cancel
      </Button>

      <CardSelectionModal
        data={bankAccounts}
        isOpen={!bankAccount && !!bankAccounts}
        onClose={proceedAfterConfigure}
        onSubmit={onBankAccountSelected}
      />

      <Modal
        isOpen={linked || noAccountError || !!responseError}
        closeModal={() => {}}
        contentClassName={styles.modalContent}
        showCloseButton={false}
      >
        <ModalHeader>{modalTitle}</ModalHeader>

        <ModalFooter>
          <Button variant="outlined" onClick={proceedAfterConfigure}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
