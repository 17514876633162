export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i;
export const cardRegex = /^[0-9]{4}\s[0-9]{4}\s[0-9]{4}\s[0-9]{4}$/;
export const expirationDateRegex = /^[0-1][0-9]\/202[0-9]$/;
export const cvvRegex = /^[0-9]{3}$/;
export const zipCodeRegex = /^[0-9]{5}(-[0-9]{4})?$/;
export const slashesRegexp = /\/\//;
export const accountIdRegexp = /^SP\d{7}$/;

// prettier-ignore
export const cardMask = [/\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/];
export const expirationDateMask = [/[0-1]/, /\d/, "/", 2, 0, 2, /\d/];
export const cvvMask = [/\d/, /\d/, /\d/];

export const formattedHours: Record<number, string> = {
  0: "12 am",
  1: "1 am",
  2: "2 am",
  3: "3 am",
  4: "4 am",
  5: "5 am",
  6: "6 am",
  7: "7 am",
  8: "8 am",
  9: "9 am",
  10: "10 am",
  11: "11 am",
  12: "12 pm",
  13: "1 pm",
  14: "2 pm",
  15: "3 pm",
  16: "4 pm",
  17: "5 pm",
  18: "6 pm",
  19: "7 pm",
  20: "8 pm",
  21: "9 pm",
  22: "10 pm",
  23: "11 pm",
};

export type OptionT = {
  label: string;
  value: string;
};

export const timeOptions: Array<OptionT> = [
  { label: "Today", value: "today" },
  { label: "Yesterday", value: "yesterday" },
  { label: "This week", value: "week" },
  { label: "This month", value: "month" },
];

export const PLAID_PUBLIC_TOKEN = "52cfb20ae926e9310a440a174a01b3";
