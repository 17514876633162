import { put, takeEvery, delay, call, select, all } from "redux-saga/effects";
import {
  ChangeEnergyReportPeriodT,
  GET_ADDITIONAL_DATA,
  GET_ADDITIONAL_DATA_SUCCESS,
  GET_ADDITIONAL_DATA_FAILURE,
  GET_ENERGY_DATA,
  GET_ENERGY_DATA_SUCCESS,
  GET_ENERGY_DATA_FAILURE,
  CHANGE_ENERGY_REPORT_PERIOD,
  CHANGED_ENERGY_REPORT_PERIOD,
} from "../modules/dashboard";
import { getEnergyData, getEnergyFlow, getAdditionalData} from "../../api/dashboard";

function* getAdditionalDataSaga() {
  try {
    const { data: additional } = yield call(getAdditionalData);
    const [, { data: { elements: powerData, score } }] = yield all([
      delay(1000),
      call(getEnergyFlow)
    ]);

    yield put({
      type: GET_ADDITIONAL_DATA_SUCCESS,
      payload: {
        additional,
        powerData,
        score
      },
    });
  } catch (error) {
    yield put({ type: GET_ADDITIONAL_DATA_FAILURE, error });
  }
}

function* getEnergyDataSaga() {
  try {
    const period: string = yield select((state) => state.dashboard.period);
    const { data: energy } = yield call(getEnergyData, period);
    yield put({
      type: GET_ENERGY_DATA_SUCCESS,
      payload: {
        energy: {
          ...energy,
          lastUpdate: new Date(),
        },
      },
    });
  } catch (error) {
    yield put({ type: GET_ENERGY_DATA_FAILURE, error });
  }
}

function* changeEnergyReportPeriodSaga(action: ChangeEnergyReportPeriodT) {
  const { period } = action;
  yield put({ type: CHANGED_ENERGY_REPORT_PERIOD, period });
  yield put({ type: GET_ENERGY_DATA });
}

export default function* userSaga() {
  yield takeEvery(GET_ADDITIONAL_DATA, getAdditionalDataSaga);
  yield takeEvery(GET_ENERGY_DATA, getEnergyDataSaga);
  yield takeEvery(CHANGE_ENERGY_REPORT_PERIOD, changeEnergyReportPeriodSaga);
}
