import React from "react";
import { CoordinatesT, RED, WHITE } from "./constants";
import styles from "./CircleChart.module.scss";

type PropsT = {
  coordinates: CoordinatesT;
};

export default function ExceedingMark(props: PropsT) {
  const { coordinates } = props;
  return (
    <g className={styles.tooltip}>
      <circle
        cx="340"
        cy={coordinates.circle.cy}
        r="10"
        style={{
          fill: RED,
        }}
      />
      <text
        style={{
          stroke: WHITE,
          fill: WHITE,
          fontWeight: 300,
        }}
        x="337.5"
        y={coordinates.mark.y}
      >
        !
      </text>
      <text
        style={{
          fontWeight: 600,
          fontSize: 12,
          fill: RED,
          fontFamily: "Manrope",
        }}
      >
        <tspan x="311" y={coordinates.text.y}>
          exceeded
        </tspan>
        <tspan x="310" dy="1em">
          prediction
        </tspan>
      </text>
    </g>
  );
}
