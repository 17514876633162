import React from "react";
import cx from "classnames"
import styles from "./ValidateFormMessage.module.scss";

type PropsT = {
  error: string | null;
  className?: string;
};

const ValidateFormMessage = (props: PropsT) => {
  const { error, className } = props;

  return <>{error && <p className={cx(styles.element, className)}>{error}</p>}</>;
};

export default ValidateFormMessage;
