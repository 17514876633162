import React from "react";
import NumberFormat from "react-number-format";

type PropsT = {
  value: any;
  isMoney?: boolean;
  suffix?: string;
  decimalScale?: number;
};

export default function NumericValueFormat(props: PropsT) {
  const { value, isMoney = false, suffix, decimalScale = 2 } = props;
  const format = isMoney ? { prefix: "$" } : {};
  return (
    <NumberFormat
      value={+value}
      decimalScale={decimalScale}
      displayType="text"
      suffix={suffix}
      thousandSeparator
      fixedDecimalScale
      {...format}
    />
  );
}
