import axios from "./axiosConfig";

export type PeakT = {
  start: number;
  finish: number;
};

export type DayT = {
  label: string;
  offPeakConsumption: number;
  offPeakGeneration: number;
  onPeakConsumption: number;
  onPeakGeneration: number;
  savings: number;
};

export type HoursT = {
  hour: number;
  peak?: any;
  peak1?: any;
  peak2?: any;
  consumption: number;
  generation: number;
};

export type EnergyT = {
  date: string;
  peaks: Array<PeakT>;
  hours: Array<HoursT>;
  totalGeneration: number;
  totalOffPeak: number;
  totalOnPeak: number;
  totalSavings: number;
  lastUpdate?: Date;
  label?: string;
};

export type WeeklyEnergyT = {
  elements: Array<DayT>;
  label: string;
  savings: number;
  totalGeneration: number;
  totalOffPeakConsumption: number;
  totalOnPeakConsumption: number;
  totalSavings: number;
};

export type StatisticsPeakT = {
  power: string;
  money: string;
};

export type LifetimeSavingsT = {
  my: number;
  total: number;
};

export type AdditionalDataT = {
  panelSize: number;
  lifetimeSavings: LifetimeSavingsT;
};

export type PowerFlowItemT = {
  label: string,
  house: number,
  solar: number,
  grid: number,
}

export function getEnergyData(period: string) {
  return axios.get(
    "/me/dashboard/energy" + (period === "today" ? "" : `/${period}`)
  );
}

export function getEnergyFlow() {
  return axios.get('/me/dashboard/energy/flow');
}

export function getAdditionalData() {
  return axios.get('/me');
}
