import React, { ReactNode } from "react";
import { useMediaQuery } from "react-responsive";
import { useHistory, Route } from "react-router-dom";
import { NavLink } from "react-router-dom";

import ProfileDrawer from "../ProfileDrawer/ProfileDrawer";
import ReferralLinkModal from "../../components/ReferralLinkModal/ReferralLinkModal";
import UpdatesCounter from "../UpdatesCounter/UpdatesCounter";
import Modal from "./../../components/Modal/Modal";
import { AppState } from "../../store/modules";
import logo from "./../../assets/images/logo.png";
import { ReactComponent as HomeIcon } from "./../../assets/icons/Home.svg";
import { ReactComponent as HelpIcon } from "./../../assets/icons/Help.svg";
import breakpoints from "./../../variables.scss";
import styles from "./Header.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  switchReferralLinkModal,
  StateT as UsersState,
} from "../../store/modules/users";
import Button from "../Button/Button";

type THeaderParams = {
  children: ReactNode;
  homeUrl: string;
  admin: boolean;
};

const Header = (params: THeaderParams) => {
  const history = useHistory();
  const { admin, children, homeUrl } = params;
  const pageTitle = admin ? "Admin Panel" : "Dashboard";
  const { isReferralLinkModalOpen, selectedUser } = useSelector<
    AppState,
    UsersState
  >((state) => state.users);

  const dispatch = useDispatch();

  const isResponsiveView = useMediaQuery({
    query: `(max-width: ${breakpoints["tablet"]}px)`,
  });

  function goHome() {
    history.push(homeUrl);
  }

  function referralLinkModal() {
    dispatch(switchReferralLinkModal(true, selectedUser?.id));
  }

  function closeReferralLinkModal() {
    dispatch(switchReferralLinkModal(false));
  }

  return (
    <header className={styles.container}>
      <div className={styles.header}>
        <div className="wrap">
          <div className={styles.content}>
            <div className={styles.column}>
              <div className={styles.logo}>
                <img
                  src={logo}
                  alt="Sprightful logo"
                  onClick={goHome}
                  className={styles.logoIcon}
                />
                {isResponsiveView && (
                  <div className={styles.mobileHeaderNavigation}>
                    <Route exact path="/dashboard" component={UpdatesCounter} />
                    <NavLink to="/faq" className={styles.faqIcon}>
                      <HelpIcon />
                    </NavLink>
                  </div>
                )}
              </div>
              <div>
                {!isResponsiveView && (
                  <div className={styles.breadcrumb}>
                    <HomeIcon className={styles.breadcrumbIcon} />
                    {pageTitle}
                  </div>
                )}
                <div className={styles.title}>{children}</div>
              </div>
            </div>

            {!isResponsiveView && (
              <div className={styles.column}>
                {!admin && (
                  <>
                    <Route exact path="/dashboard" component={UpdatesCounter} />
                    <NavLink to="/faq" className={styles.faqIcon}>
                      <HelpIcon />
                    </NavLink>
                  </>
                )}

                <div className={styles.profile}>
                  <ProfileDrawer />
                </div>
                {!admin && (
                  <Button
                    className={styles.referralLink}
                    variant='outlined'
                    onClick={referralLinkModal}
                  >
                    Referral Link
                  </Button>
                )}
                <Modal
                  closeModal={closeReferralLinkModal}
                  isOpen={isReferralLinkModalOpen}
                >
                  <ReferralLinkModal closeModal={closeReferralLinkModal} />
                </Modal>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
