import React, { useCallback } from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";

import ModalHeader from "../Modal/ModalHeader";
import ModalContent from "../Modal/ModalContent";
import ModalFooter from "../Modal/ModalFooter";
import Button from "../Button/Button";
import Modal from "../Modal/Modal";
import { AppState } from "../../store/modules";
import { StateT as BillsState } from "../../store/modules/bills";

import styles from "../../containers/Bills/Bills.module.scss";

type PropsT = {
  isOpen: boolean;
  closeModal: () => void;
  payHandler: (id: string) => void;
  billId: string | null;
};

export default function ErrorBillModal(props: PropsT) {
  const history = useHistory();
  const { isOpen, closeModal, payHandler, billId } = props;
  const { payError } = useSelector<AppState, BillsState>(
    (state) => state.bills
  );

  const navigateToPaymentsPage = useCallback(() => {
    history.push("/payment-details", { toBills: true });
  }, [history]);

  return (
    <>
      <Modal isOpen={isOpen} closeModal={closeModal}>
        <ModalHeader>Error</ModalHeader>

        <ModalContent>
          {payError ||
            "Your payment was unsuccessful, please provide proper banking account information"}
        </ModalContent>

        <ModalFooter className={styles.footer}>
          <Button variant="outlined" onClick={navigateToPaymentsPage}>
            Go to payments page
          </Button>
          <Button
            variant="outlined"
            onClick={() => billId && payHandler(billId)}
            className={styles.btnIndentation}
          >
            Pay with current account
          </Button>
          <Button onClick={closeModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
