import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../Button/Button";
import ModalHeader from "../Modal/ModalHeader";
import ModalContent from "../Modal/ModalContent";
import ModalFooter from "../Modal/ModalFooter";
import { AppState } from "../../store/modules";
import { deleteUserById, StateT } from "../../store/modules/users";
import { UserT } from "../../api/users";
import styles from "../../containers/Bills/Bills.module.scss";

type PropsT = {
  closeModal?: () => void;
};

const DeleteUserModal = (props: PropsT) => {
  const dispatch = useDispatch();

  const { closeModal } = props;

  const { users, deleteUserError: error, deleteId } = useSelector<
    AppState,
    StateT
  >((state) => state.users);

  //@ts-ignore
  const { name = "", email = "" } =
    users && users.find((usr: UserT) => usr.id === deleteId);

  const deleteUser = useCallback(
    () => deleteId && dispatch(deleteUserById(deleteId)),
    [deleteId, dispatch]
  );

  return (
    <>
      <ModalHeader>Delete user?</ModalHeader>

      <ModalContent>
        {error ? (
          <div className={styles.negative}>{error}</div>
        ) : (
          <>
            <div>{name}</div>
            <div>{email}</div>
          </>
        )}
      </ModalContent>

      <ModalFooter className={styles.footer}>
        <Button variant="outlined" onClick={deleteUser}>
          Delete
        </Button>
        <Button onClick={closeModal}>Cancel</Button>
      </ModalFooter>
    </>
  );
};

export default DeleteUserModal;
