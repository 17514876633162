import React, { ReactNode } from "react";
import cn from "classnames";
import styles from "./Button.module.scss";

const buttonType = ["button", "submit", "reset", undefined] as const;
type ButtonType = typeof buttonType[number];

type PropsT = {
  children: ReactNode;
  type?: ButtonType;
  onMouseLeave?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  className?: string;
  disabled?: boolean;
  variant?: string;
  form?: string;
};

const Button = (props: PropsT) => {
  const {
    children,
    type = "button",
    onClick,
    onMouseLeave,
    className,
    disabled = false,
    variant,
    ...rest
  } = props;
  return (
    <button
      type={type}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      className={cn(
        styles.button,
        variant === "outlined" ? styles.outlinedButton : styles.textButton,
        className
      )}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
