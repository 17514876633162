import React, { useCallback } from 'react';
import { useSelector, useDispatch } from "react-redux";
import BaselineForm from "../BaselineForm/BaselineFormWithFormik";
import { AppState } from "../../store/modules";
import { updateUserBaseline, resetUpdateState, StateT as UsersState } from "../../store/modules/users";
import { BaselineT } from '../../api/users';

type PropsT = {
  closeModal: () => void,
}

export default function EditUserModal(props: PropsT) {
  const dispatch = useDispatch();

  const { selectedUser, updateBaselineData, isEdit } = useSelector<AppState, UsersState>(({ users }) => users);

  const handleSubmit = useCallback((data: BaselineT) => {
    if (!selectedUser?.baseline?.homeId) {
      return;
    }

    dispatch(updateUserBaseline(selectedUser.baseline.homeId, data));
  }, [dispatch, selectedUser]);

  const onComponentUnmount = useCallback(() => {
    dispatch(resetUpdateState());
  }, [dispatch]);

  if (!selectedUser) {
    return null;
  }

  const { baseline } = selectedUser;

  return (
    <BaselineForm
      isHidden={false}
      closeModal={props.closeModal}
      goBack={props.closeModal}
      onSubmit={handleSubmit}
      sendError={updateBaselineData.error}
      inviting={updateBaselineData.updating}
      invited={updateBaselineData.updated}
      initialValues={baseline}
      submitText="Edit"
      onComponentUnmount={onComponentUnmount}
      isEdit={isEdit}
    />
  );
}
