import axios from "./axiosConfig";

export function getPowerProviders() {
  return axios.get("/admin/powersuppliers");
}

export function getRates(id: string) {
  return axios.get(`/admin/powersuppliers/${id}/rates`);
}

export function updateLatestRate(powerSupplierId: string, rateId: string, data: any) {
  return axios.post(`/admin/powersuppliers/${powerSupplierId}/rates/${rateId}/definition`, data);
}

export function getUpdatedRateList(powerSupplierId: string, rateId: string) {
  return axios.get(`/admin/powersuppliers/${powerSupplierId}/rates/${rateId}`);
}

export function getLastUpdatedRate(powerSupplierId: string, rateId: string, date: string) {
  return axios.get(`/admin/powersuppliers/${powerSupplierId}/rates/${rateId}/value/${date}`);
}
