import authorization from "./authorization";
import users from "./users";
import additionalInfo from "./additionalInfo";
import dashboard from "./dashboard";
import paymentDetails from "./paymentDetails";
import inviteUser from "./inviteUser";
import bills from "./bills";

export default [
  authorization,
  users,
  additionalInfo,
  dashboard,
  paymentDetails,
  inviteUser,
  bills,
];
