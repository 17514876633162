import React from "react";
import CircleChart from "../CircleChart/CircleChartContainer";
import TextWithDate from "../TextWithDate/TextWithDate";
import styles from "./CurrentUsage.module.scss";

type PropsT = {
  date: string;
  onPeakConsumption?: number;
  offPeakConsumption?: number;
  totalGeneration?: number;
  predictedGeneration: number;
  predictedOffPeak: number;
  predictedOnPeak: number;
};

export default function CurrentUsage({
  date,
  onPeakConsumption = 0,
  offPeakConsumption = 0,
  totalGeneration = 0,
  predictedGeneration,
  predictedOffPeak,
  predictedOnPeak,
}: PropsT) {
  return (
    <>
      <TextWithDate
        title="Current Usage"
        date={date}
        className={styles.currentUsageTitle}
      />
      <CircleChart
        onPeakConsumption={onPeakConsumption}
        offPeakConsumption={offPeakConsumption}
        totalGeneration={totalGeneration}
        predictedGeneration={predictedGeneration}
        predictedOffPeak={predictedOffPeak}
        predictedOnPeak={predictedOnPeak}
        className={styles.circleChart}
      />
    </>
  );
}
