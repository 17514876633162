import React from "react";

type PropsT = {
  order: "asc" | "desc";
  isSorted: boolean;
};

export default function SortIcon(props: PropsT) {
  const { order, isSorted } = props;
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 8L4 11L7 8"
        stroke={isSorted && order === "asc" ? "#ffffff" : "#9DA5B9"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 4L4 1L7 4"
        stroke={isSorted && order === "desc" ? "#ffffff" : "#9DA5B9"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
