import React, { useEffect } from "react";
import {
  ComposedChart,
  Area,
  Bar,
  XAxis,
  ReferenceLine,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { useMediaQuery } from "react-responsive";

import CustomTooltip from "../CustomChartTooltip/CustomChartTooltip";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store/modules";
import { StateT as AuthState } from "../../store/modules/authorization";
import {
  calculatePicksIntervals,
  calculatePickHours,
  transformedData,
  calculatePick,
  calculatePickValue,
  calculateOnPeak,
  calculateOffPeak,
  calculateConsumption,
} from "../../helpers/grid";
import { formattedHours } from "../../constants";
import styles from "./MobileChart.module.scss";
import colors from "../../assets/style/variables/colors.scss";
import { useParams } from "react-router-dom";
import { getUserInfo, StateT as userState } from "../../store/modules/users";

type PropsT = {
  energy: any;
};

const MobileChart = (props: PropsT) => {
  const {
    energy: { peaks, hours },
  } = props;
  const { currentRate } = useSelector<AppState, AuthState>(
    (state) => state.authorization
  );
  const {userId} = useParams<any>();
  const dispatch = useDispatch();

  const {isFlat} = useSelector<AppState, userState>(
    (state) => state.users
  );

  useEffect(() => {
    if ((!currentRate?.flat && !isFlat && userId)) {
      dispatch(getUserInfo(userId));
    }
  }, [currentRate, dispatch, isFlat, userId])
  const pickIntervals = calculatePicksIntervals(peaks);
  const peaksHours = calculatePickHours(peaks);
  const peakValue = calculatePickValue(hours);
  const isLandscape = useMediaQuery({ query: "(orientation: landscape)" });

  const chartData = transformedData.reduce(
    (acc: Array<any>, curr: any, index: number) => {
      const isPeak = peaksHours.includes(index);
      const hour = index;
      return [
        ...acc,
        {
          ...hours[index],
          hour,
          peak1: isPeak && pickIntervals[0]?.includes(hour) ? peakValue : 0,
          peak2: isPeak && pickIntervals[1]?.includes(hour) ? peakValue : 0,
          peak3: isPeak && pickIntervals[1]?.includes(hour) ? peakValue : 0,
          ...{
            [isPeak ? "onPeakConsumption" : "offPeakConsumption"]: hours[index]
              ?.consumption,
          },
        },
      ];
    },
    []
  );

  return (
    <div>
      <ResponsiveContainer
        height="100%"
        width="100%"
        aspect={isLandscape ? 3.0 : 4.0 / 3.0}
      >
        <ComposedChart
          margin={{ left: 17, top: 22 }}
          data={chartData}
          stackOffset="sign"
        >
          <CartesianGrid strokeDasharray="3 3" horizontal={false} />
          <XAxis
            dataKey="hour"
            axisLine={false}
            tick={{
              stroke: colors["grey"],
              strokeWidth: 0.5,
              fontWeight: 300,
              fontSize: 12,
            }}
            domain={[0, 23]}
            ticks={[0, 4, 8, 12, 16, 20]}
            tickFormatter={(timeStr) => formattedHours[timeStr]}
            padding={{ left: 0, right: 4 }}
            type="number"
            tickLine={false}
            interval={0}
          />
          {pickIntervals.map((peak: Array<number>, idx: number) => [
            <Area
              type="step"
              dataKey={`peak${idx + 1}`}
              fill="#f86552"
              fillOpacity={0.06}
              stroke="#f86552"
              strokeOpacity={0.06}
              className={styles[`area${idx + 1}`]}
            />,
            <Area
              type="step"
              dataKey={(info) => calculatePick(info, idx + 1)}
              fill="#f86552"
              fillOpacity={0.06}
              stroke="#f86552"
              strokeOpacity={0.06}
              className={styles[`area${idx + 1}`]}
            />,
          ])}
          {(!currentRate?.flat && !isFlat) && (
            <Bar
              dataKey={calculateOnPeak}
              barSize={10}
              fill={colors["dark-ocean"]}
              stackId="stack"
              radius={2}
            />
          )}
          {(!currentRate?.flat && !isFlat) && (
            <Bar
              dataKey={calculateOffPeak}
              barSize={10}
              fill={colors["cyan"]}
              stackId="stack"
              radius={2}
            />
          )}
          {(currentRate?.flat || isFlat) && (
            <Bar
              dataKey={calculateConsumption}
              barSize={10}
              fill={colors["cyan"]}
              stackId="stack"
              radius={2}
            />
          )}
          <Bar
            dataKey="generation"
            barSize={10}
            fill={colors["orange"]}
            stackId="stack"
            radius={2}
          />
          <ReferenceLine
            y={0}
            stroke={colors["grey"]}
            strokeOpacity={0.2}
            strokeWidth={1}
          />
          <Tooltip
            content={<CustomTooltip />}
            position={{ x: 0, y: -146 }}
            cursor={{ stroke: colors["black"], strokeWidth: 2 }}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MobileChart;
