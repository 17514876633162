import React from "react";
import { useTable } from "react-table"
import cx from "classnames";

import styles from "./Table.module.scss";

type PropsT = {
  columns: any,
  data: any,
  className?: string,
  headClassName?: string,
};

function Table(props: PropsT) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns: props.columns,
    data: props.data,
  });

  return (
    <table className={cx(styles.table, props.className)} {...getTableProps()}>
      <thead className={cx(props.headClassName)}>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => {
              return (
                <th {...column.getHeaderProps()} style={column.width ? { width: `${column.width}px`} : {}}>
                  {column.render('Header')}
                </th>
              )
            })}
          </tr>
        ))}
      </thead>

      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);

          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default Table;
