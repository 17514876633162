/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Portal } from "react-overlays";

import Button from "../Button/Button";
import ModalHeader from "../Modal/ModalHeader";
import ModalFooter from "../Modal/ModalFooter";
import { AppState } from "../../store/modules";
import { StateT as UsersState, getUsersList } from "../../store/modules/users";

import "./RateModal.scss";
import { Field, Form, Formik, FormikConfig, FormikProps } from "formik";
import SelectField from "../SelectField/SelectField";
import {
  getRates,
  StateT as InfoState,
} from "../../store/modules/additionalInfo";
import { updateUserRate, getUserRate } from "../../api/users";
import { dateFormat, formatMonthDayYear } from "../../helpers/formatDate";
import ReactDatePicker from "react-datepicker";

type PropsT = {
  closeModal: () => void;
  setSuccessRatesModal: (val: boolean) => void;
};

const UserDetailsModal = (props: PropsT) => {
  const currentDate = new Date();
  const dispatch = useDispatch();
  const { closeModal, setSuccessRatesModal } = props;
  const [date, setDate] = useState<any>(
    currentDate.setDate(currentDate.getDate() - 1)
  );
  const [rateData, setRateData] = useState([]);

  const { selectedUser } = useSelector<AppState, UsersState>(
    (state) => state.users
  );

  const { rates } = useSelector<AppState, InfoState>(
    (state) => state.additionalInfo
  );

  useEffect(() => {
    if (selectedUser?.supplier?.id) {
      dispatch(getRates(selectedUser.supplier.id));
    }
  }, [selectedUser?.supplier?.id]);

  useEffect(() => {
    if (selectedUser?.id) {
      (async () => {
        const result = await getUserRate(selectedUser.id);
        if (result.status === 200) {
          setRateData(result.data.rateHistory);
        }
      })();
    }
  }, [selectedUser?.id]);

  if (!selectedUser) return null;

  type UserFormT = {
    rate: { label: string; value: string | number };
  };

  const { supplier } = selectedUser;

  const ratesOptions = rates
    ? rates.map((rate: any) => ({
        label: rate.name,
        value: rate.id,
      }))
    : [];

  const onSubmit = async (values: any) => {
    const data = {
      rate_id: values.rate.value,
      date,
    };
    const result = await updateUserRate(selectedUser.id, data);
    if (result.status === 200) {
      setSuccessRatesModal(true);
      closeModal();
      dispatch(getUsersList());
    }
  };

  const userRate = supplier?.rates[supplier.rates.length - 1] || null;

  const form: FormikConfig<UserFormT> = {
    initialValues: {
      rate: {
        label: userRate?.name,
        value: userRate?.id,
      },
    },
    onSubmit,
    enableReinitialize: true,
  };

  const handleChangeDate = (date: Date) => setDate(date);

  const CalendarContainer = ({ children }: any) => {
    const el = document.getElementById("calendar-portal");

    return <Portal container={el}>{children}</Portal>;
  };

  return (
    <Formik {...form}>
      {(formik: FormikProps<UserFormT>): React.ReactNode => {
        const { values, dirty } = formik;
        return (
          <Form noValidate>
            <ModalHeader>User Rate details</ModalHeader>

            <div className={"rateModalWrapper"}>
              <div className={"date"}>
                <p>Valid from:&nbsp;&nbsp;&nbsp;</p>
                <ReactDatePicker
                  selected={date}
                  onChange={handleChangeDate}
                  dateFormat={dateFormat}
                  showMonthDropdown
                  showYearDropdown
                  popperContainer={CalendarContainer}
                />
              </div>
              <div style={{ alignItems: "center" }} className={"fieldWrapper"}>
                <div className={"name"}>Rate</div>
                <Field
                  component={SelectField}
                  variant="outline"
                  name="rate"
                  placeholder="Select your power rate"
                  options={ratesOptions}
                  defaultValue={values.rate}
                />
              </div>
              <div className="history">
                <div className={"historyLabel"}>Change history:</div>
                <div className={"tableWrapper"}>
                  <b className={"box"}>Rate</b>
                  <b className={"box"}>Valid From</b>
                  <b className={"box"}>Valid To</b>

                  {rateData?.length
                    ? rateData.map((item: any) => (
                        <div className={"row"} key={item.validFrom.toString()}>
                          <div className={"box"}>{item.name}</div>
                          <div className={"box"}>
                            {formatMonthDayYear(item.validFrom)}
                          </div>
                          <div className={"box"}>
                            {formatMonthDayYear(item.validTo)}
                          </div>
                        </div>
                      ))
                    : "-"}
                </div>
              </div>
            </div>
            <div className={"footer"}>
              <ModalFooter>
                <Button
                  variant="outlined"
                  type="submit"
                  className={"buttonPrimary"}
                  disabled={!dirty}
                >
                  Save Changes
                </Button>
                <Button onClick={closeModal}>Cancel</Button>
              </ModalFooter>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UserDetailsModal;
