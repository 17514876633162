import React from "react";
import { useSelector, useDispatch } from "react-redux";

import Title from "./Title";
import { AppState } from "../../store/modules";
import { StateT as AuthState } from "../../store/modules/authorization";
import {
  changeEnergyReportPeriod,
  StateT as DashboardState,
} from "../../store/modules/dashboard";
import { OptionT, timeOptions } from "../../constants";

const DashboardTitle = () => {
  const dispatch = useDispatch();
  const { name } = useSelector<AppState, AuthState>(
    (state) => state.authorization
  );

  const { period, energy } = useSelector<AppState, DashboardState>(
    (state) => state.dashboard
  );

  const selectedValue = timeOptions.find(
    (item: OptionT) => item.value === period
  );

  const saved =
    energy?.totalSavings && energy?.totalSavings > 0
      ? energy?.totalSavings
      : 0;

  const onChange = (option: OptionT) => {
    dispatch(changeEnergyReportPeriod(option.value));
  };

  return (
    <Title
      title={`Hello ${name}, You saved `}
      onChange={onChange}
      selectedValue={selectedValue}
      valueDisplay={saved}
    />
  );
};

export default DashboardTitle;
