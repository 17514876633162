import Styles from "react-select";

export const selectFieldStyle: Partial<Styles> = {
  control: (provided: any) => ({
    ...provided,
    border: "none",
    background: "transparent",
    cursor: "pointer",
    outline: "none",
    boxShadow: "none",
  }),
  singleValue: (provided: any) => ({
    ...provided,
    textTransform: "lowercase",
    color: "#0F1F48",
    paddingBottom: "2px",
    borderBottom: "2px solid #D4DAE9",
    position: "static",
    top: 0,
    transform: "none",
    maxWidth: "100%",
    textOverflow: "none"
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: "none",
  }),
  container: (provided: any) => ({
    ...provided,
    display: "inline-block",
    verticalAlign: "baseline"
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    display: "none",
  }),
  menu: (provided: any) => ({
    ...provided,
    top: "initial",
    margin: 0,
    maxWidth: "fit-content",
  }),
  menuList: (provided: any) => ({
    ...provided,
    padding: 0,
    borderRadius: "4px",
  }),
  option: (provided: any) => ({
    ...provided,
    fontWeight: 500,
    fontSize: "12px",
    letterSpacing: "0.1px",
    textAlign: "center",
    borderBottom: "1px solid #F3F7FB",
    padding: "4px 12px",
  }),
};
