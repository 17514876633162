import React, { useMemo } from "react";
import cx from "classnames";
import styles from "./Loading.module.scss";

export function Loader({ size, className }: { size?: number, className?: string }) {
  const style = useMemo(() => {
    return size
      ? { fontSize: `${size}px`, width: '1em', height: '1em' }
      : {}
  }, [size]);

  return (
    <div className={cx(styles.loaderWrapper, className)} style={style}>
      <div className={styles.loaderContainer}>
        <div className={styles.loader}>
          <span />
        </div>
      </div>
    </div>
  );
}

const Loading = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.element}>
          <div className={styles.loader}>
            <span />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
