import React, { ReactNode, useRef, useState, useEffect, useCallback } from "react";
import styles from "./MenuDrawer.module.scss";
import cx from "classnames";

interface IMenuDrawerParam {
  children: ReactNode;
  offsetY?: number;
  offsetX?: number;
}

const MenuDrawer = (params: IMenuDrawerParam) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const drawerRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [drawerPosition, setDrawerPosition] = useState({ top: 0, left: 0, opacity: 0 });

  const { offsetX = 0, offsetY = 20, children } = params;

  const handleScroll = useCallback(() => {
    const drawerEl = drawerRef.current;
    const buttonEl = buttonRef.current;

    if (!drawerEl || !buttonEl) {
      return;
    }

    const { scrollY } = window;
    const { top, left, width, height } = buttonEl.getBoundingClientRect();
    const { offsetWidth, offsetHeight } = drawerEl;

    let drawerPositionY = top + offsetY;
    let drawerPositionX = left - offsetWidth - offsetX + width;

    if (window.innerHeight + scrollY <= drawerPositionY + offsetHeight + scrollY) {
      drawerPositionY = top - height - offsetHeight - offsetY;
    }

    setDrawerPosition({
      top: drawerPositionY,
      left: drawerPositionX,
      opacity: 1,
    });
  }, [offsetX, offsetY]);

  const handleClickOutside = useCallback(() => {
    setDrawerPosition({
      top: 0,
      left: 0,
      opacity: 0,
    });
    setIsOpen(false);
    document.removeEventListener("click", handleClickOutside, false);
    document.removeEventListener("scroll", handleScroll, false);
    window.removeEventListener("resize", handleScroll, false);
    document.querySelectorAll("[data-scrollable]").forEach((e) => e.removeEventListener("scroll", handleScroll, false));
  }, [setIsOpen, handleScroll]);

  const openDrawer = () => {
    document.addEventListener("scroll", handleScroll, false);
    window.addEventListener("resize", handleScroll, false);
    document.querySelectorAll("[data-scrollable]").forEach((e) => e.addEventListener("scroll", handleScroll, false));

    setIsOpen(true);

    setTimeout(() => {
      handleScroll();
    });
  };

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        document.addEventListener("click", handleClickOutside, false);
      });
    }
  }, [isOpen, handleClickOutside])

  useEffect(() => {
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
      document.removeEventListener("scroll", handleScroll, false);
      window.removeEventListener("resize", handleScroll, false);
      document.querySelectorAll("[data-scrollable]").forEach((e) => e.removeEventListener("scroll", handleScroll, false));
    };
  }, [handleClickOutside, handleScroll]);

  return (
    <div className={styles.content}>
      <button ref={buttonRef} className={cx(styles.button, "clickExpand", { isActive: isOpen })} type="button" onClick={openDrawer}>
        {[...Array(3)].map((v, i) => (
          <span key={i} className={styles.dot}></span>
        ))}
      </button>

      {isOpen && (
        <div className={styles.drawer} style={drawerPosition} ref={drawerRef}>
          {children}
        </div>
      )}
    </div>
  );
};

export default MenuDrawer;
