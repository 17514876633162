import React, { ReactNode, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isMobileOnly } from "react-device-detect";
import cx from "classnames";
import { useParams } from "react-router-dom";

import WhiteBlock from "../../components/WhiteBlock/WhiteBlock";
import TextWithDate from "../../components/TextWithDate/TextWithDate";
import Loading from "../../components/Loading/Loading";
import CurrentUsage from "../../components/CurrentUsage/CurrentUsage";
import BillingAndPaymentHistory from "../../components/BillingAndPaymentHistory/BillingAndPaymentHistory";
import LastYearComparison from "../../components/LastYearComparison/LastYearComparison";
import NumericValueFormat from "../../components/NumericValueFormat/NumericValueFormat";
// import getArrowCodeAndClassName from "../../helpers/getArrowCodeAndClassName";
import { AppState } from "../../store/modules";
import {
  getBillsInformation,
  getBillsInformationById,
  StateT as BillsState,
} from "../../store/modules/bills";
import { StateT as AuthState } from "../../store/modules/authorization";

import { ReactComponent as IconBill } from "../../assets/icons/Bill.svg";
import { ReactComponent as IconSaving } from "../../assets/icons/Saving.svg";
import styles from "./Bills.module.scss";

type BlockPropsT = {
  children?: ReactNode;
  className: string;
  footer?: () => ReactNode;
  wrapperClassName?: string;
};

function Block(props: BlockPropsT) {
  return (
    <div className={props.className}>
      <WhiteBlock className={props.wrapperClassName}>
        {props.children}

        {props.footer && (
          <>
            <div className={styles.delimiter} />

            {props.footer()}
          </>
        )}
      </WhiteBlock>
    </div>
  );
}

type TextDataT = {
  title: string;
  date: string;
  amount: number;
};

type TextTemplateT = {
  data: Array<TextDataT>;
};

function TextTemplate(props: TextTemplateT) {
  return (
    <>
      {props.data.map(({ title, date, amount }) => (
        <div key={title} className={cx(styles.headerWrapper, "row")}>
          <TextWithDate
            title={title}
            date={date}
            className={cx({ [styles.centeredTitle]: !date })}
          />
          <div className={styles.bill}>
            <NumericValueFormat value={amount} isMoney decimalScale={2} />
          </div>
        </div>
      ))}
    </>
  );
}

function usePageData() {
  const { admin } = useSelector<AppState, AuthState>(
    (state) => state.authorization
  );
  const initLoad = useRef<boolean | null>(null);
  const dispatch = useDispatch();
  const { upcoming, closed, error, loading, paying } = useSelector<
    AppState,
    BillsState
  >((state) => state.bills);
  let { userId }: any = useParams();

  useEffect(() => {
    if (!initLoad.current || !paying) {
      initLoad.current = true;
      if (admin) {
        dispatch(getBillsInformationById(userId));
      } else {
        dispatch(getBillsInformation());
      }
    }
  }, [dispatch, initLoad, paying, admin, userId]);

  return {
    upcoming,
    closed,
    loading,
    error,
    paying,
  };
}

export default function Bills() {
  const { upcoming, loading, paying } = usePageData();

  const onPeakConsumption = upcoming?.onPeakConsumption;
  const offPeakConsumption = upcoming?.offPeakConsumption;
  const totalGeneration = upcoming?.totalGeneration;

  const savingBlock: Array<TextDataT> = [
    {
      title: "Savings to date",
      date: upcoming?.upcoming.currentPeriod || "",
      amount: upcoming?.upcoming.currentSavings || 0,
    },
    {
      title: "Projected monthly savings",
      date: upcoming?.upcoming.projectedPeriod || "",
      amount: upcoming?.upcoming.projectedSavings || 0,
    },
  ];
  const billsBlock: Array<TextDataT> = [
    {
      title: "Expected charges to date",
      date: upcoming?.upcoming.currentPeriod || "",
      amount: upcoming?.upcoming.currentCharge || 0,
    },
    {
      title: "Projected monthly charges",
      date: upcoming?.upcoming.projectedPeriod || "",
      amount: upcoming?.upcoming.projectedCharge || 0,
    },
  ];

  if (loading || paying) {
    return <Loading />;
  }

  return (
    <div>
      <div className={cx("row", styles.container, { mobile: isMobileOnly })}>
        <Block
          className="col-4"
          wrapperClassName={styles.blockWrapper}
        >
          <IconSaving className={styles.icon} />

          <TextTemplate data={savingBlock} />
        </Block>

        <Block
          className="col-4"
          wrapperClassName={styles.blockWrapper}
        >
          <IconBill className={styles.icon} />

          <TextTemplate data={billsBlock} />
        </Block>

        <Block className="col-4" wrapperClassName={styles.blockWrapper}>
          <CurrentUsage
            date={upcoming?.upcoming.currentPeriod || ""}
            onPeakConsumption={onPeakConsumption}
            offPeakConsumption={offPeakConsumption}
            totalGeneration={totalGeneration}
            predictedGeneration={upcoming?.predictedGeneration || 0}
            predictedOffPeak={upcoming?.predictedOffPeak || 0}
            predictedOnPeak={upcoming?.predictedOnPeak || 0}
          />
        </Block>

        <Block className="col-6">
          <BillingAndPaymentHistory />
        </Block>

        <Block className="col-6">
          <LastYearComparison />
        </Block>
      </div>
    </div>
  );
}
