import React from "react";
import ReactTooltip from "react-tooltip";
import { ReactComponent as ExceedIcon } from "../../assets/icons/Exceed.svg";
import styles from "./MissedDataMark.module.scss";

export default function MissedDataMark() {
  return (
    <>
      <ExceedIcon
        className={styles.mark}
        data-for="tooltip"
        data-tip="tooltip"
      />
      <ReactTooltip id="tooltip" backgroundColor="#f3f7fb" textColor="#000000">
        User has missed baseline data
      </ReactTooltip>
    </>
  );
}
