import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { ConnectedRouter as Router } from "connected-react-router";
import { isMobileOnly } from "react-device-detect";

import AuthorizationPage from "../AuthorizationPage/AuthorizationPage";
import PaymentDetails from "../PaymentDetails/PaymentDetails";
import Dashboard from "../Dashboard/Dashboard";
import { history } from "../../store";

import "react-input-range/lib/css/index.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-quill/dist/quill.snow.css";

const NotFoundPage: React.FC = () => <div>Not Found Page</div>;

function App() {
  useEffect(() => {
    document.body.classList.toggle('mobile', isMobileOnly);
  }, []);

  return (
      //@ts-ignore
    <Router history={history}>
      <Switch>
        <Route exact path="/auth/:path" component={AuthorizationPage} />
        <Route exact path="/payment-details" component={PaymentDetails} />
        <Route exact path="/404" component={NotFoundPage} />
        <Route path="/" component={Dashboard} />
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </Router>
  );
}

export default App;
