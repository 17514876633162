import React, { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Modal from "./../../components/Modal/Modal";
import Button from "../../components/Button/Button";
import EditFPLRates from "../EditFPLRates/EditFPLRates";
import SearchInput from "../../components/SearchInput/SearchInput";
import TableUsersList from "../../components/TableUsersList/TableUsersList";
import InviteUser from "../../components/InviteUser/InviteUser";
import Loading from "../../components/Loading/Loading";
import UserDetailsModal from "../../components/UserDetailsModal/UserDetailsModal";
import UserEnergyModal from "../../components/UserEnergyModal/UserEnergyModal";
import EditUserModal from "../../components/EditUserModal/EditUserModal";
import SuccessModal from "../../components/SuccessModal/SuccessModal";
import SavingShareModal from "../../components/SavingShareModal/SavingShareModal";
import AdditionalServicesModal from "../../components/AdditionalServicesModal/AdditionalServicesModal";
import ReferralLinkModal from "../../components/ReferralLinkModal/ReferralLinkModal";
import RateModal from "../../components/RateModal/RateModal";
import { AppState } from "../../store/modules";
import { showRatesHistory, StateT as InfoState } from "../../store/modules/additionalInfo";
import {
  StateT as UsersState,
  switchUserDetails,
  closeUserEnergy,
  toggleUserEditModal,
  StateT,
  switchSavingShareModal,
  switchAdditionalServicesModal,
  switchReferralLinkModal,
  switchRateModal,
} from "../../store/modules/users";
import { StateT as InviteUsersState } from "../../store/modules/inviteUser";

import { ReactComponent as FilterIcon } from "../../assets/icons/Filter.svg";
import styles from "./UsersListPage.module.scss";
import SuccessAlert from "../../components/SuccessAlert/SuccessAlert";

export const DEFAULT_MODAL_WIDTH = 486;
const modalStyle = { width: `${DEFAULT_MODAL_WIDTH}px` };
const energyModalStyle = {
  width: "800px",
};

const UsersListPage = () => {
  const dispatch = useDispatch();
  const {
    loading,
    isUserDetailsOpen,
    isEnergyModalOpen,
    isBaselineModalOpen,
    isSavingShareModalOpen,
    isAdditionalServicesModalOpen,
    selectedUser,
    isReferralLinkModalOpen,
    isRateModalOpen,
  } = useSelector<AppState, UsersState>((state) => state.users);
  const [inviteUserModalWidth, setInviteUserModalWidth] = useState<
    string | number
  >(DEFAULT_MODAL_WIDTH);

  const { invited } = useSelector<AppState, InviteUsersState>(
    (state) => state.inviteUser
  );

  const { updatedRates, showTable } = useSelector<AppState, InfoState>(
    (state) => state.additionalInfo
  );

  const { deleted } = useSelector<AppState, StateT>((state) => state.users);

  const [successModalIsOpen, setIsSuccessOpen] = useState(false);
  const [successDeleteIsOpen, setIsDeletedOpen] = useState(false);
  const [successEditFPLRates, setSuccessEditFPLRates] = useState(false);
  const [successEditAdditionalCharges, setSuccessEditAdditionalCharges] =
    useState(false);
  const [InviteModalIsOpen, setInviteIsOpen] = useState(false);
  const [FPLModalIsOpen, setFPLModalIsOpen] = useState(false);
  const [updateUserSuccess, setUpdateUserSuccess] = useState(false);
  const [successReferralLinkModal, setSuccessReferralLinkModal] =
    useState(false);
  const [successRatesModal, setSuccessRatesModal] = useState(false);

  useEffect(() => {
    if (invited) {
      setIsSuccessOpen(true);
      setTimeout(closeSuccessModal, 2000);
    }
  }, [invited]);

  useEffect(() => {
    if (deleted) {
      setIsDeletedOpen(true);
      setTimeout(closeDeleteModal, 2000);
    }
  }, [deleted]);

  useEffect(() => {
    if (successEditFPLRates) {
      setTimeout(() => {
        setSuccessEditFPLRates(false);
      }, 2000);
    }
    if (successEditAdditionalCharges) {
      setTimeout(() => {
        setSuccessEditAdditionalCharges(false);
      }, 2000);
    }
    if (successReferralLinkModal) {
      setTimeout(() => {
        setSuccessReferralLinkModal(false);
      }, 2000);
    }
    if (updateUserSuccess) {
      setTimeout(() => {
        setUpdateUserSuccess(false);
      }, 2000);
    }
    if (successRatesModal) {
      setTimeout(() => {
        setSuccessRatesModal(false);
      }, 2000);
    }
  }, [
    successEditFPLRates,
    updateUserSuccess,
    successEditAdditionalCharges,
    successReferralLinkModal,
    successRatesModal,
  ]);

  function closeSuccessModal() {
    setIsSuccessOpen(false);
  }

  function closeDeleteModal() {
    setIsDeletedOpen(false);
  }

  const closeEditUserModal = useCallback(() => {
    dispatch(toggleUserEditModal(false));
  }, [dispatch]);

  function openInviteModal() {
    setInviteIsOpen(true);
  }

  function closeInviteModal() {
    setInviteIsOpen(false);
    setInviteUserModalWidth(DEFAULT_MODAL_WIDTH);
  }

  function closeUserDetailsModal() {
    dispatch(switchUserDetails({ isUserDetailsOpen: false, id: null }));
  }

  function closeUserEnergyModal() {
    dispatch(closeUserEnergy());
  }

  function closeSavingShareModal() {
    dispatch(switchSavingShareModal(false));
  }

  function closeAdditionalServicesModal() {
    dispatch(switchAdditionalServicesModal(false));
  }

  function closeReferralLinkModal() {
    dispatch(switchReferralLinkModal(false));
  }

  function closeRateModal() {
    dispatch(switchRateModal(false));
  }

  function closeTableModal() {
    dispatch(showRatesHistory(false));
    setFPLModalIsOpen(false);
  }

  function renderDetailsModals() {
    if (isBaselineModalOpen) {
      return (
        <Modal
          isOpen={isBaselineModalOpen}
          closeModal={closeEditUserModal}
          style={{}}
        >
          <EditUserModal closeModal={closeEditUserModal} />
        </Modal>
      );
    }

    return (
      <Modal
        isOpen={isUserDetailsOpen}
        closeModal={closeUserDetailsModal}
        style={modalStyle}
      >
        <UserDetailsModal />
      </Modal>
    );
  }

  if (loading) return <Loading />;

  return (
    <>
      <div className="wrap">
        <div className={styles.header}>
          <SearchInput />
          <SuccessAlert
            isOpen={
              successEditFPLRates ||
              updateUserSuccess ||
              successEditAdditionalCharges ||
              successReferralLinkModal ||
              successRatesModal
            }
            message={
              successReferralLinkModal
                ? "Successfully created"
                : "Information was updated"
            }
          />
          <button className={styles.filter}>
            <FilterIcon className={styles.filterIcon} />
            <span className={styles.filterTitle}>Filter (0)</span>
          </button>
          <Button
            variant="outlined"
            className={styles.inviteButton}
            onClick={() => setFPLModalIsOpen(true)}
          >
            Edit Power Provider Rates
          </Button>
          <Button
            variant="outlined"
            className={styles.inviteButton}
            onClick={openInviteModal}
          >
            + Invite User
          </Button>
        </div>
      </div>

      <TableUsersList />

      <Modal
        isOpen={FPLModalIsOpen}
        closeModal={closeTableModal}
        style={
          updatedRates?.length && showTable
            ? { width: "90%", maxWidth: "1660px" }
            : {
                width:
                  typeof inviteUserModalWidth === "number"
                    ? `${inviteUserModalWidth}px`
                    : inviteUserModalWidth,
              }
        }
      >
        <EditFPLRates
          setSuccessEditFPLRates={setSuccessEditFPLRates}
          closeModal={closeTableModal}
        />
      </Modal>

      <Modal
        isOpen={InviteModalIsOpen}
        closeModal={closeInviteModal}
        style={{
          width:
            typeof inviteUserModalWidth === "number"
              ? `${inviteUserModalWidth}px`
              : inviteUserModalWidth,
        }}
      >
        <InviteUser
          closeModal={closeInviteModal}
          setInviteUserModalWidth={setInviteUserModalWidth}
        />
      </Modal>

      <Modal
        isOpen={isEnergyModalOpen}
        closeModal={closeUserEnergyModal}
        style={energyModalStyle}
      >
        <UserEnergyModal closeModal={closeUserEnergyModal} />
      </Modal>

      <Modal isOpen={successModalIsOpen} closeModal={closeSuccessModal}>
        <SuccessModal title="invited" />
      </Modal>

      <Modal isOpen={successDeleteIsOpen} closeModal={closeDeleteModal}>
        <SuccessModal title="deleted" />
      </Modal>

      {renderDetailsModals()}

      <Modal
        isOpen={!!selectedUser?.id && isSavingShareModalOpen}
        closeModal={closeSavingShareModal}
        style={modalStyle}
      >
        {!!selectedUser?.id && isSavingShareModalOpen && (
          <SavingShareModal closeModal={closeSavingShareModal} />
        )}
      </Modal>
      <Modal
        isOpen={!!selectedUser?.id && isAdditionalServicesModalOpen}
        closeModal={closeAdditionalServicesModal}
        style={modalStyle}
      >
        {!!selectedUser?.id && isAdditionalServicesModalOpen && (
          <AdditionalServicesModal
            setSuccessEditAdditionalCharges={setSuccessEditAdditionalCharges}
            closeModal={closeAdditionalServicesModal}
          />
        )}
      </Modal>
      <Modal
        isOpen={isReferralLinkModalOpen}
        closeModal={closeReferralLinkModal}
        style={modalStyle}
      >
        {!!selectedUser?.id && isReferralLinkModalOpen && (
          <ReferralLinkModal
            setSuccessReferralLinkModal={setSuccessReferralLinkModal}
            closeModal={closeReferralLinkModal}
          />
        )}
      </Modal>
      <Modal
        isOpen={!!selectedUser?.id && isRateModalOpen}
        closeModal={closeRateModal}
        style={modalStyle}
      >
        {!!selectedUser?.id && isRateModalOpen && (
          <RateModal
            setSuccessRatesModal={setSuccessRatesModal}
            closeModal={closeRateModal}
          />
        )}
      </Modal>
    </>
  );
};

export default UsersListPage;
