import axios, { AxiosResponse } from "axios";
import store from "../store/index";
import { LOGOUT } from "../store/modules/authorization";
import { renewToken } from "./authorization";
import { saveTokens } from "../helpers/authorization";

export const apiURL = () => {
  if (process.env.REACT_APP_BACKEND_ENDPOINT) {
    return process.env.REACT_APP_BACKEND_ENDPOINT;
  } else {
    switch (process.env.NODE_ENV) {
      case "development":
        return "http://sprightful-development.us-east-2.elasticbeanstalk.com";

      case "production":
        return "http://sprightful-development.us-east-2.elasticbeanstalk.com";

      default:
        return "http://sprightful-development.us-east-2.elasticbeanstalk.com";
    }
  }
};

// eslint-disable-next-line no-console
console.log("test check env", process.env);

const instance = axios.create({
  baseURL: apiURL(),
});

let isRefreshing = false;
let refreshSubscribers: Array<any> = [];

instance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error) => {
    const {
      config,
      response: { status },
    } = error;
    if (!error.response) {
      console.error(error);
      return Promise.reject(error.message);
    }

    const errorMessage = error.response?.data?.error || error.message;

    const originalRequest = config;

    if (status === 403) {
      store.dispatch({ type: LOGOUT });
    }

    if (status === 401) {
      if (!isRefreshing) {
        isRefreshing = true;
        renewToken().then(({ data: { token, refreshToken } }) => {
          isRefreshing = false;
          onRrefreshed(token);
          saveTokens({ token, refreshToken });
        });
      }

      const retryOrigReq = new Promise((resolve, reject) => {
        subscribeTokenRefresh((token: string) => {
          originalRequest.headers["Authorization"] = `Bearer ${token}`;
          resolve(axios(originalRequest));
        });
      });
      return retryOrigReq;
    } else {
      console.error(error);
      return Promise.reject(errorMessage);
    }
  }
);

function subscribeTokenRefresh(cb: any) {
  refreshSubscribers.push(cb);
}

function onRrefreshed(token: string) {
  refreshSubscribers.map((cb) => cb(token));
}

export default instance;
