import { put, takeEvery, call } from "redux-saga/effects";
import {
  SavePaymentDetailsT,
  SAVE_PAYMENT_DETAILS,
  SAVE_PAYMENT_DETAILS_SUCCESS,
  SAVE_PAYMENT_DETAILS_FAILURE,
  LinkBankAccountSuccessT,
  LINK_BANK_ACCOUNT_SUCCESS,
  sendBankToken as sendBankTokenAction,
  sendBankTokenSuccess,
  sendBankTokenFailure,
} from "../modules/paymentDetails";
import { sendBankToken } from "../../api/paymentDetails";

function* savePaymentDetailsSaga(action: SavePaymentDetailsT) {
  try {
    yield put({
      type: SAVE_PAYMENT_DETAILS_SUCCESS,
    });
  } catch (error) {
    yield put({ type: SAVE_PAYMENT_DETAILS_FAILURE, error });
  }
}

function* sendBankTokenSaga(action: LinkBankAccountSuccessT) {
  yield put(sendBankTokenAction());

  try {
    yield call(sendBankToken, {
      token: action.data.token,
      account_id: action.data.account.id,
      account_name: action.data.account.name,
      account_number: action.data.account.mask,
    });
    yield put(sendBankTokenSuccess());
  } catch (error) {
    yield put(sendBankTokenFailure(error));
  }
}

export default function* paymentDetailsSaga() {
  yield takeEvery(SAVE_PAYMENT_DETAILS, savePaymentDetailsSaga);
  yield takeEvery(LINK_BANK_ACCOUNT_SUCCESS, sendBankTokenSaga);
}
