import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";

import MenuDrawer from "../MenuDrawer/MenuDrawer";
import MenuDrawerButton from "../MenuDrawer/MenuDrawerButton";
import NumericValueFormat from "../NumericValueFormat/NumericValueFormat";
import MissedDataMark from "../MissedDataMark/MissedDataMark";
import {
  switchUserDetails,
  openUserEnergy,
  openUserEditModal,
  switchDeleteModal,
} from "../../store/modules/users";
import { resendInvite } from "../../store/modules/inviteUser";
import { UserT } from "../../api/users";
import { apiURL } from "../../api/axiosConfig";

import { ReactComponent as RefreshIcon } from "../../assets/icons/Refresh.svg";
import { ReactComponent as PenIcon } from "../../assets/icons/Pen.svg";
import { ReactComponent as DetailsIcon } from "../../assets/icons/Details.svg";
import { ReactComponent as ChartIcon } from "../../assets/icons/ChartGrey.svg";
import { ReactComponent as Baseline } from "../../assets/icons/Baseline.svg";
import { ReactComponent as TrashcanIcon } from "../../assets/icons/Trashcan.svg";
import styles from "./TableUsersListRow.module.scss";
import stylesProfile from "./TableUsersListRowProfile.module.scss";

const url = apiURL();

export default function TableUsersListRow(props: UserT) {
  const dispatch = useDispatch();
  const {
    uploaded,
    name,
    email,
    address,
    id,
    photoLink,
    balance,
    upcomingBill,
    confirmed = true,
    onPeakRatio,
    installationPerformance,
    accountId = "-",
    sensorIds,
  } = props;

  const openDeleteModal = () => dispatch(switchDeleteModal(true, id));

  function openModal() {
    dispatch(switchUserDetails({ isUserDetailsOpen: true, id }));
  }

  function openUserEnergyModal() {
    dispatch(openUserEnergy(id));
  }

  function reSendInvite() {
    id && dispatch(resendInvite(id));
  }

  const openEditModal = useCallback(
    (isEdit: boolean) => {
      if (id) {
        dispatch(openUserEditModal(id, isEdit));
      }
    },
    [dispatch, id]
  );

  function openEditBaseline() {
    openEditModal(true);
  }

  function openBaseline() {
    openEditModal(false);
  }

  const renderMoney = (money: number | undefined) => {
    if (!uploaded) {
      return "...";
    }
    if (typeof money !== "number") {
      return "-";
    }
    return !money ? 0 : <NumericValueFormat value={money} isMoney />;
  };

  const userPhoto = photoLink
    ? {
        backgroundImage: `url(${url}${photoLink})`,
      }
    : {};

  return (
    <tr key={id}>
      <td className={styles.account}>
        <div className={styles.cell}>
          <div className={stylesProfile.content}>
            {/* TODO: check background with destination image */}
            <NavLink
              to={`/user-dashboard/${id}`}
              className={stylesProfile.img}
              style={userPhoto}
            />
            <div>
              <div className={stylesProfile.name}>
                <p>{name}</p>
                {!confirmed && (
                  <div>
                    <span className={stylesProfile.badge}>Pending</span>
                  </div>
                )}
                {uploaded && !sensorIds && <MissedDataMark />}
              </div>
              <a href={email} className={stylesProfile.email}>
                {email}
              </a>
            </div>
          </div>
        </div>
      </td>
      <td className={styles.accountId}>
        <div className={styles.cell}>{accountId}</div>
      </td>
      <td className={styles.address}>
        <div className={styles.cell}>{address}</div>
      </td>
      <td className={styles.pick}>
        <div className={styles.cell}>
          <div>
            {`${installationPerformance?.month?.toFixed(2) || "-"}`}
            <span className={styles.delimiter}>&nbsp;|&nbsp;</span>
            {`${installationPerformance?.yesterday?.toFixed(2) || "-"}`}
          </div>
        </div>
      </td>
      <td className={styles.pick}>
        <div className={styles.cell}>
          <div>
            {`${onPeakRatio?.month?.toFixed(2) || "-"}`}
            <span className={styles.delimiter}>&nbsp;|&nbsp;</span>
            {`${onPeakRatio?.yesterday?.toFixed(2) || "-"}`}
          </div>
        </div>
      </td>
      <td className={styles.balance}>
        <div className={styles.cell}>{renderMoney(balance)}</div>
      </td>
      <td className={styles.bill}>
        <div className={styles.cell}>{renderMoney(upcomingBill)}</div>
      </td>
      <td className={styles.menu}>
        <div className={styles.cell}>
          <MenuDrawer>
            {!confirmed && (
              <MenuDrawerButton Icon={RefreshIcon} onClick={reSendInvite}>
                Re-send Invite
              </MenuDrawerButton>
            )}
            <MenuDrawerButton Icon={PenIcon} onClick={openEditBaseline}>
              Edit Info
            </MenuDrawerButton>
            <MenuDrawerButton Icon={DetailsIcon} onClick={openModal}>
              Details
            </MenuDrawerButton>
            <MenuDrawerButton Icon={ChartIcon} onClick={openUserEnergyModal}>
              Energy
            </MenuDrawerButton>
            <MenuDrawerButton Icon={Baseline} onClick={openBaseline}>
              Baseline
            </MenuDrawerButton>
            <MenuDrawerButton
              type="warning"
              Icon={TrashcanIcon}
              onClick={openDeleteModal}
            >
              Delete
            </MenuDrawerButton>
          </MenuDrawer>
        </div>
      </td>
    </tr>
  );
}
