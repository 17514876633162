import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import Loading from "../../components/Loading/Loading";
import DesktopEnergyBlock from "../../components/DesktopEnergyBlock/DesktopEnergyBlock";
import MobileEnergyBlock from "../../components/MobileEnergyBlock/MobileEnergyBlock";
import StatisticsBlockForDashboard from "../../components/StatisticsBlockForDashboard/StatisticsBlockForDashboard";
import { AppState } from "../../store/modules";
import { StateT as BillsState } from "../../store/modules/bills";
import { StateT as AuthState } from "../../store/modules/authorization";
import { getUserInfo, StateT as userState } from "../../store/modules/users";
import {
  getUserEnergyDataById,
  getUserAdditionalData,
  resetUserDashboard,
  StateT as UsersState,
} from "../../store/modules/users";
import { StateT as DashboardState } from "../../store/modules/dashboard";
import breakpoints from "../../variables.scss";

const UserDashboard = () => {
  const dispatch = useDispatch();
  const { userId } = useParams<{ userId: string}>();

  const { period } = useSelector<AppState, DashboardState>(
    (state) => state.dashboard
  );

  const {
    energy,
    loading,
    userDashboard: { additional, powerData },
  } = useSelector<AppState, UsersState>(({ users }) => users);

  const { userUpcoming: upcoming } = useSelector<AppState, BillsState>(
    (state) => state.bills
  );

  const { isFlat } = useSelector<AppState, userState>((state) => state.users);

  const { currentRate } = useSelector<AppState, AuthState>(
    (state) => state.authorization
  );

  const isUserInfoLoaded =
    (currentRate?.flat !== null && currentRate?.flat !== undefined) ||
    (isFlat !== null && isFlat !== undefined);

  const isResponsiveView = useMediaQuery({
    query: `(max-width: ${breakpoints["tablet"]}px)`,
  });

  useEffect(() => {
      dispatch(getUserInfo(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    if (!energy) dispatch(getUserEnergyDataById(userId, period));
  }, [dispatch, energy, userId, period]);

  useEffect(() => {
    if (!additional) {
      dispatch(getUserAdditionalData(userId));
    }
  }, [additional, dispatch, userId]);

  useEffect(() => {
    return () => {
      dispatch(resetUserDashboard());
    };
  }, [dispatch]);

  if ((!additional && isUserInfoLoaded && loading) || !upcoming) {
    return <Loading />;
  }

  return (
    <>
      {energy ? (
        isResponsiveView ? (
          <MobileEnergyBlock energy={energy} period={period} />
        ) : (
          <DesktopEnergyBlock energy={energy} period={period} />
        )
      ) : null}

      <StatisticsBlockForDashboard
        upcoming={upcoming}
        additional={additional}
        powerData={powerData}
      />
    </>
  );
};

export default UserDashboard;
